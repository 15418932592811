<template>
    <div class="animated fadeIn faster">
        <div class="titlebar-con">
            <ar-titlebar
                :campaign       ="campaign.name"
                :img            ="campaign.img"
                :from_count     ="listing_from_count"
                :to_count       ="listing_to_count"
                :max_count      ="lists_meta.total_no_of_items"
                subject         ="listing(s)"
                :options        ="options"
                :selected_count ="selected_listings.length"
                :selected       ="selected_listings.length > 0"
                class           ="animated fadeIn faster position-relative"
                style           ="z-index: 2"
                :action_text    ="select_all_text"
                @click          ="() => { is_select_all = true; checkAllListing(); }"
                @uns-click      ="() => { is_select_all = false; checkAllListing(); }"
                :selected_max_count="page_size"
                is_listings
            >
                <!-- <ar-button :icon="'fas fa-chevron-left'" :class="'a-btn-text'" @click="navigate('campaigns')">Back to Campaign</ar-button> -->
                <template v-slot:search-form>
                    <div class="form-inline">
                        <ar-select 
                            class          ="mr-2" 
                            a_style        ="width: 180px;" 
                            :options       ="search_options" 
                            v-model.number ="searched_option"
                            :disabled      ="[1].includes(access_level) ? Listing_has_clientListings : false"
                        >
                            Search By LID
                        </ar-select> 
                        
                        <div class="a-input-group">
                            <ar-textfield
                                placeholder         ="Search here.." 
                                type                ="text"
                                a_class             ="mr-1"
                                :icon               ="search_keywords.toString().trim() != ''? 'fas fa-times': 'fas fa-search'"
                                v-model.trim        ="search_keywords"
                                @keyup.enter.native ="setFilters()"
                                @keyup.esc.native   ="clearKeywords()"
                                @click              ="clearKeywords()"
                                :disabled           ="[1].includes(access_level) ? Listing_has_clientListings : false"
                            />
                        </div>
                    </div>
                </template>

                <!-- Removed since it's duplicate with the code below -->
                <!-- <template v-if="[1,5].includes(access_level)">
                    <ar-button 
                        class  ="export-btn mr-2" 
                        @click ="downloadExcel(campaign.name, $route.params.campaign_id,selected_listings)"
                    >
                        Export to Excel
                    </ar-button>
                    <ar-button 
                        class       ="mr-2" 
                        v-if        ="checkSelectedAuth" 
                        @click="showReassignModal()"
                    >
                        Reassign
                    </ar-button>
                    <ar-button 
                        v-if        ="enableInvalidTagging"
                        a_class     ="btn-invalid"
                        data-toggle ="modal" 
                        data-target ="#change-status-modal" 
                        title       ="Mark as Invalid"
                        @click      ="getPredefinedReasons(99)"
                    >
                        Invalid
                    </ar-button>
                </template> -->
                <template>
                    <div class="qa-selected">
                        <strong 
                            class  ="mr-4" 
                            :class ="{'with-separator': !post_qa_status.includes($route.query.status)}"
                        >
                            Export to:
                        </strong> 

                        <a
                            href               ="javascript:void(0);"
                            class              ="mr-3 action-item v-button-export-to-excel"
                            :class             ="{'mr-5': !post_qa_status.includes($route.query.status)}"
                            title              ="Export to Excel"
                            @click             ="downloadExcel(campaign.name, $route.params.campaign_id,selected_listings)"
                            v-restrict ="'v-button-export-to-excel'"
                        >
                            <img src="/static/svg/Action_Excel.svg" alt="export" />
                        </a>

                        <!-- <strong v-if="!post_qa_status.includes($route.query.status)" class="mr-4">Mark as:</strong>  -->
                        <!-- @click      ="setPredefinedReasons(30); already_worked_lid = ''" -->
                        <!-- DONE: remove v-show since feature restriction is applied -->
                        <!-- v-show="$route.query.status == 'Work in Progress' && access_level == 7" -->

                        <template v-if="canChangeStatus">
                            <div class="dropdown d-inline-block mr-3" v-if="$route.query.status == 'Work in Progress'"
                                v-restrict="'v-status-change-auto-qualified'">
                                <a 
                                    id="autoQualifiedSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-auto-qualified"
                                    :data-toggle        ="autoQualifiedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!autoQualifiedSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Auto Qualified"
                                    @click="getPredefinedReasons(ListingStatus.AutoQualified); already_worked_lid = ''"
                                >
                                    <img src="/static/svg/Mark_AutoQualify.svg" alt="auto qualified icon" />
                                </a>
                                <div v-if="autoQualifiedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="autoQualifiedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in autoQualifiedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="dropdown d-inline-block mr-3" v-if="!['Has Potential','Work in Progress','Client Listing',...post_qa_status].includes($route.query.status) && !hasLFC"
                                v-restrict="'v-status-change-has-potential'">
                                <a 
                                    id="hasPotentialSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-has-potential"
                                    :data-toggle        ="hasPotentialSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!hasPotentialSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Has Potential"
                                    @click="resetCommission(); getPredefinedReasons(ListingStatus.HasPotential)"
                                >
                                    <img src="/static/svg/Action_HasPo.svg" alt="haspo" />
                                </a>
                                <div v-if="hasPotentialSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="hasPotentialSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in hasPotentialSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <!-- 
                                Deliverable: Hide all For Rework/Reworked elements across all GUI
                                Deliverable Number: 454
                            -->
                            <!-- <a 
                                v-show       ="!['Recheck','Work in Progress', ...post_qa_status].includes($route.query.status)"
                                href         ="javascript:void(0);"
                                class        ="mr-3 action-item"
                                title        ="Mark as For Rework"
                                data-toggle  ="modal"
                                data-target  ="#change-status-modal"
                                @click       ="getPredefinedReasons(2)"
                            >
                                <img src="/static/svg/Action_ForRework.svg" alt="forrework">
                            </a> -->

                            <!--                             
                                Deliverable #552: Removal of Invalid status from Artemis
                             -->
                            <!-- v-show="!['Invalid', ...post_qa_status].includes($route.query.status) && enableInvalidTagging"  -->
                            <!-- <a 
                                v-if      ="!['Invalid', ...post_qa_status].includes($route.query.status) && (LFCDetected.length == 0 || nonLFCDetected.length == 0)"
                                v-restrict="'v-status-change-invalid'"
                                href        ="javascript:void(0);" 
                                class="mr-3 action-item v-status-change-invalid"
                                data-toggle ="modal"
                                data-target ="#change-status-modal"
                                title       ="Mark as Invalid"
                                @click      ="getPredefinedReasons(99);"
                            >
                                <img src="/static/svg/Action_Invalid.svg" alt="invalid">
                            </a> -->

                            <div class="dropdown d-inline-block mr-3" v-if="!['Qualified','Work in Progress','Client Listing', ...post_qa_status].includes($route.query.status) && !hasLFC"
                                v-restrict="'v-status-change-qualified'">
                                <a 
                                    id="qualifiedSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-qualified"
                                    :data-toggle        ="qualifiedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!qualifiedSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Qualified"
                                    @click="resetCommission(); getPredefinedReasons(ListingStatus.Qualified);"
                                >
                                    <img src="/static/svg/Action_Qualified.svg" alt="qualified" />
                                </a>
                                <div v-if="qualifiedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="qualifiedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in qualifiedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- v-if="$route.query.status == 'Client Listing' && hasLFC" -->
                            <div class="dropdown d-inline-block mr-3" v-if="canBeAccepted"
                                v-restrict="'v-status-change-accepted'">
                                <a 
                                    id="acceptedSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-accepted"
                                    :data-toggle        ="acceptedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!acceptedSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Accepted"
                                    @click="resetCommission(); getPredefinedReasons(ListingStatus.Accepted);"
                                >
                                    <img src="/static/svg/notifications/Accepted.svg" alt="accepted" style="height: 22px; width: 22px; border-radius: 3px" />
                                </a>
                                <div v-if="acceptedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="acceptedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in acceptedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            
                            <div class="dropdown d-inline-block mr-3" v-if="$route.query.status === 'Invalid'"
                                v-restrict="'v-status-change-archived'">
                                <a 
                                    id="archivedSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-archived"
                                    :data-toggle        ="archivedSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!archivedSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Archived"
                                    @click="getPredefinedReasons(ListingStatus.Archived);"
                                >
                                    <img src="/static/svg/Action_Archive.svg" alt="Mark as Archived" style="height: 22px; width: 22px; border-radius: 3px"/>
                                </a>
                                <div v-if="archivedSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="archivedSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in archivedSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div class="dropdown d-inline-block mr-3" v-if="$route.query.status === 'Archived'"
                                v-restrict="'v-status-change-wip'">
                                <a 
                                    id="wipSubStatusDropdown"
                                    href="javascript:void(0);"
                                    class="action-item v-status-change-wip"
                                    :data-toggle        ="wipSubStatuses.length ? 'dropdown' : 'modal'"
                                    :data-target        ="!wipSubStatuses.length ? `#change-status-modal` : null"
                                    title="Mark as Work In Progress"
                                    @click="getPredefinedReasons(ListingStatus.WIP);"
                                >
                                    <img src="/static/svg/Action_WIP.svg" alt="Mark as Work In Progress" style="height: 22px; width: 22px; border-radius: 3px"/>
                                </a>
                                <div v-if="wipSubStatuses.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="wipSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in wipSubStatuses"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            :data-target="`#change-status-modal`"
                                            @click="subStatusesStore.currentSelectedSubStatus = option.id"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>

                        <div class="dropdown d-inline-block mr-3" v-if="transferToAnotherSubStatusOptions.length">
                                <a
                                    id="transferToAnotherSubStatusDropdown"
                                    href="javascript:void(0);"
                                    data-toggle="dropdown"
                                    class="action-item"
                                    v-tooltip="`Transfer to another sub status`"
                                >
                                    <img src="/static/svg/Action_Archived.svg" alt="transfer to another sub status" style="height: 23px; width: 23px;">
                                </a>
                                <div v-if="transferToAnotherSubStatusOptions.length" class="dropdown-menu dropdown-menu-right" aria-labelledby="transferToAnotherSubStatusDropdown">
                                    <label class="ml-3 font-weight-medium" style="font-size: 14px;">Select a Sub-status</label>
                                    <hr class="m-0 mb-2" />
                                    <div class="bulk-status-options-con">
                                        <a
                                            v-for="(option, index) in transferToAnotherSubStatusOptions"
                                            :key="index" 
                                            class="dropdown-item"
                                            href="javascript:void(0);"
                                            data-toggle="modal"
                                            data-target="#transfer-to-another-substatus-modal"
                                            @click="setTransferToAnotherSubStatus(option)"
                                        >
                                            {{ option.label }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        <!-- v-if="checkSelectedAuth" -->
                        <a
                            v-restrict="'v-status-change-reassign'"
                            class="mr-3 action-item v-status-change-reassign"
                            href="javascript:void(0);"
                            title="Reassign Listing(s)"
                            @click="showReassignModal()"
                            data-toggle="modal"
                            data-target="#reassign-modal"
                        >
                            <img src="/static/svg/Action_Reassign.svg" alt="reassign" />
                        </a>

                        <!--                             
                            Deliverable #552: Removal of Invalid status from Artemis
                        -->
                        <!-- <a
                            v-if="['work in progress', 'researched', 'Qualified', 'has potential'].includes($route.query?.status?.toLowerCase())"
                            v-restrict="'v-status-change-remove-listing'"
                            href="javascript:void(0);" class="mr-3 action-item v-status-change-remove-listing" role="button" data-toggle="modal"
                            data-target="#remove-listing-modal" title="Remove Listing"
                            @click="remove_listing.comment = ''"
                        >
                            <img src="/static/svg/Action_Remove.svg" alt="remove-listing">
                        </a> -->

                        <a 
                            v-if      ="['work in progress', 'researched', 'qualified', 'has potential', 'archived'].includes($route.query?.status?.toLowerCase())"
                            v-restrict="'v-status-change-remove-listing'"
                            href        ="javascript:void(0);" 
                            class="mr-3 action-item v-status-change-remove-listing"
                            data-toggle ="modal"
                            data-target ="#remove-listing-modal"
                            title       ="Remove Listing"
                            @click      ="getPredefinedReasons(99);"
                        >
                            <img src="/static/svg/Action_Remove.svg" alt="remove-listing">
                        </a>
                    </div>
                </template>
            </ar-titlebar>
            <div class="position-relative d-flex justify-content-end align-items-center border-top ml-4"
                style="border-width: 2px !important; border-color: #ccc;">
                <!-- <div>
                    <div class="dropdown d-inline-block pl-2">
                        <a href="javascript:void(0);" id="sortDropdown" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <ar-button class="position-relative font-weight-bold pl-3 a-btn-text"
                                style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                <img class="position-relative mr-1" style="top: -2px;"
                                    src="/static/svg/defendants/Sort.svg" alt="backCase"> Sort By - {{
                                            sort_by_display
                                    }}
                            </ar-button>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="sortDropdown">
                            <a v-for="option in  sort_by_option" :key="option.value" class="dropdown-item"
                                href="javascript:void(0);" @click="sort_by = option.value">
                                {{ option.label }}
                            </a>
                        </div>
                    </div>
                    <div class="dropdown d-inline-block pl-3 ml-2 has-bullet-separator">
                        <a href="javascript:void(0);" id="orderDropdown" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <ar-button class="position-relative font-weight-bold pl-3 a-btn-text"
                                style="font-size: 12px; text-align:center; padding-left: 12px !important; padding-right: 12px; padding-top: 3px; padding-bottom: 3px; margin-top: 2px;">
                                <img class="position-relative mr-1" style="top: -2px;"
                                    src="/static/svg/defendants/Order.svg" alt="backCase"> Order By - {{
                                            $parent.ucFirst(order_by)
                                    }}ending
                            </ar-button>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="orderDropdown">
                            <a v-for="option in order_by_option" :key="option.value" class="dropdown-item"
                                href="javascript:void(0);" @click="order_by = option.value">
                                {{ option.label }}
                            </a>
                        </div>
                    </div>
                </div> -->
                <ar-button v-if="$route.params.status != 'Cases'" class="position-relative rounded mt-2 pl-3"
                    :icon="`fas ${expanded ? 'fa-angle-up' : 'fa-angle-down'}`" icon_right outlined
                    style="font-size: 12px; padding-top: 3px; padding-bottom: 3px;" @click="expanded = !expanded, getAdditionalFilters_data()"
                    :disabled="[1].includes(access_level) ? Listing_has_clientListings : false"
                >
                    {{ expanded ? 'Less' : 'More' }} filters <span v-if="applied_filters_count"
                        class="badge badge-primary rounded-circle" style="padding: 4px 4px 4px 6px">{{
                                applied_filters_count
                        }}</span>
                </ar-button>
                <span v-else class="d-inline-block mt-2 p-3"></span>
            </div>
            <div class="additional-filters-con" style="margin-top: 10px;" :class="{ 'expanded': expanded }">
                <form id="ver-filter-form">
                    <div class="additional-filters-con-con">
                        <!-- <div class="row" v-if="access_level !== 6"> -->
                        <div class="row v-filter-listing-ids" v-restrict="'v-filter-listing-ids'">
                            <ar-textfield a_type="chip" a_class="mt-2 mb-1 w-100"
                                :placeholder="filtered_listing_id.length > 0 ? 'Input here and Press Enter (Press Alt+X to remove all)' : 'Input Listing IDs here and Press Enter'"
                                id="input-rids" v-model="filtered_listing_id_input"
                                @keyup.enter.native="appendListingID" @keydown.backspace.native="removeLastListingID"
                                @keypress.native="isNumber" @paste="cleanInput">
                                <ar-chip close v-for="(listing_id, index) in filtered_listing_id" :key="index"
                                    @remove-chip="removeListingID(listing_id)">{{ listing_id }}</ar-chip>
                            </ar-textfield>
                            <div style="text-align:right; width: 100%; font-size:13px">Count: {{
                                    filtered_listing_id.length
                            }}</div>
                        </div>
                        <!-- row here -->
                        <div class="row">
                            <div class="col-md-6">
                                <ar-select 
                                    label="Show number of items"
                                    v-model="show_number_of_items"
                                    :options="options">
                                    Show 10 listings
                                </ar-select>
                            </div>
                            <div class="col-md-6">
                                <ar-select
                                    label="Sort by"
                                    :options="sort_by_option"
                                    v-model="sort_by"
                                >
                                    Listing ID
                                </ar-select>
                            </div>
                            <div class="col-md-6">
                                <ar-select
                                    label="Order by"
                                    :options="order_by_option"
                                    v-model="order_by"
                                >
                                    Descending
                                </ar-select>
                            </div>
                            <div class="col-md-6" v-if="$route.query.status == 'Filed'">
                                <ar-textfield
                                    label="Case Number"
                                    a_type="text"
                                    v-model.trim="filter_case_number"
                                    placeholder="Input keywords here"
                                />
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label="Seller URL"
                                    a_type="text"
                                    v-model.trim="filter_seller_url"
                                    placeholder="Input url here"
                                />
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label="Product URL"
                                    a_type="text"
                                    v-model.trim="filter_product_url"
                                    placeholder="Input url here"
                                />
                            </div>
                            <div class="col-md-6 d-flex align-items-end">
                                <div class="w-100">
                                    <ar-select
                                        label="Claim Type"
                                        :options="filter_claimtype.options"
                                        v-model="filter_claimtype.selected"
                                        :disabled="filter_claimtype.options.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                        <!-- Select Claim Type -->
                                        {{filter_claimtype.options.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Claim Type' }}
                                    </ar-select>
                                </div>
                                <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                    <div class="btn-group btn-group-toggle"
                                        data-toggle="buttons" style="margin-top: -27px;">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_claimtype == '1' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_claimtype', '1')">
                                            <input type="radio" name="with_claimtype-options" id="with_claimtype-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_claimtype == '0' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_claimtype', '0')">
                                            <input type="radio" name="with_claimtype-options" id="with_claimtype-without" value="0"> 
                                            Without
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex align-items-end">
                                <div class="w-100">
                                    <ar-select
                                        label="Shipping Location"
                                        :options="filter_shipping_location.options"
                                        v-model="filter_shipping_location.selected"
                                        :disabled="filter_shipping_location.options.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                        <!-- :placeholder="filter_shipping_location.options.length == 0 ? 'Loading data...' : ''" -->
                                        <!-- Select Shipping Location -->
                                        {{filter_shipping_location.options.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Shipping Location'}}
                                    </ar-select>
                                </div>
                                <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                    <div class="btn-group btn-group-toggle"
                                        data-toggle="buttons" style="margin-top: -27px;">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_shipping_location == '1' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_shipping_location', '1')">
                                            <input type="radio" name="with_shipping_location-options" id="with_shipping_location-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_shipping_location == '0' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_shipping_location', '0')">
                                            <input type="radio" name="with_shipping_location-options" id="with_shipping_location-without" value="0"> 
                                            Without
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label="Researcher"
                                    a_type="text"
                                    v-model.trim="filter_researcher"
                                    placeholder="Input keywords here"
                                />
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label="QA"
                                    a_type="text"
                                    v-model.trim="filter_qa"
                                    placeholder="Input keywords here"
                                />
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label        ="Date Captured"
                                    a_type       ="date"
                                    v-model.trim ="filterDateCaptured"
                                    :max         ="today"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <ar-textfield
                                    label="Date added (from)"
                                    a_type="date"
                                    a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;"
                                    v-model="min_date_added"
                                    :max="today"
                                />
                            </div>
                            <div class="col-md-6">
                                <ar-textfield
                                    label="Date added (to)"
                                    a_type="date"
                                    a_class="remove-date-spinner"
                                    a_style="padding-right: 3px;"
                                    :disabled="min_date_added == ''"
                                    v-model="max_date_added"
                                    :min="min_date_added"
                                    :max="today"
                                />
                            </div>
                        </div>
                        <!-- <template v-if="has_date_qualified_filter"> -->
                            <div class="row">
                                <div class="col-md-6">
                                    <ar-textfield
                                        label="Date qualified (from)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        v-model="min_date_qualified"
                                        :max="today"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <ar-textfield
                                        label="Date qualified (to)"
                                        a_type="date"
                                        a_class="remove-date-spinner"
                                        a_style="padding-right: 3px;"
                                        :disabled="min_date_qualified == ''"
                                        v-model="max_date_qualified"
                                        :min="min_date_qualified"
                                        :max="today"
                                    />
                                </div>
                            </div>
                        <!-- </template> -->
                        <div class="row mb-3">
                            <div class="col-md-6">
                                <!-- <ar-textfield
                                    label="Item Location"
                                    a_type="text"
                                    v-model.trim="filter_item_location"
                                    placeholder="Input keywords here"
                                /> -->
                                <ar-select 
                                    searchFieldId ="item_location"
                                    v-model="filters__.item_location.selected" 
                                    label="Item Location"
                                    :placeholder="getDataFor_AdditionalFilters ? 'Loading data...' : filters__?.item_location?.options.length == 0 ? 'No Item Locations Available' : 'Search Item Location'"
                                    type="search"
                                    :options="filters__?.item_location?.options"
                                    :disabled="filters__?.item_location?.options.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    <!-- Select Item Location -->
                                </ar-select>
                            </div>
                            <div class="col-md-6 d-flex align-items-end">
                                <div class="w-100">
                                    <ar-select 
                                        searchFieldId ="item_class"
                                        v-model       ="filter_item_class" 
                                        label         ="Item Class"
                                        :placeholder   ="item_class.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Search Item Class'"
                                        type          ="search"
                                        :options      ="item_class"
                                        :disabled="item_class.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                        <!-- Select Item Class -->
                                        {{item_class.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Item Class'}}
                                    </ar-select>
                                </div>
                                <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                    <div class="btn-group btn-group-toggle"
                                        data-toggle="buttons" style="margin-top: -27px;">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_item_type == '1' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_item_type', '1')">
                                            <input type="radio" name="with_item_type-options" id="with_item_type-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_item_type == '0' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_item_type', '0')">
                                            <input type="radio" name="with_item_type-options" id="with_item_type-without" value="0"> 
                                            Without
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 d-flex align-items-end">
                                <div class="w-100">
                                    <ar-select
                                        label="Seller Country"
                                        :options="seller_locations"
                                        v-model="filter_seller_location"
                                        :disabled="seller_locations.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                        <!-- Select Seller Country -->
                                        {{seller_locations.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Seller Country'}}
                                    </ar-select>
                                </div>
                                <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                    <div class="btn-group btn-group-toggle"
                                        data-toggle="buttons" style="margin-top: -27px;">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_seller_country == '1' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_seller_country', '1')">
                                            <input type="radio" name="with_seller_country-options" id="with_seller_country-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_seller_country == '0' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_seller_country', '0')">
                                            <input type="radio" name="with_seller_country-options" id="with_seller_country-without" value="0"> 
                                            Without
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <label
                                style="color: #383A3D; font-weight: 500; font-size: 13px;">Roles</label>
                                <multiselect label="label" track-by="label" v-model="filter_roles.selected"
                                    :options="filter_roles.options" placeholder="Select here" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false">
                                </multiselect>
                            </div> -->
                            <!-- <div class="col-md-6">
                                <ar-select 
                                    searchFieldId ="employee_name"
                                    v-model       ="filter_employee.selected" 
                                    label         ="Employee Name"
                                    placeholder   ="Search Employee Name"
                                    type          ="search"
                                    :options      ="filter_employee.options"> Select Employee
                                </ar-select>
                            </div> -->
                            <div class="col-md-6">
                                <!-- <ar-select
                                    label="Purchase Type"
                                    :options="purchaseTypeOptions"
                                    v-model="filter_purchase_type"
                                    :disabled="purchaseTypeOptions.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    {{seller_locations.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Purchase Type'}}
                                </ar-select> -->
                                <ar-select
                                    label="Purchase Type"
                                    :with_subOptions="true"
                                    :options="purchaseTypeOptions"
                                    v-model="filter_purchase_type"
                                    :disabled="purchaseTypeOptions.length == 0 || getDataFor_AdditionalFilters"
                                >
                                    {{seller_locations.length == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select Purchase Type'}}
                                </ar-select>
                            </div>
                            <div class="col-md-6 d-flex mt-3">
                                <div class="w-100">
                                    <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('brand_name_on_image_option')}">
                                        <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                <input class="custom-control-input" type="checkbox" name="enable-options"
                                                    :id="`enable--brand_name_on_image-options`" v-model="enable_options" value="brand_name_on_image_option">
                                                    <label :for="`enable--brand_name_on_image-options`" class="mt-2 custom-control-label"
                                                        style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Brand Name on Image</label>
                                            </div>
                                        <div class="fieldset-alt-content">
                                            <div class="btn-group btn-group-toggle align-middle"
                                                data-toggle="buttons" style="margin-top: -27px;">
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_brand_name_on_image == '1' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_brand_name_on_image', '1')">
                                                    <input type="radio" name="brand_name_on_image-options" id="brand_name_on_image-with" value="1">
                                                    Yes
                                                </label>
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_brand_name_on_image == '0' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_brand_name_on_image', '0')">
                                                    <input type="radio" name="brand_name_on_image-options" id="brand_name_on_image-without" value="0">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="w-100 ml-3">
                                    <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('logo_on_image_option')}">
                                        <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                            <input class="custom-control-input" type="checkbox" name="enable-options"
                                                :id="`enable--logo_on_image-options`" v-model="enable_options" value="logo_on_image_option">
                                                <label :for="`enable--logo_on_image-options`" class="mt-2 custom-control-label"
                                                    style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Logo on Image</label>
                                        </div>
                                        <div class="fieldset-alt-content">
                                            <div class="btn-group btn-group-toggle align-middle"
                                                data-toggle="buttons" style="margin-top: -27px;">
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_logo_on_image == '1' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_logo_on_image', '1')">
                                                    <input type="radio" name="logo_on_image-options" id="logo_on_image-with" value="1">
                                                    Yes
                                                </label>
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_logo_on_image == '0' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_logo_on_image', '0')">
                                                    <input type="radio" name="logo_on_image-options" id="logo_on_image-without" value="0"> 
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="col-md-6 d-flex mt-3">
                                <div class="w-100">
                                    <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('will_ship_to_us_option')}">
                                        <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                                <input class="custom-control-input" type="checkbox" name="enable-options"
                                                    :id="`enable--will_ship_to_us-options`" v-model="enable_options" value="will_ship_to_us_option">
                                                    <label :for="`enable--will_ship_to_us-options`" class="mt-2 custom-control-label"
                                                        style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Will Ship to US</label>
                                            </div>
                                        <div class="fieldset-alt-content">
                                            <div class="btn-group btn-group-toggle align-middle"
                                                data-toggle="buttons" style="margin-top: -27px;">
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_will_ship_to_us == '1' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_will_ship_to_us', '1')">
                                                    <input type="radio" name="will_ship_to_us-options" id="will_ship_to_us-with" value="1">
                                                    Yes
                                                </label>
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_will_ship_to_us == '0' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_will_ship_to_us', '0')">
                                                    <input type="radio" name="will_ship_to_us-options" id="will_ship_to_us-without" value="0">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                                <div class="w-100 ml-3">
                                    <fieldset class="fieldset-alt" :class="{'disabled': !enable_options.includes('commission_option')}">
                                        <div class="custom-control custom-checkbox legend-alt mb-2 ml-4 pl-3">
                                            <input class="custom-control-input" type="checkbox" name="enable-options"
                                                :id="`enable--commission-options`" v-model="enable_options" value="commission_option">
                                                <label :for="`enable--commission-options`" class="mt-2 custom-control-label"
                                                    style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 11px;">Commission</label>
                                        </div>
                                        <div class="fieldset-alt-content">
                                            <div class="btn-group btn-group-toggle align-middle"
                                                data-toggle="buttons" style="margin-top: -27px;">
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_commission == '1' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_commission', '1')">
                                                    <input type="radio" name="filter_commission-options" id="filter_commission-with" value="1">
                                                    With
                                                </label>
                                                <label class="btn btn-outline-secondary font-weight-normal"
                                                    :class="{ 'active': filter_commission == '0' }" style="font-size: 12px;"
                                                    @click="updateOption('filter_commission', '0')">
                                                    <input type="radio" name="filter_commission-options" id="filter_commission-without" value="0"> 
                                                    Without
                                                </label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="mt-3 col-md-12 d-flex align-items-end">
                                <div class="w-100 mb-3">
                                    <label
                                    style="color: #383A3D; font-weight: 500; font-size: 13px;">Item Types</label>
                                    <multiselect label="label" track-by="label"
                                        v-model="filter_item_type"
                                        :options="itemTypeOptions"
                                        :placeholder="itemTypeOptions == 0 || getDataFor_AdditionalFilters ? 'Loading data...' : 'Select here' "
                                        :multiple="true"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :disabled="itemTypeOptions.length == 0 || getDataFor_AdditionalFilters"
                                    >
                                    </multiselect>
                                </div>
                                <div class="ml-2" style="max-width: 100%; margin-bottom: 13px;">
                                    <div class="btn-group btn-group-toggle"
                                        data-toggle="buttons" style="margin-top: -27px;">
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_item_type == '1' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_item_type', '1')">
                                            <input type="radio" name="with_item_type-options" id="with_item_type-with" value="1">
                                            With
                                        </label>
                                        <label class="btn btn-outline-secondary font-weight-normal"
                                            :class="{ 'active': filter_with_item_type == '0' }" style="font-size: 12px;"
                                            @click="updateOption('filter_with_item_type', '0')">
                                            <input type="radio" name="with_item_type-options" id="with_item_type-without" value="0"> 
                                            Without
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section class="mb-4 fieldset-alt">
                            <label class="mt-2 legend-alt"
                                style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Payment
                                Methods</label>
                            <div class="row">
                                <div class="col-md-4 pl-3" v-for="(method, index) in payment_methods" :key="index">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input" type="checkbox" name="method"
                                            :id="`payment-method--${method.value}`" v-model="selected_payment_methods"
                                            :value="method.label">
                                        <label :for="`payment-method--${method.value}`"
                                            class="custom-control-label font-weight-normal">{{ method.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <fieldset class="mb-4 fieldset-alt">
                            <div class="mb-3">
                                <label class="mt-2 d-inline-block align-middle legend-alt"
                                    style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Infringement
                                    Indicators</label>
                                <div class="btn-group btn-group-toggle ml-3 align-middle legend-alt"
                                    data-toggle="buttons">
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': infringement_option == '1' }" style="font-size: 13px;"
                                        @click="updateOption('infringement_option', '1')">
                                        <input type="radio" name="infringement-options" id="infringment-with" value="1">
                                        With
                                    </label>
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': infringement_option == '0' }" style="font-size: 13px;"
                                        @click="updateOption('infringement_option', '0')">
                                        <input type="radio" name="infringement-options" id="infringment-without"
                                            value="0"> Without
                                    </label>
                                </div>
                            </div>
                            <div class="row pl-2 pb-2 pr-2">
                                <div class="col-md-6 pl-2" v-for="reason in reasons" :key="`reason--${reason.value}`">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input" type="checkbox" name="reasons"
                                            :id="`reason--${reason.value}`" v-model="selected_reasons"
                                            :value="reason.value">
                                        <label :for="`reason--${reason.value}`"
                                            class="custom-control-label font-weight-normal">{{ reason.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="fieldset-alt" v-if="filter_status_change_reason.options.length">
                            <div class="mb-3">
                                <label class="mt-2 d-inline-block align-middle legend-alt"
                                    style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Status Change Reasons</label>
                                <div class="btn-group btn-group-toggle ml-3 align-middle legend-alt"
                                    data-toggle="buttons">
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': filter_with_status_change_reason == '1' }" style="font-size: 13px;"
                                        @click="updateOption('filter_with_status_change_reason', '1')">
                                        <input type="radio" name="with_status_change_reason-options" id="with_status_change_reason-with" value="1">
                                        With
                                    </label>
                                    <label class="btn btn-outline-secondary font-weight-normal"
                                        :class="{ 'active': filter_with_status_change_reason == '0' }" style="font-size: 13px;"
                                        @click="updateOption('filter_with_status_change_reason', '0')">
                                        <input type="radio" name="with_status_change_reason-options" id="with_status_change_reason-without"
                                            value="0"> Without
                                    </label>
                                </div>
                            </div>
                            <div class="row pl-2 pb-2 pr-2">
                                <div class="col-md-6 pl-2" v-for="reason in filter_status_change_reason.options" :key="`status-reason--${reason.value}`">
                                    <div class="custom-control custom-checkbox mb-2">
                                        <input class="custom-control-input" type="checkbox" name="status-reasons"
                                            :id="`status-reason--${reason.value}`" v-model="filter_status_change_reason.selected"
                                            :value="reason.value">
                                        <label :for="`status-reason--${reason.value}`"
                                            class="custom-control-label font-weight-normal">{{ reason.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="float-right mr-3 mt-3">
                        <ar-button id="reset-more-filter-btn" type="reset" a_class="btn-invalid mr-2" @click="resetMoreFilters">Reset
                        </ar-button>
                        <ar-button id="apply-more-filter-btn" @click="applyMoreFilters">
                            Apply
                        </ar-button>
                    </div>
                </form>
            </div>
        </div>

        <portal>
            <ArReassignmentUnsuccessfulModal :reassignment__data="reassign__unsuccessful" />
        </portal>

        <ar-dialog
            id="reassign-modal"
            :custom_maxwidth="'100%'"
            :custom_width="isReassignWithReason || reassignStatuses.results.length ? '1200px' : '500px'"
            :mdStyle="{ 'transition' : '0.3s ease' }"
            :xxLarge="!!isReassignWithReason || !!reassignStatuses.results.length"
            :has_footer="false"
            data-backdrop="static"
        >     
            <template v-if="reassignStatuses.results.length">
                <h5 class="d-inline-block ml-2 align-middle">
                    Reassignment Status
                    <span class="d-block font-weight-normal mt-1">Below are the unsuccessful reassignment of listings in the request and their reasons.</span>
                </h5>
                <table class="table-scroll mb-2">
                    <thead>
                        <template v-if="!hasExisting_listings">
                            <tr>
                                <th class="col-md-1" scope="col">LID</th>
                                <th class="col-md-5" scope="col">Seller Name</th>
                                <th class="col-md-6" scope="col">Message (hover to see message)</th>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <th class="col-md-1" scope="col">LID</th>
                                <th class="col-md-2" scope="col">Seller Name</th>
                                <th class="col-md-6" scope="col">Message (hover to see message)</th>
                                <th class="col-md-3" scope="col">Existing Listing(s)</th>
                            </tr>
                        </template>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in reassignStatuses.results" :key="index">
                            <template v-if="!hasExisting_listings">    
                                <td class="col-md-1 text-bold" scope="row">
                                    <span class="status not_found">{{ item.reason.lid }}</span>
                                </td>
                                <td class="col-md-5">
                                    <span class="d-block text-truncate">
                                        {{ item.reason.seller }}
                                    </span>
                                </td>
                                <td class="col-md-6">
                                    <span class="d-block text-truncate" :title="item.reason.message.message">{{ item.reason.message.message }}</span>
                                </td>
                            </template>
                            <template v-else>
                                <td class="col-md-1 text-bold" scope="row">
                                    <span class="status not_found">{{ item.reason.lid }}</span>
                                </td>
                                <td class="col-md-2">
                                    <span class="d-block text-truncate">
                                        {{ item.reason.seller }}
                                    </span>
                                </td>
                                <td class="col-md-6">
                                    <span class="d-block text-truncate" :title="item.reason.message.message">{{ item.reason.message.message }}</span>
                                </td>
                                <td class="col-md-3 existing-listings-container">
                                    <div class="existing-listings-item-container">
                                        <!-- listing ID in the Title -->
                                        <span 
                                            v-for="(existing_listing, index) in item.existing_listings?.duplicate"
                                            class="d-block mb-1"
                                            :title="`Click to copy ${existing_listing.lid} or Control + Click to redirect to Search Page`"
                                            :key="index"
                                        >
                                            <a 
                                                href="javascript:void(0);"
                                                class="existing-btn text-truncate"
                                                @click.exact="existing_listing == undefined ? '' :  copytoClipboard(existing_listing.lid)"
                                                @click.ctrl="redirectTo_searchPage(existing_listing.lid, existing_listing.qflag_id_word)"
                                            >
                                                {{existing_listing == undefined ? `No Existing Listing(s)` : `${existing_listing.lid} (${existing_listing.qflag_id_word})`}}
                                            </a>
                                        </span>
                                    </div>
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>

                <div class="btn-con float-right mt-3">
                    <ar-button 
                        outlined 
                        data-dismiss="modal"
                        style="color: #202229; border-color: #202229;"
                        @click="individualReassign_hasError = false"
                    >Cancel</ar-button>
                    <ar-button class="ml-2" @click="individualReassign_hasError ? resetIndividualReassignment() : resetReassignData()">
                        Go Back
                    </ar-button>
                </div>
            </template>
            <!-- v-else -->
            <div v-if="reassignStatuses.results.length == 0 && !individualReassign_hasError" class="d-flex text-left justify-content-between" :class="{'withReason': isReassignWithReason}">
                <div class="pr-4" :class="{'border-right': isReassignWithReason, 'w-100': !isReassignWithReason}">
                    <div class="mb-2 reassign-left-heading" :class="{'text-center': !isReassignWithReason}">
                        <!--<img :src="diha" class="d-inline-block align-middle reassign-campaign-img">-->
                        <h5 class="d-inline-block ml-2 align-middle">
                            <!-- <b :style="`color: #${reassignment_mode == 'Researcher' ? '2D599E' :'33ABA2'};`">Reassign to other {{ isQAAccount ? 'User' : reassignment_mode}}</b> -->
                            <b :style="`color: #${reassignment_mode == 'Researcher' ? '2D599E' :'33ABA2'};`">Reassign to other Account</b>
                            <!-- <span class="mt-1 d-block font-weight-normal" :style=" !isReassignWithReason ? 'font-size:14px;' : ''">Select from the list of {{ isQAAccount ? 'users' : reassignment_mode }} below to reassign products(s).</span> -->
                            <span class="mt-1 d-block font-weight-normal" :style=" !isReassignWithReason ? 'font-size:14px;' : ''">Select from the list of Accounts below to reassign products(s).</span>
                        </h5>
                    </div>

                    <div class="left-con">
                        <div class="row">
                            <div class="col-md-12">
                                <!-- v-if="isQAAccount" -->
                                <div
                                    v-if       ="!isQueryStatusEquals('archived')"
                                    class      ="col-md-12 v-reassignment-user-type"
                                    v-restrict ="'v-reassignment-user-type'"
                                >
                                    <ar-select
                                        type="reassign-QA"
                                        placeholder="Select User Type"
                                        id="reassignment-user-type"
                                        :options="reassignment_user_type"
                                        :disabled=" !reassignment_user_type.length"
                                        :first_element_as_default="true"
                                        v-model.number="batchReassign.user_type.selected"
                                        :val='batchReassign.user_type.selected'
                                        label="User Type"
                                    >
                                        Select User Type
                                    </ar-select>
                                </div>
                                <!-- <div class="col-md-12" v-if="isQAAccount" > -->
                                <div
                                    class      ="col-md-12 v-reassignment-account"
                                    v-restrict ="'v-reassignment-account'"
                                >
                                    <!-- <label for="" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">{{check_qa_selected ? 'QA' : batchReassign.user_type.selected == 0 && !check_qa_selected ? 'All Users' : batchReassign.user_type.selected == 1 ? 'Researchers and CL' : 'QA'}}</label> -->
                                    <label for="" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Account</label>
                                    <multiselect 
                                        v-model="selected_campaign_user" 
                                        :options="filtered_campaign_users"
                                        group-values="groupValues" 
                                        group-label="groupLabel" 
                                        placeholder="Type to search" 
                                        track-by="label" 
                                        label="label"
                                        class="mx-auto"
                                    >
                                        <span slot="noResult">Oops! No account(s) found. Consider changing the search query.</span>
                                    </multiselect>
                                </div>
                                <!-- <ar-select
                                    v-restrict  ="'v-reassignment-researcher'"
                                    class       ="v-reassignment-researcher col-md-12 mt-2"
                                    type        ="search" 
                                    id          ="reassign-user"
                                    placeholder ="Select Researcher"
                                    label       ="Researcher"
                                    v-model     ="selected_campaign_user"
                                    :options    ="filtered_campaign_users"
                                /> -->
                                <!-- <div class="col-md-12" v-if="isQAAccount && !check_qa_selected"> -->
                                <div class="col-md-12 mt-2">
                                    <ar-select
                                        v-restrict  ="'v-reassignment-campaign'"
                                        class       ="v-reassignment-campaign"
                                        type        ="search" 
                                        id          ="reassign-campaign"
                                        placeholder ="Select Campaign" 
                                        label       ="Campaign" 
                                        v-model     ="reassign_campaign" 
                                        :options    ="campaigns_select" 
                                        :disabled   ="!campaigns_select.length"
                                    />
                                    <div class="bulk-reassign-status-and-substatus-con">
                                        <ar-select
                                            v-restrict  ="'v-reassignment-status'"
                                            class       ="v-reassignment-status mb-0"
                                            textfield_class="mb-0"
                                            type        ="search" 
                                            placeholder ="Select Status" 
                                            label       ="Status" 
                                            id          ="reassign-status-select"
                                            v-model     ="reassignStatuses.selected" 
                                            :options    ="reassignStatusOptions" 
                                            :disabled   ="!reassignStatusOptions.length"
                                        />
                                        <template v-if="reassignmentBulkSubStatusOptions?.filter(s => s.id)?.length">
                                            <ar-toggle-btn>
                                                <div class="sub-statuses-container w-100">
                                                    <label class="ml-2">Select a sub status</label>
                                                    <div 
                                                        v-for="(sub, sub_index) in reassignmentBulkSubStatusOptions"
                                                        class="btn sub-status d-flex align-items-center reassignment"
                                                        style="padding-left: 37px !important;"
                                                        :key="`bulkReassignSubStatus--${sub_index}`"
                                                        @click="toggleSubStatusReassignment($event, sub.id ?? -1)"
                                                    >
                                                        <h5>{{sub.label}}</h5>
                                                        <input 
                                                            type ="radio" 
                                                            :name ="`bulkReassignSubStatus--sub-status`"
                                                            :id  ="`bulkReassignSubStatus--sub-status-${sub.id}`"
                                                            v-show="false"
                                                        >
                                                    </div>
                                                </div>
                                            </ar-toggle-btn>
                                        </template>
                                    </div>
                                </div>
                                <!-- v-else-if="!isQAAccount" -->
                                <!-- <ar-select
                                    type        ="search" 
                                    id          ="reassign-user"
                                    :placeholder ="`Select ${isQAAccount ? 'User' : reassignment_mode}`"
                                    :label      ="isQAAccount ? 'User' : reassignment_mode"
                                    v-model     ="selected_campaign_user"
                                    :options    ="filtered_campaign_users"
                                >
                                    Select {{ isQAAccount ? 'User' :reassignment_mode}}
                                </ar-select> -->
                            </div>
                            <!-- <template v-if="reassignment_mode == 'Researcher'">
                                <div class="col-md-12" v-if="canReassignWithCampaign">
                                    <ar-select 
                                        type        ="search" 
                                        id          ="reassign-campaign"
                                        placeholder ="Select Campaign" 
                                        label       ="Campaign" 
                                        v-model     ="reassign_campaign" 
                                        :options    ="campaigns_select" 
                                        :disabled   =" !campaigns_select.length"
                                    >
                                        Select Campaign
                                    </ar-select>
                                </div>
                                <div class="col-md-12" v-if="canReassignWithStatus">
                                    <ar-select 
                                        type        ="search" 
                                        placeholder ="Select Status" 
                                        label       ="Status" 
                                        id          ="reassign-status-select"
                                        v-model     ="reassignStatuses.selected" 
                                        :options    ="reassignStatusOptions" 
                                        :disabled   =" !reassignStatusOptions.length"
                                    >
                                        Select Status 
                                    </ar-select>
                                </div>
                            </template> -->
                        </div>
                    </div>
                </div>

                <div class="pt-0 pl-4 reassign-right-con v-reassignment-reasons-selection" v-if="isReassignWithReason">
                    <div class="reassign-right-heading d-flex justify-content-between align-items-center">
                        <h5> Selected ({{ selected_listings.length }}) listing(s)
                            <span class="mt-1 d-block font-weight-normal">Please select reason(s) to continue reassignment</span>
                        </h5>
                    </div>

                    <div class="right-con pt-3">
                        <div class="reassign-to-wip-notice mt-4 mb-4" v-show="isReassignWIP">
                            <a class="notice-img">
                                <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                            </a>
                            <h6>Notice</h6>
                            <p>These data has already been processed and validated by a recognized third party.</p>
                        </div>
                        <ar-reason-selection
                            :reasons    ="batch_change_status == 99 ? filtered_predefined_reasons  : predefined_reasons"
                            key         ="reassign-to-wip-modal-reasons"
                            :is-loading ="is_loading"
                            is-batch-modal
                        />
                    </div>
                </div>
            </div>
            <template v-slot:buttons v-if="!reassignStatuses.results.length">
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined    ="true"
                    @click="isOpen_batchReassign = false; $route.query.status.toLowerCase() == 'qualified' ? batchReassign.user_type.selected = 1 : batchReassign.user_type.selected = 0"
                >
                    Cancel
                </ar-button>
                <ar-button :disabled="reassignStatuses.loading" @click="doNewReassign()">
                    Reassign
                </ar-button>
            </template>
            <!-- <template v-slot:footer>
                <ar-select 
                    v-if     ="['Reworked','Recheck'].includes($route.query.status) && isAllowedQAReassignment" 
                    v-model  ="reassignment_mode" 
                    :val     ="reassignment_mode" 
                    :options ="[{'value':'Researcher','label':'Researcher'},{'value':'QA','label':'QA'}]" 
                    first_element_as_default
                />
            </template> -->
        </ar-dialog>

        <!-- @modal-close ="resetData" -->
        <ar-batch-modal
            v-bind       ="batchModalProps"
            key          ="batch-change-status"
            :is_loading   = "computedLoading"
            @confirm     ="batchChangeStatus(selected_listings, batch_change_status, 'close-forrework-modal')"
        />

        <ar-dialog 
            id="transfer-to-another-substatus-modal"
            heading="Transfer to Another Sub Status"
            :sub_heading="`You are about to tag ${selected_listings.length} data to substatus: <b>${subStatusesStore.currentSelectedSubStatusLabel}</b>. Confirm by clicking on the 'Proceed' button.`"
            custom_minwidth="1100px">
            <ArLoaderinator :loading="!fetching_reasons" style="min-height: 100px;">
                <div 
                    class="float-left text-left w-100 overflow-auto" 
                    style="min-height: 100px; max-height: 500px"
                >
                    <ar-reason-selection
                        key                ="batch-transfer-to-another-sub-status-reason"
                        v-bind             ="{ reasons: filtered_predefined_reasons }"
                        is-batch-modal
                    />
                </div>
            </ArLoaderinator>
            <template v-slot:buttons>
                <ar-button a_class="mr-2" data-dismiss="modal" :outlined="true">
                    Cancel
                </ar-button>
                <ar-button @click="updateListingSubStatus(selected_listings)" :disabled="is_loading">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <ar-dialog
            id="remove-listing-modal"
            heading="Remove Listing"
            heading_style="color: #DC3C40;"
            custom_minwidth="1100px"
            :sub_heading="`A selection of predefined reasons are available below. Confirm by clicking on the 'Confirm' button.`"
        >
            <!-- <ar-textfield
                a_type="textarea"
                a_class="text-left"
                a_style="height: 100px; resize: none"
                label="Comment"
                placeholder="Comment here"
                v-model="remove_listing.comment"
            /> -->
            <ArLoaderinator :loading="!fetching_reasons" style="min-height: 100px;">
                <div 
                    class="float-left text-left w-100 overflow-auto" 
                    style="min-height: 100px; max-height: 500px"
                >
                    <ar-reason-selection
                        key                ="batch-change-status-reason"
                        v-bind             ="{ reasons: filtered_predefined_reasons }"
                        is-batch-modal
                    />
                </div>
            </ArLoaderinator>
            <template v-slot:buttons>
                <ar-button
                    class="mr-2"
                    data-dismiss="modal"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button @click="removeListing()" :disabled="!canRemoveListing">
                    Proceed
                </ar-button>
            </template>
        </ar-dialog>

        <!--Batch invalid modal-->
        <!--<ar-dialog
            id            ="set-invalid-modal"
            heading       ="Mark as Invalid"
            heading_style ="color: #DC3C40;"
            sub_heading   ="Fill in your comments in regards in marking this product to invalid."
        >
            <ar-textfield
                a_type      ="textarea"
                a_class     ="text-left"
                a_style     ="height: 100px; resize: none"
                label       ="Comment"
                placeholder ="Comment here"
                v-model     ="batch_comment"
            />
            </ar-textfield>
            <template v-slot:buttons>
                <ar-button
                    id           ="close-invalid-modal"
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    :outlined    ="true"
                    @click       ="batch_comment = ''"
                >
                    Cancel
                </ar-button>
                <ar-button @click="batchChangeStatus(selected_listings, 99, batch_comment, 'close-invalid-modal')">
                    Finish
                </ar-button>
            </template>
        </ar-dialog>-->

        <!--Batch has potential modal-->
        <!--<ar-dialog
            id            ="set-haspo-modal"
            heading       ="Mark as Has Potential"
            heading_style ="color: #79BD9A;"
            sub_heading   ="Fill in your comments in regards in marking this product as has potential."
        >
            <ar-toggle-btn v-if="selected_listings.length > 0">
                <template>
                    <div class="d-block w-100">    
                        <div 
                            class  ="btn status-for-commission comm-status" 
                            @click ="selected_comm = 1"
                        >
                            <img src="/static/svg/Icon_Commission.svg" alt="icon">
                            <h5>With Commission</h5>
                            <p>Variable-pay for reward</p>
                            <input type="radio" name="qualified-comm" id="commissionx">
                        </div>
                        <div 
                            class  ="btn status-remove-commission comm-status" 
                            @click ="selected_comm = 0"
                        >
                            <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                            <h5>No Commission</h5>
                            <p>No reward</p>
                            <input type="radio" name="qualified-comm" id="no-commissionx">
                        </div>
                    </div>
                </template>
            </ar-toggle-btn>
            <ar-textfield
                a_type="textarea"
                a_class="text-left"
                a_style="height: 100px; resize: none"
                label="Comment"
                placeholder="Comment here"
                v-model="batch_comment"
            ></ar-textfield>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    id="close-haspo-modal"
                    :outlined="true"
                    @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="batchChangeStatus(selected_listings,98,batch_comment,'close-haspo-modal')">
                    Finish
                </ar-button>
            </template>
        </ar-dialog>-->

        <!--Batch for rework modal-->
        <!-- <ar-dialog
            id            ="set-forrework-modalx"
            heading       ="Mark as For Rework"
            heading_style ="color: #2D599E;"
            sub_heading   ="A selection of predefined reasons are available below. Confirm by clicking on the 'Confirm' button."
            :has_footer   ="false"
            @modal-close  ="resetData()"
            key           ="for-rework-modalx"
            large
        >
            <div class="float-left text-left w-100 overflow-auto" style="height: 300px; max-height: 500px">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                    <li class="nav-item has-treeview" v-for="(predefined, index) in predefined_reasons" :key="index">
                        <a href="javascript:;" class="nav-link" data-widget="treeview">
                            <template v-if="!isObject(predefined.reason)">
                                <div class="custom-control custom-checkbox">
                                    <input class="custom-control-input" v-if="predefined.type != 1"  v-model="selected_predefined_reasons" :value="predefined.id" type="checkbox" :id="'predefined-'+predefined.id+'-'+index">
                                    <input class="custom-control-input" v-else v-model="selected_other_reason" :value="predefined.id" type="checkbox" :id="`predefined-${predefined.id}-${index}`" @change="scrollIntoView('for-rework-other-comment')">
                                    <label :for="'predefined-'+predefined.id+'-'+index" class="custom-control-label font-weight-normal" v-if="!isObject(predefined.reason)">{{predefined.reason}}</label>
                                </div>
                            </template>
                            <div v-else>
                                <i class="nav-icon fas fa-th"></i>
                                <p :id="'predefined-'+predefined.id" :reason-id="predefined.id">
                                    {{ Object.keys(predefined.reason)[0] }}
                                </p>
                                <i class="fas fa-angle-left right ml-2" ></i>
                            </div>
                    </a>
                    &lt;!&ndash;Sub Reasons&ndash;&gt;
                    <ul class="nav nav-treeview pl-5" v-if="typeof predefined.reason == 'object'">
                        <li class="nav-item" v-for="(subreason,index) in predefined.reason[Object.keys(predefined.reason)[0]]" :key="subreason.id">
                            <a href="javascript:;" class="nav-link">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        class="custom-control-input"
                                        v-model="selected_predefined_sub_reasons"
                                        :value="{'id':subreason.id,'reason_id':predefined.id}"
                                        :sub-reason-id="subreason.id"
                                        :reason-id="predefined.id"
                                        type="checkbox"
                                        :id="'predefined-'+subreason.id+'-'+index"
                                    >
                                    <label
                                        :for="'predefined-'+subreason.id+'-'+index"
                                        class="custom-control-label font-weight-normal"
                                    >
                                        {{subreason.reason}}
                                    </label>
                                </div>
                            </a>
                        </li>
                    </ul>
                </li>
                </ul>
                <div  class="animated fadeIn form-group a-textfield text-left col-md-11" v-show="selected_other_reason.length">
                    <label for="" class="col-form-label">Comment</label>
                    <div id="for-rework-other-comment" class="form-group" tabindex="-1">
                        <textarea v-model.trim="other_reason" placeholder="Comment here" class="form-control" spellcheck="false" style="height: 100px; resize: none;"></textarea>
                    </div>
                </div>
                <ar-reason-selection :reasons="predefined_reasons" key="for-rework-modal-reasonsx"/>
            </div>
            <ar-textfield
                a_type      ="textarea"
                a_class     ="text-left"
                a_style     ="height: 100px; resize: none"
                label       ="Comment"
                placeholder ="Comment here"
                v-model     ="batch_comment"
            />
            <template v-slot:buttons>
                <ar-button
                    a_class      ="mr-2"
                    data-dismiss ="modal"
                    id           ="close-forrework-modal"
                    @click       ="batch_comment = ''"
                    :disabled    ="is_loading"
                    outlined
                >
                    Cancel
                </ar-button>
                <ar-button
                    @click    ="batchChangeStatus(selected_listings, 2, batch_comment, 'close-forrework-modal')"
                    :disabled ="is_loading"
                >
                    Confirm
                </ar-button>
            </template>
        </ar-dialog> -->

        <!--Batch qualified modal-->
        <!--<ar-dialog
            id="set-qualified-modal"
            heading="Mark as Qualified"
            heading_style="color: #29ABA2;"
            sub_heading="Fill in your comments in regards in marking this product as qualified.">
            <ar-toggle-btn v-if="selected_listings.length > 0">
                <template>
                    <div class="d-block w-100">    
                        <div class="btn status-for-commission comm-status" @click="selected_comm = 1">
                            <img src="/static/svg/Icon_Commission.svg" alt="icon">
                            <h5>With Commission</h5>
                            <p>Variable-pay for reward</p>
                            <input type="radio" name="qualified-comm" id="commission">
                        </div>
                        <div class="btn status-remove-commission comm-status" @click="selected_comm = 0">
                            <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                            <h5>No Commission</h5>
                            <p>No reward</p>
                            <input type="radio" name="qualified-comm" id="no-commission">
                        </div>
                    </div>
                </template>
            </ar-toggle-btn>
            <ar-textfield
                a_type="textarea"
                a_class="text-left"
                a_style="height: 100px; resize: none"
                placeholder="Comment here"
                v-model="batch_comment"
            ></ar-textfield>
            <template v-slot:buttons>
                <ar-button
                    a_class="mr-2"
                    data-dismiss="modal"
                    :outlined="true"
                    id="close-qualified-modal"
                    @click="batch_comment = ''">
                    Cancel
                </ar-button>
                <ar-button @click="batchChangeStatus(selected_listings,3,batch_comment,'close-qualified-modal')">
                    Finish
                </ar-button>
            </template>
        </ar-dialog>-->

        <!--Batch auto-qualified modal-->
        <!--<ar-dialog
            id            ="set-autoQ-modal"
            heading       ="Mark as Auto Qualified"
            heading_style ="color: #29ABA2;"
            sub_heading   ="Fill in your comments in regards in marking this product as auto-qualified."
            :has_footer   ="false"
            :has_button   ="false"
        >
            <ar-textfield
                a_type      ="textarea"
                a_class     ="text-left"
                a_style     ="height: 100px; resize: none"
                label       ="Comment"
                placeholder ="Comment here"
                v-model     ="batch_comment"
                :disabled   ="is_loading"
            />
            <ar-button
                a_class      ="mr-2"
                data-dismiss ="modal"
                :outlined    ="true"
                id           ="close-atouQ-dialog"
                @click       ="batch_comment = ''"
                :disabled    ="is_loading"
            >
                Cancel
            </ar-button>
            <ar-button  
                @click    ="batchChangeStatus(selected_listings, 30, batch_comment, 'close-atouQ-dialog')" 
                :disabled ="is_loading"
            >
                {{is_loading ? 'Processing...' : 'Confirm'}}
            </ar-button>
            
            <span class="mt-5 d-block text-center">
                <a 
                    v-if   ="already_worked_lid != ''"
                    class  ="text-danger text-bold animated fadeIn" 
                    style  ="font-size:14px"
                    :href  ="`verification/search?status=All&platform=0&lid=${ already_worked_lid }`"
                    target ="_blank"
                >
                    The seller(s) of the <u>listing(s)</u> already has a working item in this campaign. Click to check.
                </a>
            </span>
        </ar-dialog>-->
        <!-- @modal-close="resetData()" -->
        <ar-modal id="listing-modal" >
            <template v-slot:top-btn>
                <ar-button
                    a_class="a-btn-text mr-2"
                    data-dismiss="modal"
                    id="back-to-product"
                    icon="fas fa-chevron-left"
                >
                    <span>{{active_campaign}}</span>
                </ar-button>
                <span v-if="canEdit" :class="editListingRestriction">
                    <ar-button
                        id="link-edit-listing"
                        a_class="a-btn-text float-right"
                        icon="fas fa-pen"
                        @click="editListing"
                    >
                        Edit Listing
                    </ar-button>
                </span>
                <template v-if="canChangeStatus && isListingInfoReady">
                    <ar-button
                        v-show="!change_status"
                        a_class="a-btn-text mr-3 float-right"
                        icon="fas fa-sync"
                        :class="changeStatusRestrictionName"
                        @click="switchHistoryAndStatus(true)"
                    >
                        {{ !post_qa_status.includes($route.query.status) ? 'Change Status' : 'Comment' }}
                    </ar-button>
                    <ar-button
                        v-show="change_status"
                        a_class="a-btn-text mr-3 float-right"
                        icon="fas fa-redo"
                        @click="switchHistoryAndStatus(false); show_predefined_reasons = false">
                        Status History
                    </ar-button>
                </template>
               <!-- v-if="isListingInfoReady && checkAuthorization && !disabledReassignAccounts" -->
               <span
                    class="v-status-change-reassign"
                    v-restrict="'v-status-change-reassign'"
                >
                    <ar-button
                        v-if="isListingInfoReady && active_listing_info[0]?.qflag_id == 99 && [7, 5].includes(access_level) ? true : isListingInfoReady"
                        :a_class="`a-btn-text mr-3 float-right ${individualReassign.show ? 'active' : ''}`"
                        icon="fas fa-user-tag"
                        @click="individualReassign.show = !individualReassign.show; checkForInvalidLFC()"
                    >
                        Reassign
                    </ar-button>
                </span>
                <ar-button
                    v-if    ="isListingInfoReady"
                    a_class ="a-btn-text float-right"
                    icon    ="fas fa-redo"
                    @click  ="refreshData(active_listing_id)"
                >
                    Refresh Data
                </ar-button>
                <ar-button
                    v-if    ="isListingInfoReady && canAddNotes"
                    a_class ="a-btn-text float-right"
                    @click  ="showAddListingNote()"
                >
                    <i class="fas fa-plus listing-modal-notes-icon mr-1" :class="{'toggled' : listingNote.status}"></i>
                    {{ !listingNote.status ? "Show" : "Hide" }} Notes
                </ar-button>
            </template>
            <template v-if="!isListingInfoReady">
                <div class="listing-loading-state">
                    <img src="/static/img/Modal-Loader.gif" alt="loading">
                </div>
            </template>
            <template v-else>
                <div v-for="(list, index) in active_listing_info" :key="index" class="animated fadeIn">
                    <section> <!--Upper Modal-->
                        <div class="row">
                            <div v-show="!show_predefined_reasons" class="col-md-7 col-xs-12"> <!--Preview container-->
                                <div class="a-modal-seller-info">
                                    <a :href="preview()" target="_blank">
                                        <div class="img-container">
                                            <img :src="preview()" onerror="this.src='/static/img/Listing_Broken_Image.png'" alt="default listing photo">
                                            <img class="campaign-preview" :src="$parent.getImage('campaigns', list.campaign|| 'Default')" :title="list.campaign" alt="default campaign photo">
                                        </div>
                                    </a>
                                    <div class="seller-details">
                                        <a :href="list.seller_url" :target="list.seller_url != 'javascript:void(0);' || list.seller_url != '' ? '_blank' : '_self'">
                                            <img :src="$parent.getImage('platforms', list.list_platform || 'Default')" alt="default campaign photo">
                                        </a>
                                        <h5 class="text-capitalize">{{list.seller_name}} <span>{{list.rid}} - {{list.status}} {{ `${list.status == 'Filed' ? '- Case Number: ' + list.case_number : ''}` }}</span></h5>
                                        {{setQflag(list.qflag_id)}}
                                        <p class="mb-4">{{list.list_title}}</p>
                                        <a class="mb-4" :href="list.list_url" target="_blank">{{list.list_url}}</a>
                                    </div>
                                    <span  title="Researcher" class="text-danger ml-2"> 
                                        <img src="/static/svg/Users_Researchers.svg" alt="researchers" style="width: 11px; position: relative; top: -2px; margin: 0px 2px 0px 4px;" /> 
                                        {{list.owner}} 
                                    </span>
                                    <span title="Date Captured" class="has-bullet-separator ml-2 pl-3" v-show="Object.entries(list.evidences).length > 0">
                                        <!-- <img data-v-31aba694="" src="/static/svg/Users_Researchers.svg" alt="researchers" style="width: 11px; position: relative; top: -2px; margin: 0px 2px 0px 4px;" /> -->
                                        <b>Date Captured:</b> {{list.date_captured || 'Not Set'}}
                                    </span>
                                </div>
                            </div> <!--End of Preview container-->
                            <div :class="show_predefined_reasons ? 'col-md-12' : 'col-md-5'" class="col-xs-12" > <!--History and Status Container-->
                                <div class="a-modal-status-history-con">
                                    <div v-if="listingNote.status">
                                        <h5 class="mb-4">Notes</h5>
                                        <div class="a-modal-status-history mb-3" :class="{ 'text-center': !listingNote.data.length }">
                                            <template v-if="listingNote.is_loading">
                                                <div class="text-center mt-5">
                                                    <div v-for="(spin,index) in [1,2,3]" :key="index" class="spinner-grow text-dark" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <span class="d-block mt-3">Getting Notes..</span>
                                                </div>
                                            </template>
                                            <template v-else-if="listingNote.data.length">
                                                <ar-list 
                                                    type         ="history"
                                                    v-for        ="(note, index) in filterHistory(listingNote.data)"
                                                    :key         ="index"
                                                    :subject     ="name(note.user[0] != null && note.user[0].first_name != null ? note.user[0].first_name : '', note.user[0] != null && note.user[0].last_name != null ? note.user[0].last_name : '')"
                                                    :details     ="note.text"
                                                    :date        ="note.created_at"
                                                    status_icon ="/static/svg/Status_Add.svg"
                                                    action      ="insert"
                                                    :field       ="note.field"
                                                ></ar-list>
                                            </template>
                                            <img src="/static/svg/NoData.svg" class="mt-5" alt="empty" v-else>
                                        </div>
                                        <div class="pr-4">
                                            <ar-textfield 
                                                v-model.trim="listingNote.value" 
                                                a_type="textarea" 
                                                a_style="height: 100px; resize: none;" 
                                                placeholder="Your notes here.."
                                                :disabled="listingNote.is_loading"
                                            />
                                            <ar-button class="float-right" :disabled="!listingNote.value || listingNote.is_loading" @click="addListingNote()">Add</ar-button>
                                        </div>
                                    </div>
                                    <template v-else>
                                        <div class="float-left position-relative" style="width:58%; border-right:1px solid #6EB4F95C" v-show="show_predefined_reasons">
                                            <div class="btn-group btn-group-toggle for-rework-toggles" data-toggle="buttons">
                                                <label 
                                                    class  ="btn btn-outline-primary" 
                                                    :class ="{'active': !reworkTimeline}" 
                                                    @click ="reworkTimeline = false"
                                                >
                                                    <input 
                                                        id           ="rework-mod-1" 
                                                        name         ="rework-mod-option" 
                                                        type         ="radio" 
                                                        autocomplete ="off" 
                                                        checked
                                                    >
                                                    <img :src="reworkTabIcon.reasons" class="mr-3"> Predefined Reasons
                                                </label>
                                                <!-- 
                                                    Deliverable: Hide all For Rework/Reworked elements across all GUI
                                                    Deliverable Number: 454
                                                 -->
                                                <!-- <label
                                                    v-if   ="showReworkTimeline"
                                                    class  ="btn btn-outline-primary" 
                                                    :class ="{'active': reworkTimeline}" 
                                                    @click ="reworkTimeline = true"
                                                >
                                                    <input 
                                                        id           ="rework-mod-2" 
                                                        name         ="rework-mod-option" 
                                                        type         ="radio" 
                                                        autocomplete ="off" 
                                                        checked
                                                    > 
                                                    <img :src="reworkTabIcon.timeline" class="mr-3"> Rework Timeline
                                                </label> -->
                                            </div>
                                            <div class="reassign-to-wip-notice mr-2 mb-5" v-show="isReassignWIP">
                                                <a class="notice-img">
                                                    <img onerror="this.src='/static/svg/Notice_SVG.svg'" src="/static/svg/Notice_SVG.svg" alt="woman-smiling-with-notice">
                                                </a>
                                                <h6>Notice</h6>
                                                <p>These data has already been processed and validated by a recognized third party.</p>
                                            </div>
                                            <div class="overflow-auto" style="max-height:450px;" v-show="!reworkTimeline">
                                                <div class="pr-4" v-if="selected_change_status === -99">
                                                    <h5 class="mb-4">Remove current listing</h5>
                                                    <p>Please <b>provide reason(s)</b> on why you want to remove this listing in the textbox below. Confirm by clicking on the 'Confirm' button.</p>

                                                    <ar-textfield
                                                        a_type="textarea"
                                                        a_class="text-left"
                                                        a_style="height: 100px; resize: none"
                                                        label="Comment"
                                                        placeholder="Comment here"
                                                        v-model="remove_listing.comment"
                                                    />
                                                </div>
                                                <div v-else-if="fetching_reasons">
                                                    <h5 class="mb-4">{{ predefined_status == 'Remove Listing' ? 'Remove Listing' : `Mark as ${predefined_status}` }}</h5>
                                                    <!-- <p>A selection of predefined reasons are available below. Select <b>Others</b> to provide a custom reason. <br/> Confirm by clicking on the 'Confirm' button.</p> -->

                                                    <ar-reason-selection
                                                        key                ="listing-modal-reasons"
                                                        :reasons           ="(batch_change_status == 99 || selected_change_status == 99) ? filtered_predefined_reasons : predefined_reasons"
                                                        :is-loading        ="is_loading"
                                                    />
                                                </div>
                                                <div v-else class="col-xs-1 text-center" style="margin-top: 25%">
                                                    <p>Fetching Predefined Reasons</p>
                                                    <div 
                                                        v-for ="count in [1,2,3]"
                                                        :key  ="count" 
                                                        class ="spinner-grow text-dark"
                                                        role  ="status"
                                                    >
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <template v-else>
                                                <div class="d-flex justify-content-start align-items-center">
                                                    <h5 class="rework-modification-counter mr-3">2 times <span>Tagged as For Rework</span></h5>
                                                    <h5 class="rework-modification-counter">2 times <span>Commission status was changed</span></h5>
                                                </div>
                                                <ar-rework-timeline/>
                                            </template> -->
                                            <!-- 
                                                Deliverable: Hide all For Rework/Reworked elements across all GUI
                                                Deliverable Number: 454
                                             -->
                                            <!-- <div v-show="reworkTimeline && showReworkTimeline">
                                                <ar-rework-timeline :histories="active_listing_info[0].general_history"/>
                                            </div> -->
                                        </div>
                                        <div 
                                            v-if="individualReassign.show"
                                            :style="show_predefined_reasons ? 'width: 39.2%' : 'width: 100%'"
                                            :class="{'float-right': isReassignWithReason}"
                                        >
                                            <template v-if="isListingInfoReady">
                                                <!-- <h5 class="mb-4">Reassign to another {{ isQAAccount ? 'User' : reassignment_mode }}</h5> -->
                                                <h5 class="mb-4">Reassign to another Account</h5>
                                                <div class="pr-3">
                                                    <div class="col-md-12">
                                                        <!-- <div class="col-md-12" v-if="isQAAccount"> -->
                                                        <div
                                                            v-if="!$route.query.status === 'Archived'"
                                                            v-restrict="'v-reassignment-user-type'"
                                                            class="col-md-12 v-reassignment-user-type"
                                                        >
                                                            <ar-select
                                                                type        ="reassign-QA"
                                                                placeholder ="Select User Type"
                                                                id          ="reassignment-user-type"
                                                                :options    ="reassignment_user_type"
                                                                :disabled   ="!reassignment_user_type.length"
                                                                :first_element_as_default = "true"
                                                                :val        ="individualReassign.user_type.selected"
                                                                v-model.number="individualReassign.user_type.selected"
                                                                label       ="User Type"
                                                            >
                                                                <!-- :default_val="{label: `All`, value: 0}" -->
                                                                Select Account Type
                                                            </ar-select>
                                                        </div>
                                                        <!-- <div class="col-md-12" v-if="isQAAccount"> -->
                                                        <div class="col-md-12 v-reassignment-account" v-restrict="'v-reassignment-account'">
                                                            <!-- <label for="" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">{{individualReassign.user_type.selected == 0 ? 'All Users' : individualReassign.user_type.selected == 1 ? 'Researchers and CL' : 'QA'}}</label> -->
                                                            <label for="" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Account</label>
                                                            <multiselect  
                                                                v-model      ="individualReassign.user.selected" 
                                                                :options     ="filtered_campaign_users" 
                                                                group-values ="groupValues"
                                                                group-label  ="groupLabel"
                                                                placeholder  ="Type to search" 
                                                                track-by     ="label"
                                                                label        ="label"
                                                                class        ="mb-3"
                                                            >
                                                                <span slot="noResult">Oops! No account(s) found. Consider changing the search query.</span>
                                                            </multiselect>
                                                        </div>
                                                        <!-- individualReassign.user_type.selected == 2 is QA -->
                                                        <!-- <div class="col-md-12" v-if="isQAAccount && !check_qa_selected"> -->
                                                        <div class="col-md-12">
                                                            <ar-select
                                                                v-restrict     ="'v-reassignment-campaign'"
                                                                class          ="v-reassignment-campaign"
                                                                type           ="search"
                                                                placeholder    ="Select Campaign"
                                                                id             ="reassignment-campaigns"
                                                                :options       ="campaigns_select"
                                                                :disabled      ="!campaigns_select.length"
                                                                v-model.number ="individualReassign.campaign.selected"
                                                                :default_val   ="{ label: active_listing_info[0].campaign, value: active_listing_info[0].campaign_id }"
                                                                label          ="Campaigns"
                                                            />
                                                            <div class="individual-reassign-status-and-substatus-con">
                                                                <ar-select
                                                                    v-restrict     ="'v-reassignment-status'"
                                                                    class          ="v-reassignment-status mb-0"
                                                                    textfield_class="mb-0"
                                                                    type           ="search"
                                                                    id             ="individualReassign-status"
                                                                    placeholder    ="Select Status"
                                                                    v-model.number ="individualReassign.status.selected"
                                                                    :options       ="reassignStatusOptions"
                                                                    :disabled      ="!reassignStatusOptions.length"
                                                                    label          ="Status"
                                                                />
                                                                <template v-if="reassignmentSubStatusOptions?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-2">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in reassignmentSubStatusOptions"
                                                                                class="btn sub-status d-flex align-items-center reassignment"
                                                                                style="padding-left: 37px !important;"
                                                                                :key="`reassignSubStatus--${sub_index}`"
                                                                                @click="toggleSubStatusReassignment($event, sub.id ?? -1)"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`reassignSubStatus--sub-status`"
                                                                                    :id  ="`reassignSubStatus--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-show="selected_change_status == 99 && checkIndividualLFC"
                                                            class="lfc-invalid-note"
                                                        >
                                                            <span style="display: flex; align-items: center">
                                                                <i class="fas fa-info-circle d-block text-md mr-2" />
                                                                <span class="lfc-invalid-note-header">
                                                                    Please note that your selected reason will be seen directly by the client handling this campaign. 
                                                                    Make sure to only select the most appropriate reason for invalidation.
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <!-- v-else-if="!isQAAccount" -->
                                                        <!-- <ar-select
                                                            type="search"
                                                            id="individualReassign-user"
                                                            :placeholder="`Select ${reassignment_mode}`"
                                                            :options="filtered_campaign_users"
                                                            v-model.number="individualReassign.user.selected"
                                                            :default_val="{label:list.owner, value:list.user_id}"
                                                            :label="reassignment_mode"
                                                        >
                                                            Select {{isQAAccount ? 'User' : reassignment_mode}}
                                                        </ar-select> -->
                                                    </div>
                                                    <!-- <template v-if="reassignment_mode == 'Researcher'">
                                                        <div class="col-md-12" v-if="canReassignWithCampaign">
                                                            <ar-select
                                                                type="search"
                                                                placeholder="Select Campaign"
                                                                id="reassignment-campaigns"
                                                                :options="campaigns_select"
                                                                :disabled=" !campaigns_select.length"
                                                                v-model.number="individualReassign.campaign.selected"
                                                                label="Campaigns"
                                                            >
                                                                Select Campaigns
                                                            </ar-select>
                                                        </div>
                                                        <div class="col-md-12" v-if="canReassignWithStatus">
                                                            <ar-select
                                                                type="search"
                                                                id="individualReassign-status"
                                                                placeholder="Select Status"
                                                                v-model.number="individualReassign.status.selected"
                                                                :options="reassignStatusOptions"
                                                                :disabled=" !reassignStatusOptions.length"
                                                                label="Status"
                                                            />
                                                        </div>
                                                    </template> -->
                                                    <!-- <div 
                                                        class="col-md-12" 
                                                        v-if="['Reworked','Recheck'].includes($route.query.status) && isAllowedQAReassignment"
                                                    >
                                                        <ar-select 
                                                            v-model  ="reassignment_mode" 
                                                            :val     ="reassignment_mode" 
                                                            :options ="[{'value':'Researcher','label':'Researcher'},{'value':'QA','label':'QA'}]" 
                                                            first_element_as_default
                                                        />
                                                    </div> -->
                                                    <div class="col-md-12">
                                                        <ar-button a_class="float-right mt-3" @click="doNewReassign(true, list)">Confirm</ar-button>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div :class="{'d-none' : show_predefined_reasons}" v-else-if="!change_status || !canChangeStatus">
                                            <template>
                                                <h5 class="mb-4">Status History</h5>
                                                <div class="a-modal-status-history">
                                                    <div v-show="isHistoryReady">
                                                        <ar-list
                                                            type          ="history"
                                                            v-for         ="(history, index) in filterHistory(list.general_history)"
                                                            :key          ="index"
                                                            :subject      ="name(history.user[0] != null && history.user[0].first_name != null ? history.user[0].first_name : '', history.user[0] != null && history.user[0].last_name != null ? history.user[0].last_name : '')"
                                                            :details.sync ="history.text"
                                                            :date         ="history.created_at"
                                                            :status_icon  ="getStatusIcon(history.value, history.field)"
                                                            :action       ="history.action"
                                                            :field        ="history.field"
                                                        />
                                                    </div>
                                                    <div class="d-flex justify-content-center" v-if="!isHistoryReady">
                                                        <div v-for="(spin, index) in [1, 2, 3]" :key="index" class="spinner-grow text-dark ml-2 mt-5" role="status"></div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div 
                                            :class="{'float-right' : show_predefined_reasons}"
                                            :style="!show_predefined_reasons || 'width:39.2%'"
                                            v-else-if="change_status"
                                        >
                                            <template v-if="subStatusesOnlyOptions?.length">
                                                <div class="animated fadeIn pr-3">
                                                    <h5 class="mb-4">Transfer to another sub status</h5>
                                                    <ar-toggle-btn>
                                                        <div class="sub-statuses-container w-100" style="border: none;">
                                                            <div 
                                                                v-for="(sub, sub_index) in subStatusesOnlyOptions"
                                                                class="btn sub-status only d-flex align-items-center"
                                                                :key="`transfer-sub-only--${sub_index}`"
                                                                @click="toggleSubStatusOnly($event, sub.id ?? -1, list.status, list.qflag_id)"
                                                                @mousedown="removeSubStatusFocus()"
                                                            >
                                                                <h5>{{sub.label}}</h5>
                                                                <input 
                                                                    type ="radio" 
                                                                    :name ="`transfer-sub-only--sub-status`"
                                                                    :id  ="`transfer-sub-only--${sub.id}`"
                                                                    v-show="false"
                                                                >
                                                            </div>
                                                        </div>
                                                    </ar-toggle-btn>
                                                    <ar-button 
                                                        @click="updateListingSubStatus()"
                                                        a_class   ="float-right mb-4" 
                                                        :disabled ="is_loading"
                                                    >
                                                        {{is_loading ? 'Processing...' : 'Proceed'}}
                                                    </ar-button>
                                                </div>
                                                <hr style="clear: both; margin-right: 30px;"/>
                                            </template>
                                            <template>
                                                <h5 class="mb-4">{{ !post_qa_status.includes($route.query.status) ? 'Select a Status change' : 'Add a Comment' }}</h5>
                                                <div class="pr-3">
                                                    <ar-toggle-btn :toggled="selected_change_status == 3">
                                                        <template v-if="!post_qa_status.includes($route.query.status)">
                                                            <!-- v-restrict="'v-status-change-wip'" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if   ="$route.query.status === 'Archived' && !isFeatureRestricted('v-status-change-wip')"
                                                                    class  ="btn status-wip mb-0 mt-2 v-status-change-wip w-100"
                                                                    style  ="border-radius: 4px 4px 0px 0px !important"
                                                                    :class ="{ 'active': +selected_change_status === ListingStatus.WIP && show_predefined_reasons }"
                                                                    @click ="getChangeStatus(ListingStatus.WIP, 'Work in Progress'); $store.dispatch('resetPredefinedReasonState')"
                                                                
                                                                >
                                                                    <img src="/static/svg/Icon_WIP.svg" alt="icon">
                                                                    <h5>Mark as Work In Progress</h5>
                                                                    <p>Listing still needs to be worked on</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="wip">
                                                                </div>
                                                                <template v-if="+selected_change_status === ListingStatus.WIP && subStatuses['Work in Progress']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['Work in Progress']?.filter(s => s.id)?.length ? subStatuses['Work in Progress'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`WorkinProgress--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, ListingStatus.WIP)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`WorkinProgress--sub-status`"
                                                                                    :id  ="`WorkinProgress--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>

                                                            <!-- v-show="![1,2].includes(list.qflag_id) && [1,5].includes(access_level)" -->
                                                            <!-- v-restrict="'v-status-change-researched'" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if="![1, 2].includes(list.qflag_id) && !isFeatureRestricted('v-status-change-researched')"
                                                                    class="btn status-researched v-status-change-researched w-100"
                                                                    :class="{'active': selected_change_status == 1 && show_required_list}"
                                                                    @click="getChangeStatus(1,'Researched'); $store.dispatch('resetPredefinedReasonState');
                                                                            show_required_list = !show_required_list; currentAssignmentCopy = currentAssignedCampaign"
                                                                >
                                                                    <img src="/static/svg/Mark_Researched.svg" alt="icon">
                                                                    <h5>Mark as Researched</h5>
                                                                    <p>Done gathering needed information</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="researched" checked>
                                                                </div>
                                                                <template v-if="+selected_change_status === 1 && subStatuses['Researched']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['Researched']?.filter(s => s.id)?.length ? subStatuses['Researched'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`Researched--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, 1)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`Researched--sub-status`"
                                                                                    :id  ="`Researched--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>
                                                            <div
                                                                v-if   ="show_required_list && requiredFields.length !== 0 && selected_change_status == 1 "
                                                                class  ="row"
                                                                style  ="width: 100%; border:1px solid #ccc; position:relative; left:8px; border-radius: 5px; padding: 13px 0 0"
                                                            >
                                                                <div style="width: 70%; margin: 0 auto;">
                                                                    <h5 class="mb-3">Please check the required fields: </h5>
                                                                    <div class="row">
                                                                        <ul v-if="evidenceRequiredFields.length !== 0" class="col-md-6">
                                                                            <h5>Evidence</h5>
                                                                            <li
                                                                                class="text-capitalize list-unstyled"
                                                                                style="margin-bottom: 2px;"
                                                                                v-for="(field, index) in evidenceRequiredFields"
                                                                                :key="index"
                                                                            >
                                                                                <i class="fas" :class="field.isComplete ? 'fa-check text-green' : 'fa-times text-red'"></i> {{ field.label }}
                                                                            </li>
                                                                        </ul>

                                                                        <ul v-if="dataRequiredFields.length !== 0" class="col-md-6">
                                                                            <h5>Data</h5>
                                                                            <li
                                                                                class="text-capitalize list-unstyled"
                                                                                style="margin-bottom: 2px;"
                                                                                v-for="(field, index) in dataRequiredFields"
                                                                                :key="index"
                                                                            >
                                                                                <i class="fas" :class="field.isComplete ? 'fa-check text-green' : 'fa-times text-red'"></i> {{ field.label }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <!--                             
                                                                Deliverable #552: Removal of Invalid status from Artemis
                                                            -->
                                                            <!-- v-show="![99, 66].includes(list.qflag_id)" -->
                                                            <!-- <div
                                                                v-if="![99, 66].includes(list.qflag_id) && $featureAccess.isAllowed('v-status-change-invalid')"
                                                                class="btn status-invalid v-status-change-invalid"
                                                                :class="{'active': selected_change_status == 99 && show_predefined_reasons}"
                                                                v-show="![99, 66].includes(list.qflag_id)"
                                                                @click="getChangeStatus(99, 'Invalid'); $store.dispatch('resetPredefinedReasonState'); checkForInvalidLFC()"
                                                            >
                                                                <img src="/static/svg/Mark_Invalid.svg" alt="icon">
                                                                <h5>Mark as Invalid</h5>
                                                                <p>Not conforming to valid specifications</p>
                                                                <input type="radio" name="status-change" id="invalid">
                                                            </div> -->
                                                            <div 
                                                                v-if="[4, 1, 3, 98, 66].includes(list.qflag_id) && $featureAccess.isAllowed('v-status-change-remove-listing')"
                                                                class="btn status-invalid v-status-change-remove-listing w-100"
                                                                :class="{'active': selected_change_status == 99 && show_predefined_reasons}"
                                                                v-show="[4, 1, 3, 98, 66].includes(list.qflag_id)"
                                                                @click="getChangeStatus(99, 'Remove Listing'); $store.dispatch('resetPredefinedReasonState');"
                                                            >
                                                                <img src="/static/svg/Icon_Delete.svg" alt="icon">
                                                                <h5>Remove Listing</h5>
                                                                <p>Remove/delete this current listing.</p>
                                                                <input type="radio" name="status-change" id="invalid">
                                                            </div>

                                                            <div
                                                                v-show="selected_change_status == 99 && checkIndividualLFC"
                                                                class="lfc-invalid-note"
                                                            >
                                                                <span style="display: flex; align-items: center">
                                                                    <i class="fas fa-info-circle d-block text-md mr-2" />
                                                                    <span class="lfc-invalid-note-header">
                                                                        Please note that your selected reason will be seen directly by the client handling this campaign. 
                                                                        Make sure to only select the most appropriate reason for invalidation.
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <!-- <div
                                                                class="btn status-qualified"
                                                                :class="{'active': selected_change_status == 41}"
                                                                v-show="[4].includes(+list.qflag_id) && [1].includes(access_level)"
                                                                @click="getChangeStatus(41, 'For Purchase');"
                                                            >
                                                                <img src="/static/svg/ForPurchase-Status.svg" alt="icon">
                                                                <h5>Mark as For Purchase</h5>
                                                                <p>Listing needs to be purchased first</p>
                                                                <input type="radio" name="status-change" id="forpurchase">
                                                            </div> -->
                                                            <!-- v-show="[2, 7].includes(access_level) && list.qflag_id != 98 && $route.query.status !== 'Client Listing'" -->
                                                            <!-- v-restrict="'v-status-change-has-potential'" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if="!isFeatureRestricted('v-status-change-has-potential')"
                                                                    class="btn status-haspo mb-0 v-status-change-has-potential w-100"
                                                                    style="border-radius: 4px 4px 0px 0px !important"
                                                                    :class="{ 'active': selected_change_status == 98 && show_predefined_reasons }"
                                                                    v-show="![98, 66].includes(list.qflag_id) && $route.query.status !== 'Client Listing'"
                                                                    @click="getChangeStatus(98,'Has Potential'); $store.dispatch('resetPredefinedReasonState')"
                                                                >
                                                                    <img src="/static/svg/Mark_HasPotential.svg" alt="icon">
                                                                    <h5>Mark as Has Potential</h5>
                                                                    <p>Possibility for qualification</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="haspo">
                                                                </div>
                                                                <div
                                                                    class="v-status-change-has-potential"
                                                                    v-restrict="'v-status-change-has-potential'"
                                                                    v-if="selected_change_status == 98 && list.qflag_id != 98 && show_predefined_reasons"
                                                                    style="border: 1px solid #79bd9a59; margin-left: -1px; width: 99.9%"
                                                                >
                                                                    <ar-toggle-btn>
                                                                        <div class="w-100 p-2">
                                                                            <div
                                                                                class="btn status-for-commission comm-status mb-0"
                                                                                @click="selected_comm = 1; reworkCommission = 1"
                                                                            >
                                                                                <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                                                <h5>With Commission</h5>
                                                                                <p>Variable pay for reward</p>
                                                                                <input type="radio" name="commission" id="for-commission">
                                                                            </div>
                                                                            <div 
                                                                                class="btn status-remove-commission comm-status mb-0"
                                                                                @click="selected_comm = 0; reworkCommission = 0"
                                                                            >
                                                                                <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                                                <h5>No Commission</h5>
                                                                                <p>No reward</p>
                                                                                <input type="radio" name="commission" id="no-commission">
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </div>
                                                                <template v-if="+selected_change_status === 98 && subStatuses['Has Potential']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['Has Potential']?.filter(s => s.id)?.length ? subStatuses['Has Potential'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`HasPotential--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, 98)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`HasPotential--sub-status`"
                                                                                    :id  ="`HasPotential--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>
                                                            <!-- 
                                                                Deliverable: Hide all For Rework/Reworked elements across all GUI
                                                                Deliverable Number: 454
                                                             -->
                                                            <!-- <div
                                                                class  ="btn status-for-rework mb-0 mt-2"
                                                                :class ="[{'active': +selected_change_status === 2 && show_predefined_reasons}]"
                                                                style="border-radius: 4px 4px 0px 0px !important"
                                                                v-show ="+access_level === 2 && +list.qflag_id !== 2"
                                                                @click ="getChangeStatus(2, 'For Rework'); $store.dispatch('resetPredefinedReasonState')"
                                                            >
                                                                <img src="/static/svg/Mark_ForRework.svg" alt="icon">
                                                                <h5>Mark as For Rework</h5>
                                                                <p>Need some fixes</p>
                                                                <input type="radio" name="status-change" id="for-rework">
                                                            </div>
                                                            <div 
                                                                v-if="+selected_change_status === 2 && +list.qflag_id !== 2 && show_predefined_reasons"
                                                                style="border: 1px solid #bec1e0; margin-left: -1px; width: 99.9%"
                                                            >
                                                                <ar-toggle-btn>
                                                                    <div class="w-100 p-2">
                                                                        <div 
                                                                            class="btn status-for-commission comm-status mb-0"
                                                                            :class="{'active': reworkLevel === 2}"
                                                                            @click="reworkLevel = 2"
                                                                        >
                                                                            <img src="/static/svg/rework-modifications/Rework_Minor.svg" alt="icon">
                                                                            <h5>Minor Rework</h5>
                                                                            <p>Low Severity Level</p>
                                                                            <input type="radio" name="severity_level" id="minor-rework">
                                                                        </div>

                                                                        <div 
                                                                            class="btn status-remove-commission comm-status mb-0"
                                                                            :class="{'active': reworkLevel === 1}"
                                                                            @click="reworkLevel = 1"
                                                                        >
                                                                            <img src="/static/svg/rework-modifications/Rework_Major.svg" alt="icon">
                                                                            <h5>Major Rework</h5>
                                                                            <p>High Severity Level</p>
                                                                            <input type="radio" name="severity_level" id="major-rework">
                                                                        </div>
                                                                    </div>
                                                                </ar-toggle-btn>
                                                                <ar-toggle-btn>
                                                                    <div class="w-100 px-2">
                                                                        <div 
                                                                            class="btn status-for-commission comm-status"
                                                                            :class="{'active': reworkCommission === 1}"
                                                                            @click="reworkCommission = 1"
                                                                        >
                                                                            <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                                            <h5>With Commission</h5>
                                                                            <p>Variable pay for reward</p>
                                                                            <input type="radio" name="commission" id="for-commission">
                                                                        </div>
                                                                        <div 
                                                                            class="btn status-remove-commission comm-status"
                                                                            :class="{'active': reworkCommission === 0}"
                                                                            @click="reworkCommission = 0"
                                                                        >
                                                                            <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                                            <h5>No Commission</h5>
                                                                            <p>No reward</p>
                                                                            <input type="radio" name="commission" id="no-commission">
                                                                        </div>
                                                                    </div>
                                                                </ar-toggle-btn>
                                                            </div> -->
                                                            <!-- 
                                                                Deliverable: Hide all For Rework/Reworked elements across all GUI
                                                                Deliverable Number: 454
                                                             -->
                                                            <!-- <div
                                                                v-show ="[1,5].includes(access_level) && list.qflag_id != 8 && list.qflag_id == 2"
                                                                class  ="btn status-reworked"
                                                                :class ="[{'active': selected_change_status == 8 && show_required_list}]"
                                                                @click ="getChangeStatus(8, 'Reworked'); $store.dispatch('resetPredefinedReasonState'); show_required_list = !show_required_list"
                                                            >
                                                                <img src="/static/svg/Mark_Reworked.svg" alt="icon">
                                                                <h5>Mark as Reworked</h5>
                                                                <p>Fixes have been implemented</p>
                                                                <input type="radio" name="status-change" id="reworked">
                                                            </div> -->

                                                            <!-- 
                                                                Deliverable: Hide all For Rework/Reworked elements across all GUI
                                                                Deliverable Number: 454
                                                             -->

                                                            <!-- <div 
                                                                v-show="show_required_list && selected_change_status == 8"
                                                                class="row"
                                                                style="width: 100%; border:1px solid #ccc; position:relative; left:8px; border-radius: 5px; padding: 13px 0 0; margin-bottom: 10px;"
                                                            >
                                                                <div style="width: 70%; margin: 0 auto;">
                                                                    <h5 class="mb-3">Please check the required fields: </h5>
                                                                    <div class="row">
                                                                        <ul class="col-md-6" v-for="(type,index) in required_fields" :key="index">
                                                                            <h5 class="mb-1">{{index}}:</h5>
                                                                            <li class="list-unstyled" style="margin-bottom: 2px;" v-for="(value,index) in type" :key="index">
                                                                                <i :class="value ? 'fas fa-check text-green' : 'fas fa-times text-red'"></i>
                                                                                {{index}}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <!-- v-show ="[2,7].includes(access_level) && +list.qflag_id !== 3 && !$route.path.includes('Client Listing')" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if   ="canChangeStatusToQualified"
                                                                    class  ="btn status-qualified mb-0 mt-2 v-status-change-qualified w-100"
                                                                    style  ="border-radius: 4px 4px 0px 0px !important"
                                                                    :class ="{ 'active': +selected_change_status === 3 && show_predefined_reasons }"
                                                                    @click ="getChangeStatus(3, 'Qualified'); $store.dispatch('resetPredefinedReasonState')"
                                                                >
                                                                    <img src="/static/svg/Mark_Qualified.svg" alt="icon">
                                                                    <h5>Mark as Qualified</h5>
                                                                    <p>Suitable for a law case</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="qualified">
                                                                </div>
                                                                <div 
                                                                    class="v-status-change-qualified"
                                                                    v-restrict="'v-status-change-qualified'"
                                                                    v-if="+selected_change_status === 3 && +list.qflag_id !== 3 && show_predefined_reasons"
                                                                    style="border: 1px solid #b7d5f7; margin-left: -1px; width: 99.9%"
                                                                >
                                                                    <ar-toggle-btn>
                                                                        <div class="w-100 p-2">
                                                                            <div 
                                                                                class="btn status-for-commission comm-status mb-0"
                                                                                @click="selected_comm = 1; reworkCommission = 1"
                                                                            >
                                                                                <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                                                <h5>With Commission</h5>
                                                                                <p>Variable pay for reward</p>
                                                                                <input type="radio" name="commission" id="for-commission">
                                                                            </div>
                                                                            <div 
                                                                                class="btn status-remove-commission comm-status mb-0"
                                                                                @click="selected_comm = 0; reworkCommission = 0"
                                                                            >
                                                                                <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                                                <h5>No Commission</h5>
                                                                                <p>No reward</p>
                                                                                <input type="radio" name="commission" id="no-commission">
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </div>
                                                                <template v-if="+selected_change_status === 3 && subStatuses['Qualified']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['Qualified']?.filter(s => s.id)?.length ? subStatuses['Qualified'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`Qualified--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, 3)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`Qualified--sub-status`"
                                                                                    :id  ="`Qualified--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>
                                                            
                                                            <!-- v-show ="[2, 7].includes(access_level) && $route.query.status == 'Client Listing'" -->
                                                            <!-- v-restrict="'v-status-change-accepted'" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if="canBeAccepted"
                                                                    class  ="btn status-accepted mb-0 mt-2 v-status-change-accepted w-100"
                                                                    style  ="border-radius: 4px 4px 0px 0px !important"
                                                                    :class ="{'active': +selected_change_status === 32 && show_predefined_reasons}"
                                                                    @click ="getChangeStatus(32, 'Accepted'); $store.dispatch('resetPredefinedReasonState')"
                                                                >
                                                                    <img src="/static/svg/Mark_Accepted.svg" alt="icon">
                                                                    <h5>Mark as Accepted</h5>
                                                                    <p>Data is confirmed and approved for filing</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="accepted">
                                                                </div>
                                                                <div 
                                                                    class="v-status-change-accepted"
                                                                    v-restrict ="'v-status-change-accepted'"
                                                                    v-if="+selected_change_status === 32 && +list.qflag_id !== 32 && show_predefined_reasons"
                                                                    style="border: 1px solid #b7d5f7; margin-left: -1px; width: 99.9%"
                                                                >
                                                                    <ar-toggle-btn>
                                                                        <div class="w-100 p-2">
                                                                            <div 
                                                                                class="btn status-for-commission comm-status mb-0"
                                                                                @click="selected_comm = 1; reworkCommission = 1"
                                                                            >
                                                                                <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                                                <h5>With Commission</h5>
                                                                                <p>Variable pay for reward</p>
                                                                                <input type="radio" name="commission" id="for-commission">
                                                                            </div>
                                                                            <div 
                                                                                class="btn status-remove-commission comm-status mb-0"
                                                                                @click="selected_comm = 0; reworkCommission = 0"
                                                                            >
                                                                                <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                                                <h5>No Commission</h5>
                                                                                <p>No reward</p>
                                                                                <input type="radio" name="commission" id="no-commission">
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </div>
                                                                <template v-if="+selected_change_status === 32 && subStatuses['Accepted']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['Accepted']?.filter(s => s.id)?.length ? subStatuses['Accepted'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`Accepted--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, 32)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`Accepted--sub-status`"
                                                                                    :id  ="`Accepted--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>

                                                                <!-- <template v-else-if="([3, 98].includes(+list.qflag_id) || +access_level === 2) && +selected_change_status !== 3"> -->
                                                                <template v-else-if="([3, 98].includes(+list.qflag_id)) && +selected_change_status !== 3">
                                                                    <div
                                                                        v-if   ="+list.commission === 1"
                                                                        v-restrict ="'v-button-remove-commission'"
                                                                        class  ="btn status-remove-commission mt-2 v-button-remove-commission w-100"
                                                                        :class ="{'active': selected_comm === 0 && selected_change_status === 0}"
                                                                        @click ="getCommissionValue(0)"
                                                                    >
                                                                        <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                                        <h5>Remove Commission</h5>
                                                                        <p>Detach reward</p>
                                                                        <input v-show="false" type="radio" name="status-change" id="remove-commission">
                                                                    </div>
                                                                    <div
                                                                        v-else
                                                                        v-restrict="'v-button-add-commission'"
                                                                        class  ="btn status-for-commission mt-2 v-button-add-commission w-100"
                                                                        :class ="{'active': +selected_comm === 1 && selected_change_status === 0}"
                                                                        @click ="getCommissionValue(1)"
                                                                    >
                                                                        <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                                        <h5>Add Commission</h5>
                                                                        <p>Variable pay for reward</p>
                                                                        <input v-show="false" type="radio" name="status-change" id="add-commission">
                                                                    </div>
                                                                </template>
                                                            </div>

                                                            <!-- v-restrict="'v-status-change-archived'" -->
                                                            <div class="status-change-options w-100">
                                                                <div
                                                                    v-if="!isFeatureRestricted('v-status-change-archived')"
                                                                    class  ="btn status-accepted mb-0 mt-2 v-status-change-archived w-100"
                                                                    style  ="border-radius: 4px 4px 0px 0px !important"
                                                                    :class ="{'active': +selected_change_status === ListingStatus.Archived && show_predefined_reasons}"
                                                                    v-show ="$route.query.status === 'Invalid'"
                                                                    @click ="getChangeStatus(ListingStatus.Archived, 'Archived'); $store.dispatch('resetPredefinedReasonState')"
                                                                >
                                                                    <img src="/static/svg/Mark_Archived.svg" alt="icon">
                                                                    <h5>Mark as Archived</h5>
                                                                    <p>Archived outside of regular workflow</p>
                                                                    <input v-show="false" type="radio" name="status-change" id="archived">
                                                                </div>
                                                                <template v-if="+selected_change_status === ListingStatus.Archived && subStatuses['archived']?.filter(s => s.id)?.length">
                                                                    <ar-toggle-btn>
                                                                        <div class="sub-statuses-container w-100">
                                                                            <label class="ml-4">Select a sub status</label>
                                                                            <div 
                                                                                v-for="(sub, sub_index) in subStatuses['archived']?.filter(s => s.id)?.length ? subStatuses['archived'] : []"
                                                                                class="btn sub-status d-flex align-items-center"
                                                                                :key="`archived--${sub_index}`"
                                                                                @click="toggleSubStatus($event, sub.id ?? -1, ListingStatus.Archived)"
                                                                                @mousedown="removeSubStatusFocus()"
                                                                            >
                                                                                <h5>{{sub.label}}</h5>
                                                                                <input 
                                                                                    type ="radio" 
                                                                                    :name ="`archived--sub-status`"
                                                                                    :id  ="`archived--sub-status-${sub.id}`"
                                                                                    v-show="false"
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </ar-toggle-btn>
                                                                </template>
                                                            </div>

                                                        </template>
                                                    <!-- <template v-else-if="(+list.qflag_id === 3 || +list.qflag_id === 98 || +access_level === 2) && +selected_change_status !== 3">
                                                        <div
                                                            class="btn status-remove-commission"
                                                            :class="{'active': +selected_comm === 0 && +selected_change_status === 0}"
                                                            @click="getCommissionValue(0, list.qflag_id)"
                                                            v-if="+list.commission === 1"
                                                        >
                                                            <img src="/static/svg/Icon_NoCommission.svg" alt="icon">
                                                            <h5>Remove Commission</h5>
                                                            <p>Detach reward</p>
                                                            <input type="radio" name="status-change" id="remove-commission">
                                                        </div>
                                                        <div
                                                            class="btn status-for-commission"
                                                            :class="{'active': +selected_comm === 1 && +selected_change_status === 0}"
                                                            @click="getCommissionValue(1, list.qflag_id)"
                                                            v-else
                                                        >
                                                            <img src="/static/svg/Icon_Commission.svg" alt="icon">
                                                            <h5>Add Commission</h5>
                                                            <p>Variable pay for reward</p>
                                                            <input type="radio" name="status-change" id="add-commission">
                                                        </div>
                                                    </template> -->
                                                    </ar-toggle-btn>
                                                    <div class="form-group mt-2">
                                                        <ar-textfield 
                                                            v-show="!show_predefined_reasons" 
                                                            v-model.trim="modal_comment" 
                                                            a_type="textarea" 
                                                            a_style="height: 100px; resize: none;" 
                                                            :placeholder="!post_qa_status.includes($route.query.status) ? 'Add Comment' : 'Comment here'"
                                                        />
                                                    </div>
                                                    <ar-button 
                                                        a_class   ="float-right" 
                                                        @click    ="selected_change_status === 99 ? removeListing([list.rid]) : changeStatus(list.rid, list.qflag_id)"
                                                        :disabled ="(selected_change_status === 99 && !canRemoveListing) || computedLoading"
                                                    >
                                                        {{is_loading ? 'Processing...' : 'Confirm'}}
                                                    </ar-button>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div> <!--History and Status Container-->
                        </div>
                    </section> <!--End of Upper Modal-->
                    <div v-show="!show_predefined_reasons"> <!--Lower Modal-->
                        <!-- <hr/> -->
                        <!-- <section class="row" v-show="Object.entries(list.evidences).length > 0 || list.qflag == 41"> -->
                        <section class="row" v-show="Object.entries(list.evidences).length > 0">
                            <div class="col-md-12">
                                <div class="row">
                                    <h5 class="col-md-2">Evidences</h5>
                                    <!-- v-if="([3,31,32,33,98].includes(list.qflag_id) && [2,6].includes(access_level)) || (list.qflag_id == 1 && access_level == 1) || (Object.entries(list.evidences).length > 0 && access_level == 7)" -->
                                    <ar-button 
                                        v-if="[3,31,32,33,98].includes(list.qflag_id) && Object.entries(list.evidences).length > 0"
                                        v-restrict="'v-button-merge-evidence'"
                                        class="v-button-merge-evidence"
                                        @click="mergeEvidences(list.rid)" 
                                        style="margin-left:-6%;bottom:7px;background-color:#29ABA2"
                                    >
                                        Merge Evidences
                                    </ar-button>
                                </div>
                            </div>
                            <div class="col-md-12" v-if="is_uploaded_evidences_ready">
                                <a 
                                    v-for="(evidence, index) in uploaded_evidences" 
                                    :href="evidence.href"
                                    class="a-modal-evidence mr-2"
                                    :key="index" 
                                    target="_blank"
                                    style="width: 24%"
                                >
                                    <!-- <a 
                                        v-show="list.qflag_id == 41 && evidence.type === 'checkout'"
                                        href="javascript:void(0);" 
                                        role="button" 
                                        class="btn-remove" 
                                        @click="removeEvidence(list.rid, evidence.image)"
                                    >
                                        Remove
                                    </a> -->
                                    <img  
                                        :src="evidence.src" 
                                        :alt="evidence.type" 
                                        :onerror="showMissing(evidence.image)"
                                    >
                                    {{evidence.url}}
                                    <span class="text-capitalize">{{evidence.type}}</span>
                                </a>
                                <!-- <div class="upload-modal--box mr-2" style="width: 24%" v-if="list.qflag_id == 41 && uploaded_evidences.filter(e => e.type == 'checkout').length == 0">
                                    <input 
                                        type="file" class="upload--section" 
                                        name="modal--upload-section-checkout" 
                                        id="modal--upload-section-checkout" 
                                        accept="application/pdf"
                                        @dragenter="modalCheckoutUploadDragEnter(0)" 
                                        @dragleave="modalCheckoutUploadDragLeave(0)" 
                                        @drop="modalCheckoutUploadDragLeave(0)" 
                                        @change="uploadModalCheckoutFile($event, list.rid)"
                                    />
                                    <div class="icon" v-tooltip="'Upload Checkout page'">
                                        <img src="/static/svg/case-upload/UploadFile.svg" class="mb-2" alt="upload-icon">
                                        <label class="d-block">Checkout</label>
                                    </div>
                                </div> -->
                            </div>
                        </section>
                        <section> 
                            <div class="row">
                                <div class="col-md-12">
                                    <h5>Listing Information</h5>
                                </div>
                                <div class="col-md-4">
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_Platform.svg" alt="icon">
                                        <h5>{{returnDefault(list.list_platform)}} <span>Platform</span></h5>
                                    </div>
                                    <!-- <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_ItemType.svg" alt="icon">
                                        <h5>{{returnDefault(list.item_class)}}<span>Item Class</span></h5>
                                    </div> -->
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/Trademark_Claim.svg" alt="icon">
                                        <h5>{{returnDefault(list.claim_type)}}<span>Claim Type</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_History.svg" alt="icon">
                                        <h5>{{returnDefault(list.seller_trans_history)}}<span>Seller Transaction History</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_History.svg" alt="icon">
                                        <h5>{{returnDefault(list.trans_history)}}<span>Product Transaction History</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_ShippingLocation.svg" alt="icon">
                                        <h5 v-if="list.listing_location == '' && list.list_platform !== 'Ebay'"> None <span>Item Location</span></h5>
                                        <h5 v-else-if="list.listing_location == '' && list.list_platform === 'Ebay'"> Net Set<span>Item Location</span></h5>
                                        <h5 v-else>{{returnDefault(list.listing_location)}}<span>Item Location</span></h5>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_SellerURL.svg" alt="icon">
                                        <h5>
                                            <a :href="list.seller_url != '' ? list.seller_url : 'javascript:void(0);'" :target="list.seller_url != '' ? '_blank' : '_self'">
                                                {{returnDefault(list.seller_url)}}
                                            </a>
                                            <span>Seller Store URL</span>
                                        </h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_ItemType.svg" alt="icon">
                                        <h5>[Class {{ list.item_class_number }}] {{returnDefault(list.item_type_id)}}<span>Item Type</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/ListingInfo_SampleDummy.svg" alt="icon">
                                        <h5>{{returnDefault(list.dummy_name)}} | {{returnDefault(list.dummy_dummyname)}} | {{returnDefault(list.dummy_pass)}}<span>Dummy Account Used</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/Conversation_Script.svg" alt="icon">
                                        <h5>{{returnDefault(list.keyword)}}<span>Keywords</span></h5>
                                    </div>
                                    <div class="a-modal-seller-info-item">
                                        <img src="/static/svg/SellerInfo_ShippingLocation.svg" alt="icon">
                                        <h5>{{returnDefault(list.shipping_location)}}<span>Shipping Location</span></h5>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row" v-show="list.reasons_list.length">
                                <div class="col-md-12">
                                    <h5 class="mb-3">Infringement Indicators</h5>
                                    <div class="a-modal-check-info-item col-md-5"  v-for="(reason,index) in list.reasons_list" :key="index">
                                        <img src="/static/svg/I.Reasons_Check.svg" alt="icon">
                                        {{reason}}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Seller Contact Information</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                    <h5>{{returnDefault(list.contact_person)}} <span>Contact Person</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Contact_Mobile.svg" alt="icon">
                                    <h5>{{returnDefault(list.mobile_no)}}<span>Mobile Number</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Contact.svg" alt="icon">
                                    <h5>{{returnDefault(list.telephone)}}<span>Telephone Number</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Email.svg" alt="icon">
                                    <h5>{{returnDefault(list.e_mail)}}<span>Defendant Email</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_ContactPerson.svg" alt="icon">
                                    <h5>{{list.aliases.length ? list.aliases.map(alias=>{return alias.alias}).toString() : "-----"}}<span>Alias</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Date.svg" alt="icon">
                                    <h5>{{returnDefault(list.first_contact)}}<span>First Contact Date</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Contact_VPN.svg" alt="icon">
                                    <h5>{{returnDefault(list.vpn_location)}}<span>VPN Location Used</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Method.svg" alt="icon">
                                    <h5>{{returnDefault(list.convo_method)}}<span>Conversation Methods</span></h5>
                                </div>
                            </div>
                            <div class="col-md-12 pr-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Script.svg" alt="icon">
                                    <h5>{{returnDefault(list.script)}}<span>Script</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr v-if="list.payment_accounts.length" />
                        <div class="row" v-if="list.payment_accounts.length">
                            <div class="col-md-12">
                                <h5>Payment Accounts</h5>
                            </div>
                            <div class="col-md-4" v-for="(account,index) in list.payment_accounts" :key="index">
                                <div class="a-modal-seller-info-item" :style="active_listing_info[0].seller_account_id == account.id? 'border:1px solid rgb(41, 171, 162)' : ''">
                                    <img src="/static/svg/Payment_Paypal.svg" alt="icon">
                                    <h5 :style="active_listing_info[0].seller_account_id == account.id? 'color:#29ABA2' : ''"><span class="mb-1">Payment Type: {{account.type || "Not Set" }}</span>Email/Name: {{account.name_email || "Not Set"}}<span>Account Number: {{account.number || "Not Set"}}</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Seller Location</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Location.svg" alt="icon">
                                    <h5>{{returnDefault(list.country_name)}}<span>Country</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Location.svg" alt="icon">
                                    <h5>{{returnDefault(list.region_name)}}<span>Region</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Address.svg" alt="icon">
                                    <h5>{{returnDefault(list.address)}}<span>Address</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/SellerInfo_Shipping.svg" alt="icon">
                                    <h5>{{list.ship_to_us == '1' ? "Will ship to USA" : "Will not ship to USA"}}<span>Shipping Infos</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Listing Price Details</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.listing_price)}}<span>Initial Price</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_Currency.svg" alt="icon">
                                    <h5>{{returnDefault(list.currency)}}<span>Price Currency</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Trademark_Brand.svg" alt="icon">
                                    <h5>{{list.brand_on_item == '1' ? "Yes" : "No"}}<span>Brand Name on Item Image</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.listing_price_max)}}<span>Maximum Price</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_Unit.svg" alt="icon">
                                    <h5>{{returnDefault(list.listing_price_unit)}}<span>Price Unit</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Trademark_Brand.svg" alt="icon">
                                    <h5>{{list.logo_on_item  == '1' ? "Yes" : "No"}}<span>Logo on Item Image</span></h5>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="row">
                            <div class="col-md-12">
                                <h5>Purchase Details</h5>
                            </div>
                            <div class="col-md-4">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Conversation_Purchase.svg" alt="icon">
                                    <h5>{{returnDefault(list.purchase_type)}}<span>Purchase Type</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Purchase_TransacNum.svg" alt="icon">
                                    <h5>{{returnDefault(list.transaction_number)}}<span>Transaction Number</span></h5>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.pymnt_platformname)}}<span>Payment Platform</span></h5>
                                </div>
                                <div class="a-modal-seller-info-item">
                                    <img src="/static/svg/Transaction_ItemPrice.svg" alt="icon">
                                    <h5>{{returnDefault(list.purchase_price)}}<span>Purchase Price</span></h5>
                                </div>
                            </div>
                        </div>
                        <!-- <hr v-show="Object.entries(list.evidences).length > 0" /> -->
                    </div> <!--End of Lower Modal-->
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <h5 class="mb-3">Checkout List</h5>
                            <div class="a-modal-check-info-item d-block">
                                <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                Shipping Address: Ships to NDIL (with accounts), and address is complete (street address, city, state, zip code present)?
                            </div>
                            <div class="a-modal-check-info-item d-block">
                                <img src="/static/svg/Checklist_Yes.svg" alt="icon">
                                Shipping Address: Ships to NDIL (no accounts), and address is complete (street address, city, state, zip code present)?
                            </div>
                            <div class="a-modal-check-info-item d-block">
                                <img src="/static/svg/Checklist_No.svg" alt="icon">
                                Shipping Address: Zip code matches city/state?
                            </div>
                            <div class="a-modal-check-info-item d-block">
                                <img src="/static/svg/Checklist_NA.svg" alt="icon">
                                Shipping Address: Only one clear shipping address present?
                            </div>
                        </div>
                    </div> -->
                </div>
                
                <span id="already-worked-span" class="d-block text-center">
                    <a 
                        v-if ="already_worked_lid != ''"
                        class  ="text-danger text-bold animated fadeIn"
                        :href  ="`verification/search?status=All&platform=0&lid=${ already_worked_lid }`"
                        target ="_blank"
                    >
                        <i class="fas fa-times-circle"></i> 
                        The seller of the listing (<u>{{already_worked_lid}}</u>) already has a working item in this campaign.
                        Click to check.
                    </a>
                </span>
                

            </template>
        </ar-modal>
        <!-- 
            Removed for implementation of More Filters from CM to VER GUI
            Started: Jan 10, 2023
         -->
        <!-- <ar-more-filters
            :header="['More Filters', 'Show Less']"
            @reset_filters="resetFilters"
            @apply_filters="applyFilters"
            :applied_filters_count="applied_filters_count"
            :has_apply="true"
            has_switch
        >
            <ar-select 
                label="Show number of items"
                class="mt-3"
                v-model="show_number_of_items"
                :options="options">
                Show 10 listings
            </ar-select>

            <ar-textfield
                v-if="$route.query.status == 'Filed'"
                label="Case Number"
                a_type="text"
                v-model.trim="filter_case_number"
                placeholder="Input keywords here"
            />

            <ar-textfield
                label="Researcher"
                a_type="text"
                v-model.trim="filter_researcher"
                placeholder="Input keywords here"
            />

            <ar-textfield
                label="QA"
                a_type="text"
                v-model.trim="filter_qa"
                placeholder="Input keywords here"
            />

            <ar-select
                label="Sort by"
                :options="sort_by_option"
                v-model="sort_by"
            >
                Listing ID
            </ar-select>

            <ar-select
                label="Order by"
                :options="order_by_option"
                v-model="order_by"
            >
                Descending
            </ar-select>

            <div class="row">
                <div class="col-md-6">
                    <ar-textfield
                        label="Date added (from)"
                        a_type="date"
                        a_class="remove-date-spinner"
                        a_style="padding-right: 3px;"
                        v-model="min_date_added"
                        :max="today"
                    />
                </div>
                <div class="col-md-6">
                    <ar-textfield
                        label="Date added (to)"
                        a_type="date"
                        a_class="remove-date-spinner"
                        a_style="padding-right: 3px;"
                        :disabled="min_date_added == ''"
                        v-model="max_date_added"
                        :min="min_date_added"
                        :max="today"
                    />
                </div>
            </div>

             <ar-textfield
                label        ="Date Captured"
                a_type       ="date"
                v-model.trim ="filterDateCaptured"
                :max         ="today"
            />

            <template v-if="has_date_qualified_filter">
                <div class="row">
                    <div class="col-md-6">
                        <ar-textfield
                            label="Date qualified (from)"
                            a_type="date"
                            a_class="remove-date-spinner"
                            a_style="padding-right: 3px;"
                            v-model="min_date_qualified"
                            :max="today"
                        />
                    </div>
                    <div class="col-md-6">
                        <ar-textfield
                            label="Date qualified (to)"
                            a_type="date"
                            a_class="remove-date-spinner"
                            a_style="padding-right: 3px;"
                            :disabled="min_date_qualified == ''"
                            v-model="max_date_qualified"
                            :min="min_date_qualified"
                            :max="today"
                        />
                    </div>
                </div>
            </template>

            <ar-textfield
                label="Item Location"
                a_type="text"
                v-model.trim="filter_item_location"
                placeholder="Input keywords here"
            />

            <ar-select 
                searchFieldId ="item_class"
                v-model       ="filter_item_class" 
                label         ="Item Class"
                placeholder   ="Search Item Class"
                type          ="search"
                :options      ="item_class"> Select Item Type
            </ar-select>

            <ar-select
                label="Seller Location"
                :options="seller_locations"
                v-model="filter_seller_location"
            >
                Select Seller Location
            </ar-select>

            <hr/>
            <label class="mt-2" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Payment Methods</label>
            <div v-for="method in payment_methods" :key="`payment-method--${method.value}`" class="pl-2">
                <div class="custom-control custom-checkbox mb-2">
                    <input class="custom-control-input" type="checkbox" name="method" :id="`payment-method--${method.value}`" v-model="selected_payment_methods" :value="method.label">
                    <label :for="`payment-method--${method.value}`" class="custom-control-label font-weight-normal">{{method.label}}</label>
                </div>
            </div>

            <hr/>
            <label class="mt-2" style="color: #383A3D; margin-left: 5px; font-weight: 500; font-size: 13px;">Infringement Indicators</label>
            <div class="btn-group btn-group-toggle d-block mb-3" data-toggle="buttons">
                <label class="btn btn-outline-secondary font-weight-normal w-50" :class="{'active': infringement_option == '1'}" style="font-size: 13px;" @click="updateInfringementOption('1')">
                    <input type="radio" name="infringement-options" id="infringment-with" value="1"> With
                </label>
                <label class="btn btn-outline-secondary font-weight-normal w-50" :class="{'active': infringement_option == '0'}" style="font-size: 13px;" @click="updateInfringementOption('0')">
                    <input type="radio" name="infringement-options" id="infringment-without" value="0"> Without
                </label>
            </div>
            <div v-for="reason in reasons" :key="`reason--${reason.value}`" class="pl-2">
                <div class="custom-control custom-checkbox mb-2">
                    <input class="custom-control-input" type="checkbox" name="reasons" :id="`reason--${reason.value}`" v-model="selected_reasons" :value="reason.value">
                    <label :for="`reason--${reason.value}`" class="custom-control-label font-weight-normal">{{reason.label}}</label>
                </div>
            </div>

            <template v-slot:switched>
                <h6 class="mt-2">Applied Filters</h6>
                
                <hr class="mt-0"/>
                <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Number of Listings to show</h6>
                <ar-chip>{{ page_size }} Listings</ar-chip>

                <template v-if="filter_case_number != '' && $route.query.status == 'Filed'">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Case Number</h6>
                    <ar-chip>{{ filter_case_number }}</ar-chip>
                </template>

                <template v-if="filter_researcher != ''">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Researcher</h6>
                    <ar-chip>{{ filter_researcher }}</ar-chip>
                </template>

                <template v-if="filter_qa != ''">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">QA</h6>
                    <ar-chip>{{ filter_qa }}</ar-chip>
                </template>

                <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Sorted by</h6>
                <ar-chip><span class="text-capitalize">{{ sort_by == "id" || sort_by == "0" ? "Listing ID" : sort_by.replace(/[^a-zA-Z0-9]/g, ' ') }}</span> in {{ order_by == '0' || order_by == 'desc' ? 'descending' : 'ascending' }}</ar-chip>

                <template v-if="min_date_added != ''">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Date Added</h6>
                    <ar-chip>{{ min_date_added == max_date_added ? formatDate(min_date_added) : `${formatDate(min_date_added)} - ${formatDate(max_date_added)}` }}</ar-chip>
                </template>

                <template v-if="has_date_qualified_filter && min_date_qualified != ''">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Date Qualified</h6>
                    <ar-chip>{{ min_date_qualified == max_date_qualified ? formatDate(min_date_qualified) : `${formatDate(min_date_qualified)} - ${formatDate(max_date_qualified)}` }}</ar-chip>
                </template>

                <template v-if="filter_item_location != ''">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Item Location</h6>
                    <ar-chip>{{ filter_item_location }}</ar-chip>
                </template>

                <template v-if="filter_item_class != 0">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Item Class</h6>
                    <ar-chip>{{ item_class.filter(item => filter_item_class == item.value)[0].label }}</ar-chip>
                </template>

                <template v-if="filter_seller_location != '0'">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Seller Location</h6>
                    <ar-chip>{{ seller_locations.filter(location => location.value == filter_seller_location)[0].label }}</ar-chip>
                </template>

                <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Payment methods</h6>
                <template v-if="selected_payment_methods.length == 0">
                    <ar-chip>All Payment methods</ar-chip>
                </template>
                <template v-else>
                    <ar-chip a_class="mr-1" v-for="method in selected_payment_methods" :key="method" @remove-chip="removeFilter('payment_methods', method)" close>
                        {{ method }}
                    </ar-chip>
                </template>

                <template v-if="infringement_option == '1' || selected_reasons.length > 0">
                    <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Infringement Indicators ({{ infringement_option == "1" ? "With" : "Without" }})</h6>
                    <template v-if="selected_reasons.length == 0">
                        <ar-chip>All Reasons</ar-chip>
                    </template>
                    <template v-else>
                        <ar-chip a_class="mr-1" v-for="reason in selected_reasons" :key="reason" @remove-chip="removeFilter('reasons', reason)" close>
                            {{ reasons.filter(r => r.value == reason)[0].label }}
                        </ar-chip>
                    </template>
                </template>

                <h6 class="mt-2 text-uppercase font-weight-normal text-secondary more-filters-section-header">Platforms</h6>
                <template v-if="$route.query.platform == '' || $route.query.platform == '0'">
                    <ar-chip>All Platforms</ar-chip>
                </template>
                <template v-else>
                    <ar-chip a_class="mr-1" v-for="platform in dem_platforms" :key="platform.label" @remove-chip="removeFilter('platforms', platform.value)" close>
                        {{ platform.name }}
                    </ar-chip>
                </template>
            </template>
        </ar-more-filters> -->

        <main class="main-con">
            <div class="card-con">
                <ar-list 
                    v-for          ="(list, index) in attached_lists" 
                    v-show         ="!show_error || (show_error && list.error)"
                    :key           ="index"
                    :product_url   ="list.product_url"
                    :product_img   ="list.product_img"
                    :seller_name   ="list.seller_name" 
                    :if_reassigned ="false"
                    :if_commission ="list.commission" 
                    :rid           ="list.rid"
                    :progress      ="list.progress" 
                    :status        ="list.status" 
                    :details       ="list.details"
                    :platform_img  ="list.platform_img"
                    :platform      ="list.platform"
                    :campaign_img  ="list.campaign_img" 
                    :campaign      ="list.campaign"
                    :seller_url    ="list.seller_url"
                    :date          ="list.date"
                    :last_rework   ="list.last_rework"
                    :claim_type    ="list.claim_type"
                    :payment_type  ="list.payment_type"
                    target         ="#listing-modal"
                    class          ="animated fadeIn fast"
                    :owner         ="list.owner"
                    :invalid       ="list.error"
                    :qualifier     ="list.qualifier"
                    :case_number   ="list.case_number"
                    @change        ="getSelectListing"
                    @click         ="getListingInfo(list.rid), clearReasons()"
                />
                <div v-if="attached_lists.length && loaded && !show_error">
                    <ar-pagination
                        :page_no="current_page"
                        :last_page="last_page"
                        @first-click="updatePage(1)"
                        @prev-click="updatePage(prev_page)"
                        @next-click="updatePage(next_page)"
                        @last-click="updatePage(last_page)"
                        @enter="updateListingPage"
                        @keypress.native="isNumber"
                    />
                </div>
                <div v-else-if="!attached_lists.length && loaded" class="empty-listing-con">
                    <img src="/static/svg/NoListings_Photo.svg" alt="No campaigns">
                </div>
            </div>
        </main>

        <ar-dialog
            id          = "duplicate-seller-modal"
            heading     = "Duplicate Seller Found"
            heading_style ="color: #DC3C40"
            sub_heading = "Failed to change status"
           :has_footer  = "false"
           :has_button  = "false"
        >
            <div v-for="duplicate in duplicate_seller_found" :key="duplicate.id" class="d-flex flex-column align-items-start text-left">
                <p class="text-muted text-bold">Listing ID: {{duplicate.id}}</p>
                <p class="text-muted text-bold d-inline-block text-truncate w-100" :title="duplicate.name">Seller: {{duplicate.name}}</p>
                <p class="text-muted text-bold">Status: {{$parent.getQFlag(duplicate.qflag_id)}}</p>

                <ar-button class="mt-2 align-self-end" data-dismiss="modal" outlined> Close </ar-button>
            </div>
        </ar-dialog>

        <ar-dialog
            id ="duplicate-listing-modal"
            heading ="Duplicate Listing Found"
            heading_style ="color: #DC3C40"
            sub_heading ="Listing(s) below will not be updated"
            :has_footer ="false"
            :has_button ="false"
            large
        >
            <div class="overflow-auto" style="max-height: 400px;">
                <div id="duplicate-listing-accordion" class="accordion">
                    <div v-for="(listing, index) in duplicate_listings" class="card mb-0" :key="listing.id">
                        <div
                            :id ="`heading-${listing.id}`"
                            class ="card-header collapsed"
                            data-toggle ="collapse"
                            :data-target ="`#collapse-${listing.id}`"
                            :aria-expanded ="!index"
                            :aria-controls ="`#collapse-${listing.id}`"
                        >
                            <h6 class="text-left mb-0">
                                {{ listing.id }}
                                <span class="ml-1 text-primary">( {{ listing.duplicates.length }} )</span>
                            </h6>
                        </div>
                        <div
                            :id ="`collapse-${listing.id}`"
                            class ="slow p-0 collapse"
                            :class ="{'show': !index}"
                            :aria-labelledby ="`heading-${listing.id}`"
                            data-parent ="#duplicate-listing-accordion"
                        >
                            <div class="card-body p-0">
                                <div class="row">
                                    <div v-for="duplicate in listing.duplicates" class="col-md-4" :key="duplicate.id">
                                        <div class="a-modal-seller-info-item">
                                            <h5>
                                                <span class="text-truncate text-center">{{ duplicate.name }}</span>
                                                <span class="text-primary text-center cursor-pointer" @click="searchListing(duplicate.id)">{{ duplicate.id }}</span>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <ar-button
                @click ="duplicateListingModalOnContinue"
                data-dismiss ="modal"
                class ="mt-1 float-right"
                outlined
            >
                Continue
            </ar-button> -->
            <ar-button ref="proceedAnyway" data-dismiss="modal" class="mt-3 float-right"
                @click="duplicateListingModalProceedAnyway" outlined>
                Continue
            </ar-button>
            <ar-button ref="onContinue" data-dismiss="modal" class="mt-3 mr-3 float-right"
                @click="duplicateListingModalOnContinue" outlined>
                Remove Duplicates & Continue
            </ar-button>
        </ar-dialog>
        <ar-dialog
            id            ="whitelist-info-modal"
            heading       ="Whitelist Seller"
            heading_style ="color: #DC3C40"
            sub_heading   ="You are trying to change status a whitelisted seller."
            :has_footer   ="false"
            :has_button   ="false"
        >
            <div class="d-flex flex-column align-items-start text-left">
                <p class="text-muted text-bold">Name: <span>{{detected_whitelist.name}}</span></p>
                <p class="text-muted text-bold d-inline-block text-truncate w-100" :title="detected_whitelist.url">URL: <span>{{detected_whitelist.url}}</span></p>
                <p class="text-muted text-bold">Site ID: <span>{{detected_whitelist.site_id}}</span></p>
                <p class="text-muted text-bold">Platform: <span>{{detected_whitelist.platforms}}</span></p>
                <p class="text-muted text-bold">Campaign: <span>{{detected_whitelist.campaigns}}</span></p>
                <ar-button
                    class="mt-2 align-self-end"
                    data-dismiss="modal"
                    outlined
                >
                    Close
                </ar-button>
            </div>
        </ar-dialog>
        <ar-dialog
            id              = "lfc-invalid-notice"
            heading         = "Notice"
            heading_style   = "color: #DC3C40"
            sub_heading     = "Mix of both LFC and Non-LFC is strictly prohibited."
            :has_footer     = "false"
            :has_button     = "false"
        >
            <div class="d-flex flex-column align-items-start text-center">
                <div style="display: flex; width: 100%; justify-content: space-evenly;
                    overflow-y: auto; min-height: fit-content; max-height: 200px;"
                >
                    <div style="display: flex; flex-direction: column; row-gap: 10px;">
                        <span style="font: normal normal bold 14px/18px Roboto;">
                            LFC(s)
                        </span>
                        <span
                            v-for="(item, index) in LFCDetected" :key="index"
                            style="display: flex;; flex-direction: column; row-gap: 5px"
                        >
                            <span>
                                {{ item }}
                            </span>
                        </span>
                    </div>
                    <div style="display: flex; flex-direction: column; row-gap: 10px; margin-left: 20px;">
                        <span style="font: normal normal bold 14px/18px Roboto;">
                            Non - LFC(s)
                        </span>
                        <span
                            v-for="(item, index) in nonLFCDetected" :key="index"
                            style="display: flex;; flex-direction: column; row-gap: 5px"
                        >
                            <span>
                                {{ item }}
                            </span>
                        </span>
                    </div>
                </div>
                <ar-button
                    class="mt-2 align-self-end"
                    data-dismiss="modal"
                    outlined
                >
                    Close
                </ar-button>
            </div>
        </ar-dialog>
        <ar-dialog
            id              = "lfc-multiple-invalid-notice"
            heading         = "Multiple LFC Invalidation"
            sub_heading     = "Only one reason should be selected when invalidating LFC(s)"
            heading_style   = "color: #E5AD93"
            :has_footer     = "false"
            :has_button     = "false"
        >
        </ar-dialog>
        <ar-dialog
            id               = "lfc-confirm-invalidation"
            heading          = "LFC Invalidation"
            sub_heading      = "Please confirm invalidation of listing(s)"
            heading_style    = "color: #E5AD93"
            :has_footer      = "false"
            :has_button      = "false"
            data-backdrop    = "static"
            :custom_width    = "'fit-content'"
            :custom_maxwidth = "'30%'"
        >
            <div
                v-show="this.selected_listings.length && hasLFC == 1"
                class="lfc-invalid-note2"
            >
                <span style="display: flex; align-items: center">
                    <i class="fas fa-info-circle d-block text-md mr-2" />
                    <span class="lfc-invalid-note-header">
                        Please note that your selected reason will be seen directly by the client handling this campaign. 
                        Make sure to only select the most appropriate reason for invalidation.
                    </span>
                </span>
            </div>
            <div class="d-flex align-items-start">
                <div style="font: normal normal bold 16px/18px Roboto; width: 40%"> Selected Reason:</div>
                <div style="display: flex; flex-direction: column; row-gap: 5px; width: 90%">
                    <div v-show="selected_other_reason.length" style="display: flex; justify-content: flex-start; width: 310px;">
                        <span :title="`Others: ${other_reason}`"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                            <b>Others:&nbsp;</b>{{ other_reason }} 
                        </span>
                    </div>
                    <div v-show="selected_predefined_reasons || selected_predefined_sub_reasons" 
                         style="display: flex; justify-content: flex-start; width: 100%; column-gap: 30px; text-align: left">
                        <span v-for="(reason, index) in selected_predefined_reasons" :key="index">
                            <b>{{ reason.text }}</b>
                        </span>
                        <span v-for="(sub, index) in selected_predefined_sub_reasons" :key="index">
                            <b>{{ `${filtered_reasons_with_sub[0].name}: ` }}</b> {{ sub.text }}
                        </span>
                    </div>
                </div>
            </div>
            <div style="display:flex; justify-content: flex-end; column-gap: 10px;">
                <ar-button
                            class="mt-2 align-self-end"
                            data-dismiss="modal"
                            @click="is_loading = false"
                            outlined
                >
                    Cancel
                </ar-button>
                <ar-button
                    class="mt-2 align-self-end"
                    @click="confirmedLFCNote = true; confirmCheckLFC()"
                    data-dismiss="modal"
                >
                    Confirm
                </ar-button>
            </div>
        </ar-dialog>
        <potential-whitelists
            :whitelists ="$store.state.potentialWhitelists"
            @click      ="acknowledgeWhitelists"
            @close      ="onModalClose"
        />
        <portal>
            <CongratulatoryModal />
        </portal>
    </div>
</template>

<script>
    const lazyload = view => () => import(`@/components/layouts/${view}`)
    import axios from '@/axiosMime'
    import { mapActions, mapState, mapGetters } from 'vuex'
    import moment from 'moment'
    import MultiSelect from 'vue-multiselect';
    import chip from '../../layouts/Chip';
    import qs from 'qs';
    import { ListingStatus, Role, RoleWithKeys } from '../../../utils/constants';
    import { isEmpty, getQFlagId } from '../../../utils/common';
    import { send as globalSend } from '../../../events';

    /** Pinia Store */
    import { useFeatureAccessStore } from '../../../store/pinia/feature-access';
    import { useReasonSelectionStore } from '../../../store/pinia/reasons-selection';
    import { useSubStatusesStore } from '../../../store/pinia/substatuses';
    import { mapWritableState } from 'pinia';
    /** Pinia Store */

    export default {
        name: 'ar-listing',
        components: {
            'ar-titlebar'  : lazyload('TitleBar'),
            'ar-textfield' : lazyload('Textfield'),
            'ar-button'    : lazyload('Button'),
            'ar-list'      : lazyload('List'),
            'ar-select'    : lazyload('Select'),
            'ar-dialog'    : lazyload('Dialog'),
            'ar-modal'     : lazyload('Modal'),
            'ar-toggle-btn': lazyload('ButtonToggle'),
            // 'ar-action-bar': actionbar,
            'multiselect': MultiSelect,
            'ar-pagination'    : () => import('@/components/layouts/Pagination'),
            'ar-chip': chip,
            // ArMoreFilters      : () => import('@/components/layouts/MoreFilters'),
            // ArChip             : () => import('@/components/layouts/Chip'),
            ArReasonSelection  : () => import('../verification/listing/ReasonsSelection'),
            ArBatchModal       : () => import('./listing/BatchChangeStatusModal'),
            PotentialWhitelists: () => import('@/components/layouts/modals/PotentialWhitelists.vue'),
            // ArReworkTimeline   : () => import('./listing/ReworkTimeline'),
            CongratulatoryModal: () => import("@/components/layouts/modals/CongratulatoryModal"),
            ArLoaderinator: () => import("@/components/layouts/loading/Loaderinator"),
            ArReassignmentUnsuccessfulModal: () => import("@/components/layouts/modals/ReassignmentUnsuccessfulModal"),
        },
        props: {
            p_campaigns:{
                type:Object,
                default:() => ({})
            }
        },
        data() {
            return {
                reassign__unsuccessful: {
                    hasActive: [],
                    notAssignedToCampaign: [],
                    duplicatesFound: [],
                    others: []
                },
                remove_listing: {
                    comment:'',
                    loading: false
                },
                // list for id in existing listings
                hasExisting_listings: false,
                existing_listings_id: [],
                isLoading_existing_listings: true,
                existing_and_duplicate_reasons: [
                    'Seller already have an active listing on the requested campaign.',
                    'The seller of the listing already has a working item in this campaign. You cannot proceed.'
                ],
                reassignmentFailed_meta_data: [],
                individualReassign_hasError: false,
                // Additional Filters / More Filters
                filtered_listing_id: [],
                filtered_listing_id_input: null,
                expanded: false,
                search_keywords   : this.$route.query.keywords != null ? this.$route.query.keywords : "",
                searched_lid      : this.$route.params.lid ? this.$route.params.lid : "",
                campaigns         : this.p_campaigns,
                filtered_platforms: [],
                filtered_status   : "",
                selected          : false,
                can_status        : false,
                change_status     : false,
                qa                : false,
                sidebar_campaigns : [],
                statuses: '',
                platforms: ['11Street', 'Alibaba', 'Amazon', 'DHGate', 'Wish', 'Ebay', 'Qoo10'],
                lists: [],
                lists_meta: {},
                lists_links: {},
                status_change_options: [
                    {
                        heading: "Mark as Invalid",
                        details: "Not conforming to valid specifications",
                        img: "/static/svg/Icon_Invalid.svg",
                        id: "invalid"
                    },
                {
                    heading: "Mark as Has Potential",
                    details: "Possibility for qualification",
                    img: "/static/svg/Icon_HasPotential.svg",
                    id: "haspo"
                },
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454         
                 */
                // {
                //     heading: "Mark as Reworked",
                //     details: "Need some fixes",
                //     img: "/static/svg/Icon_ForRework.svg",
                //     id: "reworked"
                // },
                // {
                //     heading: "Mark as For Rework",
                //     details: "Fixes have been implemented",
                //     img: "/static/svg/Icon_Reworked.svg",
                //     id: "for_rework"
                // },
                {
                    heading: "Mark as Qualified",
                    details: "Suitable for a law case",
                    img: "/static/svg/Icon_Qualified.svg",
                    id: "qualified"
                },
                {
                    heading: "For Commission",
                    details: "Variable-pay for reward",
                    img: "/static/svg/Icon_Commission.svg",
                    id: "commission"
                },
                {
                    heading: "Remove Commission",
                    details: "Detach reward",
                    img: "/static/svg/Icon_NoCommission.svg",
                    id: "remove-commission"
                }],
                options: [
                    {
                        label: "Show 25 listings",
                        value: 1
                    },
                {
                    label: "Show 50 listings",
                    value: 2
                },
                {
                    label: "Show 100 listings",
                    value: 3
                },
                {
                    label: "Show 150 listings",
                    value: 4
                },
                {
                    label: "Show 500 listings",
                    value: 5
                },
                {
                    label: "Show 1000 listings",
                    value: 6
                }],
                campaign: {
                    img: '/static/svg/Campaign Default Photo.svg',
                    name: ""
                },
                searched_option: 0,
                search_options: [
                    {
                        label: "Search by Seller Name",
                        value: 1
                    },
                    {
                        label: "Search by Product URL",
                        value: 2
                    },
                    {
                        label: "Search by Store URL",
                        value: 3
                }],
                listing_info       : [],
                loaded             : false,
                page_size          : 10,
                current_page       : 1,
                status             : [1,5].includes(JSON.parse(localStorage.getItem("ver")).access_level) ? 4 : 1,
                selected_searched_by : 0,
                order_by           : "desc",
                order_by_option    : [{
                    label: "Ascending",
                    value: "asc"
                }],
                sort_by            : "id",
                sort_by_option    : [
                    {
                    label: "Researcher",
                    value: "researcher"
                },
                {
                    label: "QA",
                    value: "qa"
                },
                // {
                //     label: "Payment Method",
                //     value: "payment_method"
                // },
                {
                    label: "Date Added",
                    value: "created_at"
                },
                {
                    label: "Date Qualified",
                    value: "qualified_at"
                },
                // {
                //     label: "Infringement Reasons",
                //     value: "reasons"
                // }
                ],
                active_listing_id  : "",
                lid_to_change      : {},
                selected_listings  : [],
                campaign_users     : [],
                selected_campaign_user: 0,
                batch_comment       : "",
                select_all_text     : "Select",
                is_select_all       : true,
                modal_comment       : "",
                campaigns_select    : [],
                reassign_campaign   : 0,
                show_number_of_items: 0,
                qflag               : 0,
                selected_change_status : 0,
                selected_comm : '',
                researchers_campaigns_select: [],
                researchers: [],
                show_required_list:false,
                commission:0,
                filters: {
                    status: this.$route.query.status,
                    platform: this.$route.query.platform,
                    keywords: this.$route.query.keywords != null ? this.$route.query.keywords : "",
                    campaign_id: this.$route.params.campaign_id
                },
                searching                      : false,
                min_date_added                 : "",
                max_date_added                 : "",
                min_date_qualified             : "",
                max_date_qualified             : "",
                payment_methods                : [],
                selected_payment_methods       : [],
                reasons                        : [],
                selected_reasons               : [],
                infringement_option            : "1",
                filter_item_location           : "",
                filter_seller_location         : "0",
                seller_locations               : [],
                modal_campaign                 : "",
                lid_status                     : "",
                filter_case_number             : "",
                filter_researcher              : "",
                filter_qa                      : "",
                filter_seller_url              : "",
                filter_product_url             : "",
                filter_claimtype               : {
                    options: [],
                    selected: 0
                },
                filter_shipping_location       : {
                    options: [],
                    selected: 0
                },
                filter_roles                   : {
                    options: [],
                    selected: []
                },
                filter_employee                : {
                    options: [],
                    selected: 0
                },
                filter_brand_name_on_image     : 1,
                filter_logo_on_image           : 1,
                filter_will_ship_to_us         : 1,
                enable_options                 : [],
                filter_with_claimtype          : 1,
                filter_with_shipping_location  : 1,
                filter_with_item_type          : 1,
                filter_with_seller_country     : 1,
                filter_with_item_class         : 1,
                filter_item_type_options       : [],
                filter_commission              : 1,
                filter_purchase_type           : 0,
                filter_with_status_change_reason: 1,
                filter_status_change_reason    : {
                    options: [],
                    selected: []
                },
                filters__: {
                    item_location: {
                        options: [],
                        selected: 0,
                        search: ''
                    }
                },
                modal_evidences                : {},
                predefined_reasons             : [],
                show_predefined_reasons        : false,
                clicked_statuses               : [],
                predefined_status              : '',
                selected_predefined_reasons    : [],
                selected_predefined_sub_reasons: [],
                other_reason                   : "",
                selected_other_reason          : [],
                fetching_reasons               : false,
                reassignment_mode              : 'Researcher',
                post_qa_status                 : ['Submitted', 'Accepted', 'Rejected', 'Monitoring', 'Filed'],
                prev_route                     : {},
                uploaded_evidences             : [],
                is_uploaded_evidences_ready    : false,
                already_worked_lid             : '',
                is_loading                     : false,
                item_class                     : [],
                filter_item_class              : 0,
                filter_item_type               : [],
                applied_filters_count          : 0 ,
                detected_whitelist             : {},
                duplicate_seller_found         : {},
                duplicate_listings             : [],
                all_predefined_reasons         : {},
                batch_change_status            : 2,
                duplicateListingModalOnContinue: () => ({}),
                duplicateListingModalProceedAnyway: () => ({}),
                acknowledgeWhitelists          : () => ({}),
                isCheckingPotentials           : false,
                hasAcknowledged                : false,
                onModalClose                   : () => ({}),
                reworkTimeline                 : false,
                listingNote                    : {
                    data      : [],
                    status    : false,
                    value     : "",
                    is_loading: false
                },
                reworkCommission  : null,
                reworkLevel       : null,
                forCommission: null,
                hasCrucial        : false,
                filterDateCaptured: '',
                reassignStatuses:  {
                    loading: false,
                    results: [],
                    selected: 0,
                    data: [{
                        label: "Work In Progress",
                        value: 4,
                    }, 
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // {
                    //     label: "For Rework",
                    //     value: 2,
                    // },
                    {
                        label: "Invalid",
                        value: 99,
                    },
                    {
                        label: "Researched",
                        value: 1,
                    }]
                },
                individualReassign: {
                    show: false,
                    status: {
                        selected: 0,
                        data: []
                    },
                    campaign: {
                        selected: 0,
                        data: []
                    },
                    user: {
                        selected: 0,
                        data: []
                    },
                    user_type: {
                        selected: 0,
                        // data: []
                    }
                },
                batchReassign: {
                    user_type: {
                        selected: 0,
                    }
                },
                isOpen_batchReassign: false,
                lastEditedListing: [],
                // has_clientListings: false,
                getDataFor_AdditionalFilters: false,
                lfcStatus                   : null,
                currentAssignmentCopy       : [],
                isHistoryReady              : false,
                reasonsWithRestrictedRoles  : {},
                confirmedLFCNote: false,
                checkIndividualLFC: false,
                is_reason_many: false,
                nonLFCDetected: [],
                LFCDetected: [],
            }
        },
        computed: {
            ...mapWritableState(useReasonSelectionStore, ['showReasonWithRestriction', 'hasSeenReasonWithRestriction', 'hasSelectedReasonWithRestriction', 'isRestrictionLoading']),
            ...mapState([/*"active_listing_info",*/"is_active_list_ready", "shown_data", "show_error", "previous_route", 
                        "keywords", "Listing_has_clientListings",'hasTriggeredSomething', 'client_Listings', 'currentAssignedCampaign',
                        'showResearchedTracker', 'researchTrackerLoad', 'relatedCampaignBypass']),
            ...mapGetters(['isHolidayTheme', 'themeListingPreview']),
            transferToAnotherSubStatusOptions() {
                const current_status = this.$route.query.status
                
                if (current_status == 'Hacked')
                    return this.autoQualifiedSubStatuses
                
                if (current_status == 'Has Potential')
                    return this.hasPotentialSubStatuses
                
                if (current_status == 'Qualified')
                    return this.qualifiedSubStatuses

                if (current_status == 'Accepted')
                    return this.acceptedSubStatuses

                if (current_status == 'Archived')
                    return this.archivedSubStatuses

                if (current_status == 'Work in Progress')
                    return this.wipSubStatuses

                return []
            },
            reassignmentSubStatusOptions() {
                const selectedReassignmentStatus = this.individualReassign.status.selected
                if (selectedReassignmentStatus == 1)
                    return this.researchedSubStatuses

                if (selectedReassignmentStatus == 3)
                    return this.qualifiedSubStatuses

                if (selectedReassignmentStatus == 98)
                    return this.hasPotentialSubStatuses

                if (selectedReassignmentStatus == 66)
                    return this.archivedSubStatuses

                return []
            },
            reassignmentBulkSubStatusOptions() {
                const selectedReassignmentStatus = this.reassignStatuses.selected
                if (selectedReassignmentStatus == 1)
                    return this.researchedSubStatuses

                if (selectedReassignmentStatus == 3)
                    return this.qualifiedSubStatuses

                if (selectedReassignmentStatus == 98)
                    return this.hasPotentialSubStatuses

                if (selectedReassignmentStatus == 66)
                    return this.archivedSubStatuses

                return []
            },
            autoQualifiedSubStatuses() {
                const sub = this.subStatuses?.["Hacked"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            hasPotentialSubStatuses() {
                const sub = this.subStatuses?.["Has Potential"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            qualifiedSubStatuses() {
                const sub = this.subStatuses?.["Qualified"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            acceptedSubStatuses() {
                const sub = this.subStatuses?.["Accepted"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            archivedSubStatuses() {
                const sub = this.subStatuses?.["archived"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            wipSubStatuses() {
                const sub = this.subStatuses?.["Work in Progress"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            researchedSubStatuses() {
                const sub = this.subStatuses?.["Researched"] ?? [];
                return sub.length === 1 ? sub.filter(s => s.id) : sub.map(s => ({ ...s, id: s.id ?? -1}));
            },
            subStatusesOnlyOptions() {
                const subs = this.subStatuses?.[this.active_li.status] ?? []
                return subs?.filter(sub => sub.id)?.length ? subs?.filter(sub => sub.id != this.active_li.substatus_id) ?? [] : []
            },
            subStatusesStore() {
                return useSubStatusesStore()
            },
            subStatuses() {
                const userObjectCondition = !this.shown_data.includes("RD") && (this.access_level == 5 || JSON.parse(localStorage.getItem("ver")).role == 2)
                let objUserSubStatusKey = userObjectCondition ? 'total_item_by_substatus_and_team' : 'total_item_by_substatus_and_user'

                return this.subStatusesStore.subStatuses?.[[2,7].includes(this.access_level) ? 'total_item_by_substatus' : objUserSubStatusKey] ?? {}
            },
            isRemoveListingRestricted() {
                return this.isFeatureRestricted('v-status-change-remove-listing')
            },
            canRemoveListing() {
                const reasons = {
                    "main_reasons"   :this.selected_predefined_reasons,
                    "sub_reasons"    :this.selected_predefined_sub_reasons,
                }

                const otherReasons = {
                    "other_reason_id":this.selected_other_reason,
                    "other_reason"   :this.other_reason,
                }

                if (otherReasons.other_reason_id.length)
                    return otherReasons.other_reason.trim() !== ''

                return Object.values(reasons).some((v) => v.length > 0)
            },
            isAllowedQAReassignment() {
                return [439,271,221].includes(this.user.id)
            },
            showReworkTimeline() {
                return [2 ,3, 98].includes(+this.selected_change_status)
            },
            isReassignWithReason() {
                /* return (this.individualReassign.status.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.individualReassign.status.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.individualReassign.status.selected) && this.access_level != 1))) 
                    || (this.reassignStatuses.selected && ([2,8,3,98].includes(this.currentStatus) || !([4].includes(this.reassignStatuses.selected) && [1, 99].includes(this.currentStatus)) || ([4].includes(this.reassignStatuses.selected) && this.access_level != 1))) */

                // return !this.isFeatureRestricted('v-modal-reasons-selection');
                return !this.isFeatureRestricted('v-reassignment-reasons-selection');
            },
            isReassignWIP() {
                return (this.individualReassign.status.selected && [4].includes(this.individualReassign.status.selected) && [2,8,3,98].includes(this.currentStatus)) 
                    || (this.reassignStatuses.selected && [4].includes(this.reassignStatuses.selected) && [2,8,3,98].includes(this.currentStatus))
            },
            currentStatus() {
                return this.$parent.getQFlagId(this.$route.query.status)
            },
            canReassignWithCampaign() {
                return this.isCLAccount || this.isSuperAdmin
            },
            canReassignWithStatus() {
                return ![1, 2].includes(this.access_level)
            },
            batchModalProps() {
                const status = this.batch_change_status
                let other_res_length = this.selected_other_reason.length
                let main_res_length = this.selected_predefined_reasons.length
                let sub_res_length = this.selected_predefined_sub_reasons.length
                let sum_res_length = main_res_length + sub_res_length + other_res_length
                
                if(sum_res_length > 1 && (this.checkIndividualLFC == true || this.LFCDetected.length > 0) && 
                    this.hasLFC == 1 && this.batch_change_status == 99 && other_res_length == 0) {
                        console.log('trigger here')
                    this.triggerMultipleLFCInvalidNotice()
                }
                if(sum_res_length > 2 && (this.checkIndividualLFC == true || this.LFCDetected.length > 0) && 
                    this.hasLFC == 1 && this.batch_change_status == 99 && other_res_length > 0) {
                        console.log('trigger here with other')
                    this.triggerMultipleLFCInvalidNotice()
                }

                let props = {
                    id               : 'change-status-modal',
                    sub_heading      : 'A selection of predefined reasons are available below. Confirm by clicking on the \'Confirm\' button.',
                    reasons          : (this.batch_change_status == 99 || this.selected_change_status == 99) ? this.filtered_predefined_reasons : this.predefined_reasons,
                    is_loading       : this.is_loading,
                    commissionable   : false,
                    custom_minwidth  : '1100px',
                    status
                }
                switch (status) {
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // case 2:
                    //     props.heading       = 'Mark as For Rework'
                    //     props.heading_style = 'color: #2D599E;'
                    // break
                    case 99:
                        props.heading       = 'Mark as Invalid'
                        props.heading_style = 'color: #DC3C40;'
                    break
                    case 98:
                        props.heading        = 'Mark as Has Potential'
                        props.heading_style  = 'color: #79BD9A;'
                        props.commissionable = true
                    break
                    case 3:
                        props.heading        = 'Mark as Qualified'
                        props.heading_style  = 'color: #29ABA2;'
                        props.commissionable = true
                    break
                    case 32:
                        props.heading        = 'Mark as Accepted'
                        props.heading_style  = 'color: #9ACD32'
                        props.commissionable = true
                    break
                    case 30:
                        props.heading       = 'Mark as Auto Qualified'
                        props.sub_heading   = 'Moving selected listings to Auto Qualified. Please confirm to proceed.'
                        props.heading_style = 'color: #29ABA2;'
                    break
                    case 4:
                        props.heading       = 'Mark as Work In Progress'
                        props.sub_heading   = 'Moving selected listings to Work In Progress. Please confirm to proceed.'
                        props.heading_style = 'color: #29ABA2;'
                    break
                    case 66:
                        props.heading       = 'Mark as Archived'
                        props.sub_heading   = 'Moving selected listings to Archived. Please confirm to proceed.'
                        props.heading_style = 'color: #29ABA2;'
                    break
                    default:
                        props = { ...props }
                    break
                }
                return props
            },
            attached_lists() {
                const listings = JSON.parse(JSON.stringify(this.lists));

                const RESEARCHED = 2;

                return listings.filter(listing => listing.qflag_id !== RESEARCHED)
                    .map(listing => ({ ...listing, platform: listing.platform ? listing.platform : '' }));
            },
            next_page() {
                var page = this.current_page
                if (this.current_page < this.last_page) page = +page + 1
                return page
            },
            prev_page() {
                var page = this.current_page
                if (this.current_page > 1) page = +page - 1
                return page
            },
            last_page() {
                return this.lists_meta.no_of_pages
            },
            listing_from_count() {
                return (this.current_page > 1) ? ((this.current_page * this.page_size) - this.page_size) + 1 : this.lists.length > 0 ? 1 : 0
            },
            listing_to_count() {
                return ((this.current_page * this.page_size) - this.page_size + this.lists_meta.items_in_this_page)
            },
            host(){
                return process.env.VUE_APP_URL
            },
            access_level(){
                return JSON.parse(localStorage.getItem('ver')).access_level
            },
            editListingRestriction() {
                const { status } = this.$route.query;

                const EditListingRestriction = {
                    'Work in Progress': 'v-link-edit-listing-wip',
                    'Has Potential'   : 'v-link-edit-listing-has-potential',
                    'Auto Qualified'  : 'v-link-edit-listing-auto-qualified',
                    Hacked            : 'v-link-edit-listing-auto-qualified',
                    Researched        : 'v-link-edit-listing-researched',
                    Qualified         : 'v-link-edit-listing-qualified',
                    Invalid           : 'v-link-edit-listing-invalid',
                    Monitoring        : 'v-link-edit-listing-monitoring',
                    Rejected          : 'v-link-edit-listing-rejected',
                    Accepted          : 'v-link-edit-listing-accepted',
                    Submitted         : 'v-link-edit-listing-submitted',
                    Filed             : 'v-link-edit-listing-filed',
                    Archived          : 'v-link-edit-listing-archived'
                };

                const feature = EditListingRestriction[status];

                const isRestrictedToAllStatus = this.$featureAccess.restrictions().includes('v-link-edit-listing');

                if (isRestrictedToAllStatus) {
                    return 'v-link-edit-listing';
                }

                return feature;
            },
            canEdit() {
                if (!this.isListingInfoReady) {
                    return false;
                }

                return !this.$featureAccess.isRestricted(this.editListingRestriction);
            },
            canEditOld() {
                let active_listing = this.active_listing_info[0]
                let canYou   = false
                if (this.$store.state.is_active_list_ready) {
                    if ([1,5,6].includes(this.access_level)) { // true if user is Verifier, CL's, or Client
                        // canYou = ([4,2].includes(this.active_listing_info[0]?.qflag_id || 0) && this.active_listing_info[0]?.user_id == JSON.parse(localStorage.getItem('ver')).id)
                            // ? true // true if the qflag is 4 or 2 and if the user owns the listing
                            // : ([31, 32, 33].includes(this.active_listing_info[0]?.qflag_id || 0) && this.access_level == 6)

                        canYou = (this.access_level == 1 && ![1,3,30,31,32,33,34,35,36,37,98,99].includes(this.active_listing_info[0]?.qflag_id || 0) && this.active_listing_info[0]?.user_id == JSON.parse(localStorage.getItem('ver')).id) ||
                            (this.access_level == 5 && [1,2,4].includes(this.active_listing_info[0]?.qflag_id || 0)) ||
                            (this.access_level == 6 && [31, 32, 33].includes(this.active_listing_info[0]?.qflag_id || 0))
                            
                    } else if ([2,7].includes(this.access_level)) { // true if user is QA or an ALLGUI (all mighty user)
                        // canYou = ([8,1,3,98].includes(this.active_listing_info[0].qflag_id))
                        //     ? true // true if the qflag is 8,1,3,98 event if his not the owner
                        //     : ([4,2].includes(this.active_listing_info[0].qflag_id) && this.active_listing_info[0].user_id == JSON.parse(localStorage.getItem('ver')).id && this.access_level == 7) // true if the qflag is 4 or 2 and if the user owns the listing
                        //         ? true
                        //         : false
                        // canYou = ( active_listing["qa_user_id"] == this.user["id"] || this.access_level == 7 )
                        // canYou = true // Commented by Marvin 10/05/2021
                        // canYou = (active_listing?.qflag_id != 34) // true if status is not Filed

                        // true if status is not Filed and user is superadmin
                        canYou = (this.access_level == 7 && active_listing?.qflag_id != 34) || 
                            // true if status is not "Work in Progress", "For Rework", "Auto Qualified", "Monitoring", "Accepted", "Rejected", "Submitted", "Filed", "For Internal Review" and user is QA
                            (this.access_level == 2 && ![4,31,32,33,34,35,36].includes(active_listing?.qflag_id)) 
                            // (this.access_level == 2 && active_listing?.qflag_id == 8) // true if status is reworked and user is QA
                    }
                }
                return canYou
            },
            checkAuthorization() {
                const current = this.active_listing_info[0]
                return (this.access_level == 1 && [4].includes(current?.qflag_id) && current?.user_id == JSON.parse(localStorage.getItem('ver')).id) ||
                    (this.access_level == 2 && [1,3,4, 66].includes(current?.qflag_id)) || // Allow QA to reassign if current status/tab is "Researched", "Qualified", "Work in Progress"
                     /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // (this.access_level == 5 && [2,4,1,8,10,98,99].includes(current?.qflag_id)) || // Old condition
                    (this.access_level == 5 && [1,4,99, 66/*,8 Hide Reworked*/].includes(current?.qflag_id)) || // New Condition - Allow to reassign CLs if current status/tab is "Researched" or "Reworked" = Marvin 08/04/2022
                    // (this.access_level == 7 && [4,2,1,8,3,10,98,99].includes(current?.qflag_id))  // Old condition
                    // (this.access_level == 7 && [1/*,2 Hide For Rework*/,3,4/*,8 Hide Reworked*/,98,99].includes(current?.qflag_id))  //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
                    (this.access_level == 7 && [1/*,2 Hide For Rework*/,3/*,8 Hide Reworked*/,98, 66].includes(current?.qflag_id))  //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
            },
            checkSelectedAuth() {
                if (['Filed', 'Rejected', 'Accepted', 'Submitted', 'Filed', 'Monitoring'].includes(this.$route.query.status)) // auto unauthorize if status is 'Filed'
                    return false
                let authorize = true
                var listings = this.lists.filter(list => this.selected_listings.includes(list.rid))
                for (var j = 0; j < listings.length; j++) {
                    if (
                        (this.access_level == 1 && (![4].includes(listings[j].qflag_id) || listings[j].user_id != JSON.parse(localStorage.getItem('ver')).id)) ||
                        // (this.access_level == 2 && (![2].includes(listings[j].qflag_id))) ||
                        (this.access_level == 2 && (![1,3,4, 66].includes(listings[j].qflag_id))) || // Allow QA to reassign if current status/tab is "Researched", "Qualified", "Work in Progress"

                        /**
                         * Deliverable: Hide all For Rework/Reworked elements across all GUI
                         * Deliverable Number: 454
                         */

                        // (this.access_level == 5 && (![2,4,1,3,8,98,99].includes(listings[j].qflag_id))) || // Old condition
                        (this.access_level == 5 && (![1,4,99, 66/*,8 Hide Reworked*/].includes(listings[j].qflag_id))) || // New Condition - Allow to reassign CLs if current status/tab is "Researched" or "Reworked" = Marvin 08/04/2022
                        // (this.access_level == 7 && (![4,2,1,8,3,98,99].includes(listings[j].qflag_id))) // Old condition
                        // (this.access_level == 7 && (![1/*,2 Hide For Rework*/,3,4/*,8 Hide Reworked*/,98,99].includes(listings[j].qflag_id))) || //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
                        (this.access_level == 7 && (![1/*,2 Hide For Rework*/,3/*,8 Hide Reworked*/,98, 99, 66].includes(listings[j].qflag_id))) || //  New Condition - Allow to reassign CLs if current status/tab is "Work in Progress", "Recheck", "Reworked", "Researched", "Qualified", "Has Potential", "Invalid" = Marvin 08/04/2022
                        (this.access_level == 6)
                    ) {
                        authorize = false
                        break
                    }
                }
                // commented Marvin 08/18/2022 
                // else if([221, 439].includes(this.user.id)) {
                //     authorize = ([2,8].includes(listings[j].qflag_id))
                //     break
                // }
                return authorize
            },
            canChangeStatusOld() {
                let canYou = false
                if (this.$store.state.is_active_list_ready) {
                    if ([1].includes(this.access_level)) {
                        let qflag_id = this.active_listing_info[0]?.qflag_id || 0
                        canYou = [4, 2, 41].includes(+qflag_id)
                    } else if ([2,5,7].includes(this.access_level)) {
                        let qflag_id = this.active_listing_info[0]?.qflag_id || 0
                        // console.log("qa_user_id: "+this.active_listing_info[0]["qa_user_id"])
                        // console.log("id: "+this.user["id"])
                        if (this.access_level == 5)
                            canYou = qflag_id == 41 || ((this.active_listing_info[0]?.user_id == this.user["id"] || this.active_listing_info[0]?.qa_user_id == this.user["id"]) && (qflag_id == 4 || qflag_id == 2))
                        else if(this.access_level == 2){
                            if(qflag_id == 1){//If status is researched show status change
                                canYou = true
                            }else{
                                if(this.active_listing_info[0]?.qa_user_id == 0)//If no QA owner show status Change
                                    canYou = true
                                else
                                    canYou = ( this.active_listing_info[0]?.qa_user_id == this.user["id"])//If QA owner is same with Current User show status
                            }
                        }else{//If access level is amdin or super user show status change
                            canYou = true
                        }
                    }
                }
                return canYou
            },
            canChangeStatus() {
                const restrictionName = this.changeStatusRestrictionName;
                return !this.isFeatureRestricted(restrictionName);
            },
            changeStatusRestrictionName() {
                const changeStatusRestriction = {
                    'auto qualified'  : 'v-status-change-in-auto-qualified',
                    'has potential'   : 'v-status-change-in-has-potential',
                    'work in progress': 'v-status-change-in-wip',
                    'for purchasing'  : 'v-status-change-in-for-purchasing',
                    researched        : 'v-status-change-in-researched',
                    qualified         : 'v-status-change-in-qualified',
                    invalid           : 'v-status-change-in-invalid',
                    monitoring        : 'v-status-change-in-monitoring',
                    rejected          : 'v-status-change-in-rejected',
                    accepted          : 'v-status-change-in-accepted',
                    submitted         : 'v-status-change-in-submitted',
                    filed             : 'v-status-change-in-filed',
                    archived          : 'v-status-change-in-archived',
                };

                const status = this.$route.query.status.toLocaleLowerCase();
                const key    = status === 'hacked' ? 'auto qualified' : status;

                return changeStatusRestriction[key];
            },
            canChangeStatusRestrictions() {
                const listingStatus = getQFlagId(this.$route.query.status);

                const canChangeStatus = !this.isFeatureRestricted('v-button-can-change-status');
                if (!canChangeStatus) {
                    return false;
                }

                const isListingWIP = listingStatus === ListingStatus.WIP;
                const canChangeStatusInWIP = !this.isFeatureRestricted('v-button-change-status-in-wip');
                if (isListingWIP && !canChangeStatusInWIP) {
                    return false;
                }

                const isListingResearched = listingStatus === ListingStatus.Researched;
                const canChangeStatusInResearched = !this.isFeatureRestricted('v-button-change-status-in-researched');
                if (isListingResearched && !canChangeStatusInResearched) {
                    return false;
                }

                const isListingForPurchasing = listingStatus === ListingStatus.ForPurchasing;
                const canChangeStatusInForPurchasing = !this.isFeatureRestricted('v-button-change-status-in-for-purchasing');
                if (isListingForPurchasing && !canChangeStatusInForPurchasing) {
                    return false;
                }

                return true;
            },
            canAddNotes() {
                /* let canYou   = false
                if (this.$store.state.is_active_list_ready) {
                    let qflag_id = this.active_listing_info[0]?.qflag_id || 0
                    canYou = [1,2,7].includes(this.access_level) && ![3,98,31,32,33,35,34].includes(qflag_id)
                }
                return canYou */

                if (!this.isListingInfoReady) {
                    return false;
                }

                return !this.isFeatureRestricted('v-button-show-notes');
            },
            isListingInfoReady() {
                return this.$store.state.is_active_list_ready
            },
            today() {
                // let date = new Date().toISOString().split('T')[0]
                // // Added format for implementation of new Endpoints
                // let date2 = new Date(new Date().getTime())
                // let year = date2.getFullYear()
                // date2 = date2.toString().split(year.toString())[1]
                // date2 = date2.split(' ')[1]
                // date = `${date} ${date2}`
                // /**
                //  * Previous Code
                //  */
                return new Date().toISOString().split('T')[0]
                // return date
            },
            has_date_qualified_filter() {
                return ['Qualified', 'Monitoring', 'Accepted', 'Rejected', 'Filed'].includes(this.$route.query.status)
            },
            dem_platforms() {
                // to call the filter drawer essence and its function. hehehe - Marvin 01/30/20
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                return filter_drawer.dem_platforms.filter(platform => this.$route.query.platform.toString().split(',').includes(platform.value.toString()))
            },
            active_campaign(){
                return this.campaign.name
            },
            active_evidences(){
                let evidences = this.modal_evidences
                return this.isListingInfoReady 
                    ? {...evidences,...this.active_listing_info[0]?.evidences || {}}
                    : this.modal_evidences
            },
            filtered_campaign_users(){
                let campaign_users = this.campaign_users
                // if(this.reassignment_mode == 'QA'){
                //     console.log(JSON.stringify(campaign_users))
                //     campaign_users =  campaign_users.filter(user=> [7,2].includes(user.access_level))
                // } 

                // if (this.isQAAccount) {

                const tempOptions = [{groupLabel: "Researcher", groupValues: []}, {groupLabel: "QA", groupValues: []}, {groupLabel: "Campaign Lead", groupValues: []}]

                campaign_users = campaign_users.filter(u => [1, 2, 5].includes(u.access_level))
                // console.log(campaign_users)
                // console.log(tempOptions)
                campaign_users.forEach(c => tempOptions[c.access_level == 5 ? c.access_level - 3 : c.access_level - 1].groupValues.push(c))
                campaign_users = tempOptions
                // console.log(campaign_users)

                if (this.individualReassign.user_type.selected == 1) {
                    campaign_users = campaign_users.filter(c => c.groupLabel.toLowerCase() == 'researcher' || c.groupLabel.toLowerCase() == 'campaign lead')
                }

                if(this.individualReassign.user_type.selected == 2) {
                    campaign_users = campaign_users.filter(c => c.groupLabel.toLowerCase() == 'qa')
                }

                if (this.isOpen_batchReassign) {
                    if (this.batchReassign.user_type.selected == 1) {
                        campaign_users = campaign_users.filter(c => c.groupLabel.toLowerCase() == 'researcher' || c.groupLabel.toLowerCase() == 'campaign lead')
                    }

                    if(this.batchReassign.user_type.selected == 2) {
                        campaign_users = campaign_users.filter(c => c.groupLabel.toLowerCase() == 'qa')
                    }
                }

                // }

                /* if (this.isResearcher || this.isCLAccount)
                    campaign_users = campaign_users.filter(u => [1].includes(u.access_level))

                if (this.isSuperAdmin)
                    campaign_users = campaign_users.filter(u => [1, 5].includes(u.access_level)) */

                /* const isResearcherReassignment = !this.isFeatureRestricted('v-reassign-campaign-users');
                if (isResearcherReassignment) {
                    const researchers = campaign_users.filter(u => u.access_level === 1);
                    const leads = campaign_users.filter(u => u.access_level === 5);

                    return [{ groupLabel: 'Researchers', groupValues: researchers }, { groupLabel: 'Leads', groupValues: leads }];
                } */

                return campaign_users
            },
            user() {
                return JSON.parse(localStorage.getItem("ver"))
            },
            active_listing_info() {
                if(this.lastEditedListing && this.lastEditedListing.length !== 0 && this.lastEditedListing.info.length > 0) {
                    // console.log(this.lastEditedListing,'is not empty')
                    return this.lastEditedListing.info
                } else {
                    return ((this.lists.find(list => list.rid == this.active_listing_id) || {}).info || [])
                }
            },
            enableInvalidTagging() {
                let status = this.$route.query.status.toLowerCase()
                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */
                if(this.access_level == 1 && ['work in progress' /*,'recheck' Hide For Rework*/].includes(status))
                    return true
                if(this.access_level == 2 && [/*'reworked' Hide Reworked,*/ 'has potential'].includes(status))
                    return true
                if (this.access_level == 7 && [/*'reworked' Hide Reworked,*/ 'work in progress', /*'recheck' Hide For Rework,*/ 'qualified', 'has potential'].includes(status))
                    return true
                return false
            },
            reworkTabIcon() {
                let reasons_icon = this.reworkTimeline ? "/static/svg/rework-modifications/Tab_PredefinedReason_Inactive.svg" : "/static/svg/rework-modifications/Tab_PredefinedReason_Active.svg" 
                let timeline_icon = this.reworkTimeline ? "/static/svg/rework-modifications/Tab_ReworkTimeline_Active.svg" : "/static/svg/rework-modifications/Tab_ReworkTimeline_Inactive.svg"
                return { reasons: reasons_icon, timeline: timeline_icon }
            },
            allSelectedReasons() {
                return {
                    reasons    : this.selected_predefined_reasons,
                    reasonsInfo: this.selected_predefined_sub_reasons,
                    otherReason: this.selected_other_reason
                }
            },
            isCLAccount() {
                return [5].includes(this.access_level)
            },
            isSuperAdmin() {
                return [7].includes(this.access_level)
            },
            isQAAccount() {
                return [2].includes(this.access_level)
            },
            isResearcher() {
                return [1].includes(this.access_level)
            },
            reassignStatusOptionsOld() {
                let statuses = []

                if (this.$route.query.status === 'Archived') {
                    return [{ label: 'Work in Progress', value: 4 }];
                }

                if ([5, 7].includes(this.access_level)) {
                    statuses = [{
                        label: "Work in Progress",
                        value: 4
                    },
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // {
                    //     label: "For Rework",
                    //     value: 2,
                    // },
                    {
                        label: "Invalid",
                        value: 99,
                    },
                    {
                        label: "Researched",
                        value: 1,
                    },
                    /**
                     * Deliverable: Hide all For Rework/Reworked elements across all GUI
                     * Deliverable Number: 454
                     */
                    // {
                    //     label: "Reworked",
                    //     value: 8,
                    // },
                    {
                        label: "Qualified",
                        value: 3,
                    },
                    {
                        label: "Has Potential",
                        value: 98,
                    }]

                    if ([5].includes(this.access_level)) {
                        // if ([1,98,99].includes(this.currentStatus))
                        //     statuses = statuses.filter(s => s.value != 4)

                        // if ([8].includes(this.currentStatus))
                        //     statuses = statuses.filter(s => s.value != 2)

                        // If current status is "Researched" then option is only "Work in Progress"
                        if ([1].includes(this.currentStatus))
                            statuses = statuses.filter(s => s.value == 4)

                        /**
                         * Deliverable: Hide all For Rework/Reworked elements across all GUI
                         * Deliverable Number: 454
                         */
                        // // If current status is "Reworked" then option is only "For Rework"
                        // else if ([8].includes(this.currentStatus))
                        //     statuses = statuses.filter(s => s.value == 2)
                    }
                }

                if ([2].includes(this.access_level)) {
                    statuses = [{
                        label: "Work in Progress",
                        value: 4
                    },
                    {
                        label: "Researched",
                        value: 1,
                    },
                    {
                        label: "Qualified",
                        value: 3,
                    }]

                    if (this.individualReassign.user_type.selected == 1 || this.batchReassign.user_type.selected == 1) {
                        statuses = [{
                            label: 'Work in Progress',
                            value: 4,
                        }, {
                            label: "Researched",
                            value: 1,
                        }]
                    }
                }

                return statuses
            },
            reassignStatusOptions() {
                let statuses = [
                    { label: 'Work in Progress', value: ListingStatus.WIP },

                    /**
                     * Deliverable #552: Removal of Invalid status from Artemis
                     */
                    // { label: 'Invalid', value: ListingStatus.Invalid },
                    
                    { label: 'Researched', value: ListingStatus.Researched },
                    { label: 'Qualified', value: ListingStatus.Qualified },
                    { label: 'Has Potential', value: ListingStatus.HasPotential },
                    { label: 'Archived', value: ListingStatus.Archived },
                ];

                if (this.currentStatus === ListingStatus.Archived) {
                    return statuses.filter(status => status.value === ListingStatus.WIP);
                }

                /* if (+this.individualReassign.user_type.selected === 1 || +this.batchReassign.user_type.selected === 1) {
                    return statuses.filter(status => [ListingStatus.WIP, ListingStatus.Researched].includes(status.value));
                } */

                if (this.currentStatus === ListingStatus.Researched) {
                    statuses = statuses.filter(status => status.value === ListingStatus.WIP);
                }

                /**
                 * Remove one status from the statuses with the given parameter.
                 * @param { number } statusToRemove 
                 */
                const filterStatus = (statusToRemove) => {
                    const filteredStatuses = statuses.filter(status => status.value !== statusToRemove);
                    return filteredStatuses;
                }

                if (this.isFeatureRestricted('v-reassignment-wip-status')) {
                    statuses = filterStatus(ListingStatus.WIP);
                }

                if (this.isFeatureRestricted('v-reassignment-invalid-status')) {
                    statuses = filterStatus(ListingStatus.Invalid);
                }

                if (this.isFeatureRestricted('v-reassignment-researched-status')) {
                    statuses = filterStatus(ListingStatus.Researched);
                }

                if (this.isFeatureRestricted('v-reassignment-qualified-status')) {
                    statuses = filterStatus(ListingStatus.Qualified);
                }

                if (this.isFeatureRestricted('v-reassignment-haspo-status')) {
                    statuses = filterStatus(ListingStatus.HasPotential);
                }

                return statuses.filter(status => encodeURI(status.label) !== encodeURI(this.$route.query.status));
            },
            get_existing_listings() {
                let result = null
                if(this.hasExisting_listings){
                    result = this.computed_reassignmentFailed_meta_data.map(item => {
                        return { 
                            duplicate : item.duplicate.map(dup => {
                                return {
                                    lid: dup.id,
                                    qflag_id_word: this.$parent.getQFlag(dup.qflag_id)
                                }
                            }),
                        }
                    })
                }
                return result
            },
            computed_reassignmentFailed_meta_data() {
                return this.reassignmentFailed_meta_data
            },
            // sort_by_display() {
            //     let display = ""
            //     switch (this.sort_by) {
            //         case "0":
            //         case "id":
            //             display = "Listing ID"
            //             break
            //         case "researcher":
            //             display = "Researcher"
            //             break
            //         case "qa":
            //             display = "QA"
            //             break
            //         case "date_added":
            //             display = "Date Added"
            //             break
            //     }
            //     return display
            // },
            check_qa_selected() {
                let is_qa_selected = false
                if(this.isQAAccount){
                    if(this.individualReassign.user_type.selected == 2){
                        is_qa_selected = true
                    }
    
                    if(this.individualReassign.user.selected.access_level == 2){
                        is_qa_selected = true
                    }

                    if(this.isOpen_batchReassign) {
                        if(this.batchReassign.user_type.selected == 2){
                            is_qa_selected = true
                        }
        
                        if(this.selected_campaign_user.access_level == 2){
                            is_qa_selected = true
                        }
                    }

                }
                return is_qa_selected
            },
            reassignment_user_type() {
                let statuses = [{
                    label: "All",
                    value: 0,
                }, {
                    label: "Researcher / CL",
                    value: 1,
                }, {
                    label: "QA",
                    value: 2,
                }]

                if(this.$route.query.status.toLowerCase() !== 'researched') {
                    statuses = [{
                        label: "Researcher / CL",
                        value: 1,
                    }]
                }

                return statuses
            },
            itemTypeOptions() {
                return [{label: "No Item Type", value: "No Item Type"}, ...this.filter_item_type_options.map(f => ({label: f.type, value: f.type}))]
            },
            purchaseTypeOptions() {
                /**
                 * Update purchase type values in Artemis
                 * Deliverable #: 506
                 */

                // return [
                //     {label: "No Purchase Type", value: -1 },
                //     {label: "Successfully purchased and allowed to ship", value: "Successfully purchased and allowed to ship"},
                //     {label: "Successfully purchased and cancelled/refunded", value: "Successfully purchased and cancelled/refunded"},
                //     {label: "Tried purchasing but failed", value: "Tried purchasing but failed"},
                //     {label: "Tried purchasing and got account", value: "Tried purchasing and got account"},
                //     {label: "No need to purchase", value: "No need to purchase"}
                // ]
                return [
                    {label: "No need to purchase", value: "No need to purchase", sub: []},
                    {label: "Purchase attempt failed", value: "Purchase attempt failed", sub: []}, // done
                    {label: "Successfully purchased and cancelled/refunded", value:'Successfully purchased and cancelled/refunded',
                        sub: [
                            // Breakdown
                            {label: "Purchase cancelled by buyer", value: "Purchase cancelled by buyer"},
                            {label: "Purchase cancelled by seller", value: "Purchase cancelled by seller"},
                            {label: "Purchase cancelled by platform", value: "Purchase cancelled by platform"},
                            {label: "Purchase refunded", value: "Purchase refunded"},
                        ],
                    },
                    // new entry
                    {label: "Pending seller confirmation", value: "Pending seller confirmation", sub: []},
                    {label: "Purchased, shipping pending", value: "Purchased, shipping pending", sub: []},
                    {label: "Successfully purchased and allowed to ship", value: "Successfully purchased and allowed to ship",
                        sub: [
                            // Breakdown
                            {label: "Purchased and shipped", value: "Purchased and shipped", sub: []},
                            {label: "Delivery delayed", value: "Delivery delayed", sub: []},
                            {label: "Delivery failed/unsuccessful", value: "Delivery failed/unsuccessful", sub: []},
                            {label: "Delivered", value: "Delivered", sub: []},
                        ]
                    },
                    // {label: "Tried purchasing and got account", value: "Tried purchasing and got account"}, // remove 
                    {label: "No Purchase Type", value: -1, sub: [] },
                ]
            },
            canMarkAsAccepted() {
                const canStatusChangeToAccepted = !this.isFeatureRestricted('v-status-change-accepted');
                const isInClientListing = this.$route.query.status === 'Client Listing';
                const isInQualified = this.$route.query.status === 'Qualified';

                return canStatusChangeToAccepted && (isInClientListing || isInQualified);
            },
            restrictedFeatures() {
                return useFeatureAccessStore().restrictedFeatures;
            },
            canChangeStatusToQualified() {
                if (this.isFeatureRestricted('v-status-change-qualified')) {
                    return false;
                }

                const queryStatus = this.$route.query?.status;
                const isClientListingTab = queryStatus === 'Client Listing';

                const qflagId = this.active_listing_info?.[0]?.qflag_id;
                const isListingQualified = +qflagId === 3;

                const isInArchivedStatus = queryStatus === 'Archived';

                return !isListingQualified && !isClientListingTab && !isInArchivedStatus;
            },
            computedLFC() {
                let lfc = this.client_Listings.map(r => r.rid)
                return lfc
            },
            /** @return { 0 | 1 | -1 } */
            hasLFC() {
                let status = +(this.lfcStatus)
                if (this.selected_listings.every(item => this.computedLFC.includes(item))) {
                    //There is/are LFC selected
                    status = 1
                }
                else if (this.selected_listings.every(item => !this.computedLFC.includes(item))) {
                    //There is/are Non-LFC selected
                    status = 0
                }
                // Both LFC and Non-LFC is/are selected
                else {
                    this.hasLFCMessage()
                    status = -1
                }
                return status
            },
            ListingStatus() {
                return ListingStatus;
            },
            disabledReassignAccounts() {
                const ids = [938, 243, 861, 221, 878, 249, 927, 355, 453, 1017, 241, 937, 389, 939, 237, 940]
                const current = this.user?.id
                let isDisabled = false

                if (ids.includes(current)) {
                    isDisabled = true
                }
                else isDisabled = false

                return isDisabled
            },
            active_li() {
                return this.active_listing_info[0] ?? {}
            },
            selectedListingsValues() {
                return this.lists.filter(l => this.selected_listings.includes(l.rid))
            },
            generalHistory() {
                const hasActiveListing = this.active_listing_info.length !== 0;

                if (!hasActiveListing) {
                    return [];
                }

                return this.active_listing_info[0].general_history;
            },
            uploadedEvidences() {
                return this.uploaded_evidences;
            },
            requiredFields() {
                const listing = this.active_listing_info?.[0] ?? null;

                if (listing === null) {
                    return [];
                }

                const isNotNullAndAllowed = (feature) => feature.required_field !== null
                    && this.$featureAccess.isAllowed(feature.class_name)
                    && feature.gui === 'verification';

                const featuresWithRequiredFields = this.$featureAccess.features()
                    .filter(isNotNullAndAllowed);

                const initialValue = Object.freeze({
                    undefined: undefined,
                    null: null,
                    zero: 0,
                    emptyString: '',
                });

                const possibleInitialValues = Object.values(initialValue);

                const isComplete = (value) => {
                    if (Array.isArray(value)) {
                        return value.length !== 0;
                    }

                    if (value === '0') {
                        value = 0;
                    }

                    return !possibleInitialValues.includes(value);
                }

                const requiredFields = featuresWithRequiredFields.map(feature => {
                    const { name, required_field } = feature;
                    const __required_field =  typeof required_field === 'string' ?  JSON.parse(required_field) : required_field;
                    const { field, type } = __required_field;

                    const isEvidenceType = type === 'evidence';
                    const value          = isEvidenceType ? listing.evidences?.[field] : listing[field];

                    return { isComplete: isComplete(value), ...__required_field };
                });

                return requiredFields;
            },
            evidenceRequiredFields() {
                return this.requiredFields.filter(field => field.type === 'evidence');
            },
            dataRequiredFields() {
                return this.requiredFields.filter(field => field.type === 'data');
            },
            hasMissingRequiredFields() {
                const requiredFields = this.requiredFields;
                const hasMissingRequiredFields = requiredFields.some(field => !field.isComplete);

                return hasMissingRequiredFields;
            },
            filtered_predefined_reasons() {
                let predefined_reasons = [...this.predefined_reasons];

                if ((this.checkIndividualLFC || this.LFCDetected.length > 0)) {
                    predefined_reasons = predefined_reasons
                        .filter(item => item.is_for_clients === 1 && item.status === 1)
                        .map(item => {
                            if (item.reason && item.reason instanceof Object) {
                                const filteredReason = {};
                                Object.keys(item.reason).forEach(key => {
                                    filteredReason[key] = item.reason[key].filter(subItem => subItem.is_for_clients === 1);
                                });
                                return { ...item, reason: filteredReason };
                            }
                            return item;
                        });
                }

                return predefined_reasons;
            },
            filtered_reasons_with_sub() {
                let predefined_reasons = this.filtered_predefined_reasons
                    .filter(sub => sub.type == 2 && sub.reason && Object.keys(sub.reason).length > 0)
                    .map(item => {
                        const uniqueSubReasons = new Set();

                        Object.keys(item.reason).forEach(subReasonKey => {
                            const subReasons = item.reason[subReasonKey];
                            subReasons.forEach(subReason => {
                                uniqueSubReasons.add(JSON.stringify({ name: subReasonKey, id: item.id }));
                            });
                        });

                        return Array.from(uniqueSubReasons).map(jsonStr => JSON.parse(jsonStr));
                    })
                    .flat();

                // Remove duplicates
                return predefined_reasons.filter((item, index, self) =>
                    index === self.findIndex(obj => obj.id === item.id && obj.name === item.name)
                ).filter(item => item.id == this.selected_predefined_sub_reasons[0]?.reason_id).map(item => ({
                    name: item.name
                }));
            },
            is_active_listing_lfc() {
                return this.computedLFC.includes(this.active_listing_id)
            },
            computedLoading() {
                let other_res_length = this.selected_other_reason.length;
                let main_res_length = this.selected_predefined_reasons.length;
                let sub_res_length = this.selected_predefined_sub_reasons.length;
                let sum_res_length = main_res_length + sub_res_length + other_res_length;
                let batch_status = this.batch_change_status
                let single_status = this.selected_change_status

                if(this.selected_listings.length) {
                    if (this.is_loading) {
                        // Case 1: If is_loading is true, return true
                        return true;
                    } else if (!this.is_loading && sum_res_length <= 2 && other_res_length > 0 && (batch_status == 99 || single_status == 99)) {
                        // Case 2: If is_loading is false, sum_res_length > 1, and other_res_length > 0, return false
                        return false;
                    } else if (!this.is_loading && sum_res_length >= 3 && other_res_length === 1 && (batch_status == 99 || single_status == 99) && this.hasLFC == 1) {
                        // Case 4: If is_loading is false, sum_res_length > 2, and other_res_length is 1, return true
                        return true;
                    } else if(!this.is_loading && sum_res_length == 0){
                        return true
                    } else if(!this.is_loading && sum_res_length >= 2 && other_res_length  < 1 && (batch_status == 99 || single_status == 99) && this.hasLFC == 1) {
                        return true
                    }
                    else {
                        // Default case: All other conditions result in false
                        return false;
                    }
                }
                else {
                    if (this.is_loading) {
                        // Case 1: If is_loading is true, return true
                        return true;
                    } else if([1,0].includes(this.selected_comm)) {
                        return false
                    }else if(!this.is_loading && batch_status !== 99 || single_status !== 99) {
                        return false
                    } else if (!this.is_loading && sum_res_length <= 2 && other_res_length > 0 && (batch_status == 99 || single_status == 99)) {
                        // Case 2: If is_loading is false, sum_res_length > 1, and other_res_length > 0, return false
                        return false;
                    } else if (!this.is_loading && sum_res_length >= 3 && other_res_length === 1 && (batch_status == 99 || single_status == 99) && this.is_active_listing_lfc == true) {
                        // Case 4: If is_loading is false, sum_res_length > 2, and other_res_length is 1, return true
                        return true;
                    } else if(!this.is_loading && sum_res_length == 0){
                        return true
                    } else if(!this.is_loading && sum_res_length >= 2 && other_res_length  < 1 && (batch_status == 99 || single_status == 99) && this.is_active_listing_lfc == true) {
                        return true
                    }
                    else {
                        // Default case: All other conditions result in false
                        return false;
                    }
                }
            },
            canBeAccepted() {
                const isRestricted = this.$featureAccess.isRestricted('v-status-change-accepted');

                if (isRestricted) {
                    return false;
                }

                const { status } = this.$route.query;
                const isInClientListingTab = status === 'Client Listing' && this.hasLFC;
                const isInResearchedTab = status === 'Researched';
                const isInQualifiedTab = status === 'Qualified';
                const isInHasPotentialTab = status === 'Has Potential';

                return isInClientListingTab || isInResearchedTab || isInQualifiedTab || isInHasPotentialTab;
            }
            // currentProgress() {
            //     let progress = this.currentAssignedCampaign?.listing_count
            //     let target = this.currentAssignedCampaign?.target
            //     return progress / target * 100
            // }
        },
        beforeRouteEnter(to, from, next){
            next( vm => {
                vm.prev_route = from
            })
        },
        beforeCreate(){
            let ver = JSON.parse(localStorage.getItem("ver"))
            if(ver == null){
                this.$router.push('/verification')
            }
        },
        async mounted() {
            document.addEventListener('keydown', event => {
                if (event.altKey && event.key === 'x' || event.altKey && event.key === 'X') {
                    this.filtered_listing_id = []
                }
            })
            // await the listings to check the client listings
            /* await this.getListing()
            await this.getCampaigns()
            await this.getReasons()
            await this.setResearchTrackerLoad() */

            await Promise.all([
                this.getListing(),
                this.getCampaigns(),
                this.getReasons(),
                this.setResearchTrackerLoad(),
                this.getRestrictedReasons(),
            ]);

            /**
             * Transfer to getAdditionalFilters_data()
             * to be called when Additional Filters is open
             */
            // this.getClaimTypes()
            // this.getItemTypes()
            // // this.getRoles()
            // // this.getEmployees()
            // this.getShippingLocations()
            // this.getPaymentMethods()
            // this.getSellerLocations()
            // this.getItemClasses()
            // this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status))
            
            if ([1].includes(this.access_level)){
                this.getUserCampaigns()
            }
            else{
                this.getResearchers()
            }
            document.title = this.$route.meta.title
            
            let filter_drawer_dom = document.querySelector('.a-sidebar.a-sidebar-filter')
            filter_drawer_dom.style.paddingBottom = "122px"
            
            if(this.$route.query.status.toLowerCase() == 'qualified') {
                this.batchReassign.user_type.selected = 1
                this.individualReassign.user_type.selected = 1
                return
            }
            this.batchReassign.user_type.selected = 0
            this.individualReassign.user_type.selected = 0
            if (this.hasLFC == -1){
                this.hasLFCMessage()
            }
        },
        methods: {
            ...mapActions(["enableTabs","getActiveListingInfo", "changeShowError", "reduceSidebarCampaignCount", 'setRelatedCampaignDuplicates', 'setRelatedCampaignDuplicateBypass', 'setRelatedCampaignDuplicateContinueFunction', 'increaseUserCurrentWeeklyQuota']),
            setTransferToAnotherSubStatus(option) {
                this.resetCommission(); 
                this.getPredefinedReasons(this.currentStatus);
                this.subStatusesStore.currentSelectedSubStatus = option.id; 
                this.subStatusesStore.currentSelectedSubStatusLabel = option.label
            },
            refreshVer_statuses() {
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                    filter_drawer.dem_statuses = []
                    filter_drawer.initializeStatuses()
            },
            async updateListingSubStatus(listings = []) {
                if (this.subStatusesStore.currentSelectedSubStatus == null)
                    return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
                
                this.is_loading = true

                const hasNoReasons = this.$store.state.checked_reasons.length === 0
                    && this.$store.state.checked_subreasons.length === 0
                    && isEmpty(this.$store.state.checked_other_reason)
                if (hasNoReasons) {
                    this.is_loading = false
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                }
                if (!isEmpty(this.$store.state.checked_other_reason)) {
                    if (isEmpty(this.$store.state.other_reason_comment)) {
                        this.is_loading = false
                        return this.$parent.showSnackbar('Please provide comment', false, 'invalid', 'fas fa-times-circle')
                    }
                }

                try {
                    const config = this.$session.get('ver_headers')
                    config.headers["Allow-Checking-Bypass"] = 1

                    const data = {
                        target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                        listing_ids: listings?.length ? listings.join(',') : this.active_li.rid,
                        comment: '',
                        reasons: this.setPredefinedReasons(),
                        is_same_status: true
                    }

                    await this.$http.patch(`/listings/multiple/statuschange/${this.$parent.getQFlagId(this.$route.query.status)}`, data, config)

                    this.$parent.showSnackbar(`Successfully transferred listing to new substatus.`, false, 'success', 'fas fa-check')

                    this.selected_listings = []
                    this.is_select_all = false
                    this.checkAllListing()

                    this.getListing()
                    this.refreshVer_statuses()

                    $('.modal').modal('hide')
                } catch (e) {
                    console.log(e)
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                }

                this.is_loading = false
            },
            toggleSubStatusReassignment(e, id) {
                this.subStatusesStore.currentSelectedSubStatus = null
                this.subStatusesStore.currentSelectedSubStatus = id
            },
            toggleSubStatus(e, id, status) {
                this.subStatusesStore.currentSelectedSubStatus = null

                let p = e.target.parentElement
                let count = 0

                while (!$(p).hasClass('status-change-options') && count < 6) {
                    p = p.parentElement
                    count++
                }

                $(p).children().first().addClass('active focus')

                this.selected_change_status = status
                this.subStatusesStore.currentSelectedSubStatus = id
            },
            async toggleSubStatusOnly(e, id, status, qflag) {
                if (this.subStatusesStore.currentSelectedSubStatus == id) {
                    $('.a-modal-status-history-con .sub-statuses-container .btn.sub-status').removeClass('active')
                    
                    this.subStatusesStore.currentSelectedSubStatus = null
                    this.predefined_reasons = []
                    this.show_predefined_reasons = false
                    this.selected_change_status = ''

                    return
                }

                if (this.selected_change_status != status)
                    this.predefined_reasons = []

                this.subStatusesStore.currentSelectedSubStatus = null

                let p = e.target.parentElement
                let count = 0

                while (!$(p).hasClass('status-change-options') && count < 6) {
                    p = p.parentElement
                    count++
                }

                $(p).children().first().addClass('active focus')
                $('.a-modal-status-history-con :not(.sub-statuses-container) .btn-group-vertical :not(.sub-statuses-container) .btn').removeClass('active focus')

                this.predefined_status      = status
                this.selected_change_status = qflag
                this.subStatusesStore.currentSelectedSubStatus = id

                //Reset reworkLevel and reworkCommission
                this.reworkCommission = null;
                this.reworkLevel      = null;
                this.hasAcknowledged  = false;
                this.reworkTimeline   = false;

                await this.resetSelectedStatus();
                this.resetCommission();

                this.fetching_reasons       = false;
                this.lid_to_change          = {};

                let clicked_statuses     = this.clicked_statuses; //To know the current and previous clicked status

                this.resetReasonsSelectionStore();

                this.show_predefined_reasons = true;

                this.clicked_statuses.push(qflag);

                if (this.show_predefined_reasons) {
                    this.selected_predefined_reasons = [];
                    this.other_reason                = '';

                    this.getPredefinedReasons(qflag);
                }
            },
            removeSubStatusFocus() {
                // setTimeout(() => {
                //     $('.a-modal-status-history-con .btn.sub-status').removeClass('active focus')
                // })
            },
            async removeListing(ids = []) {
                this.remove_listing.loading = true
                this.$parent.showSnackbar('Removing listings.. Please wait..', true)
                const selected = ids.length ? ids : this.selected_listings
                const queries = { reasons: this.setPredefinedReasons() };

                try {
                    for (let i = 0; i < selected.length; i++)
                        await this.$http.delete(`listings/${selected[i]}?${qs.stringify(queries, { encodeValuesOnly: true })}`, this.$session.get('ver_headers'))

                    this.getListing()

                    $('.modal').modal('hide')
                    this.selected_listings = []
                    this.is_select_all = false
                    this.checkAllListing()

                    this.$parent.showSnackbar('Successfully removed selected listing(s).', false, 'success', 'fas fa-check-circle')
                } catch (error) {
                    console.log(error);
                    this.$parent.showSnackbar('Error occured when deleting listing.', false, 'invalid', 'fas fa-times-circle')
                }
                this.remove_listing.loading = false
            },
            async getItemLocations() {
                try {
                    const currentSubStatus = this.$route.query.substatus_id
                    const headers = this.$session.get('ver_headers')
                    if (currentSubStatus && currentSubStatus != 0 && currentSubStatus != -1 && currentSubStatus != "undefined")
                        headers.headers['Substatus-id'] = currentSubStatus

                    const { data } = await this.$http.get(`/api/listing-locations/campaigns/${this.$route.params.campaign_id}/status/${this.$parent.getQFlagId(this.$route.query.status)}`, headers)

                    let result = data.data.map(d => ({ label: `${d.value} [${d.count}]`, value: d.value, count: d.count }))
                    result.sort((a, b) => (a.label > b.label)? 1 : ((b.label > a.label)? -1 : 0))
                    this.filters__.item_location.options = result

                    if (this.filters__?.item_location?.options.length) {
                        const countNoItemLocation = data.meta.noLocationCount
                        this.filters__?.item_location?.options.unshift({ label: `No Item Location [${countNoItemLocation}]`, value: '---', count: countNoItemLocation })
                    } 
                } catch (ex) {
                    console.log(ex)
                }
            },
            getEmployeeName(first, last) {
                return (first + " " + last).trim()
            },
            checkForInvalidLFC() {
                if (this.client_Listings.find(({ rid }) => rid == this.active_listing_id)) {
                    this.checkIndividualLFC = true
                }
                else {
                    this.checkIndividualLFC = false
                }
            },
            confirmCheckLFC() {
                let listing_id = this.active_listing_id
                let selected_listings = this.selected_listings
                let selected_status = this.selected_change_status
                let batch_status = this.batch_change_status
                let campaign = this.campaign_id
                let individual = this.individualReassign.status
                let comment = ''
                let isIndividual = false
                let list = null

                //INDIVIDUAL LFC INVALIDATION
                if(this.confirmedLFCNote && this.checkIndividualLFC && this.individualReassign.show == false && this.selected_change_status == 99) {
                    this.changeStatus(listing_id, selected_status)
                }

                if(this.confirmedLFCNote && this.checkIndividualLFC && this.individualReassign.show == true && this.selected_change_status == 99) {
                    this.newReassignListing(listing_id, selected_status, campaign, individual)
                }

                //MULTIPLE LFC INVALIDATION
                if(this.confirmedLFCNote && this.selected_listings.length && this.batch_change_status == 99) {
                    this.batchChangeStatus(selected_listings, batch_status, comment);
                }

                if(this.confirmedLFCNote && this.hasLFC == 1 && this.batchModalProps.status == 99  && this.batch_change_status == 99) {
                    this.doNewReassign()
                }
            },
            multipleCheckForInvalidLFC() {
                this.LFCDetected = this.computedLFC.filter(m => this.selected_listings.includes(m))
                this.nonLFCDetected = this.selected_listings.filter(s => !this.computedLFC.includes(s))
                
                if ((this.LFCDetected.length != 0) && (this.nonLFCDetected != 0)) {
                    this.triggerLFCInvalidNotice()
                }
            },
            triggerLFCInvalidNotice() {
                $('#lfc-invalid-notice').modal('show')
            },
            triggerMultipleLFCInvalidNotice() {
                $('#lfc-multiple-invalid-notice').modal('show')
            },
            // triggerManyLFCReasons() {
            //     let other_res_length = this.selected_other_reason.length
            //     let main_res_length = this.selected_predefined_reasons.length
            //     let sub_res_length = this.selected_predefined_sub_reasons.length
            //     let sum_res_length = main_res_length + sub_res_length + other_res_length

            //     if(sum_res_length > 1 && other_res_length == 0) {
            //         this.is_reason_many = true
            //     } else if(sum_res_length > 1 && other_res_length > 0) {
            //         this.is_reason_many = false
            //     }
            //     else this.is_reason_many = false
            // },
            async getEmployees() {
                try {
                    const { data } = await this.$http.get('users?page_size=100000', this.$session.get('ver_headers'))

                    this.filter_employee.options = data.data.filter(d => d.status).map(d => ({label: this.getEmployeeName(d.first_name, d.last_name), value: d.id}))
                } catch (ex) {
                    console.log(ex)
                }
            },
            async getItemTypes() {
                try {
                    const { data } = await this.$http.get(`classification_types/inCampaign/${this.$route.params.campaign_id}?page_size=100000`, this.$session.get('ver_headers'))

                    this.filter_item_type_options = data.data
                } catch (ex) {
                    console.log(ex)
                }
            },
            updateOption(key, value) {
                this[key] = value;
            },
            async getClaimTypes() {
                const { data } = await this.$http.get("claimtype?page_size=10000", this.$session.get("ver_headers"));
                
                this.filter_claimtype.options = [{label: "No Claimtype", value: -1}, ...data.data.map(d => ({ label: d.claim_type, value: d.id }))]
            },
            async getShippingLocations() {
                const { data } = await this.$http.get("shipping_locations?page_size=10000", this.$session.get("ver_headers"));

                // this.filter_shipping_location.options = [{label: "No Shipping Location", value: -1}, ...data.data.filter(d => d.active).map(d => ({ label: d.location, value: d.id }))] // old options - Marvin 5/16/2023
                this.filter_shipping_location.options = [...data.data.filter(d => d.active).map(d => ({ label: d.location, value: d.location }))]
            },
            reassignResearcherOptionsLabel({ label }) {
                return label
            },
            async getListing() {
                this.subStatusesStore.currentSelectedSubStatus = null

                /**
                 * Deliverable #552: Removal of Invalid status from Artemis
                 * - Redirect user to Work in Progress if query.status = Invalid
                 */
                 if (this.$route.query.status == "Invalid") {
                    this.$router.push({ ...this.$route, query: { ...this.$route.query, status: "Work in Progress" } })
                    return
                }
                this.$parent.showBackdrop()
                if (this.searching) return
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                let conditions = {}
                this.searching = true
                let keywords = this.search_keywords.trim()
                if (this.lid_status == "" && this.searched_option == 0 && keywords != '') {
                    /**
                     * If uncommented the behavior will be
                     * 
                     *  - after searching it will set the vuex variable and serves as a trigger
                     *  - the trigger will then execute the code at the end of this function
                     */
                    this.$store.dispatch('setKeywords', keywords)
                    await sleep(500)
                    await this.searchByLID(keywords)
                    await sleep(500)
                    this.$router.push({name:'listing', query: { ...this.$route.query, status: this.lid_status, platform: this.$route.query.platform }})
                    
                    this.searching = false
                    return
                }
                setTimeout(() => {
                    // to call the filter drawer essence and its function. hehehe - Marvin 01/30/20
                    let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                    filter_drawer.applyPlatformFilters()
                }, 500)
                await sleep(200)

                const substatus = this.$route.query.substatus_id?.toString()?.split('_')?.[0] ?? this.$route.query.substatus_id

                conditions.substatus_id = (!this.subStatusesStore.canSeeSubStatuses || substatus == 0 || substatus == -1) ? undefined : substatus
                conditions.with_substatus_set = !this.subStatusesStore.canSeeSubStatuses || substatus == -1 ? undefined : substatus != 0

                if (this.$route.params.campaign_id != 0) {
                    conditions.campaign_id = this.$route.params.campaign_id
                }
                if (this.$route.query.platform != 0) {
                    conditions.platform_id = this.$route.query.platform.toString().split(",")
                }
                if (this.$route.query.status != 'All') {
                    conditions.qflag_id = this.currentStatus
                }
                if (keywords != "") {
                    if (this.searched_option == 0)
                        conditions.id = keywords
                    // else if (this.searched_option == 1)
                    //     conditions.seller_id = { sellers: { name: keywords }}
                    else if (this.searched_option == 2)
                        conditions.url = keywords
                    else if (this.searched_option == 3)
                        conditions.store_url = keywords
                    // conditions.seller_id = { sellers: { url: keywords }}
                    // else if (this.searched_option == 4)
                    //     conditions.researcher = keywords
                    // else if (this.seached_option == 5)
                    //     conditions.qa = keywords
                }
                if([1,6].includes(this.access_level)){
                    conditions.user_id = this.user.id
                }
                if(this.$route.query.status == 'Client Listing'){
                    if (this.access_level == 7){
                        conditions.qflag_id = [4, 1, 0]
                    }
                    else if(this.access_level == 2){
                        conditions.qflag_id = [1, 0]
                    }
                    else {
                        conditions.qflag_id = [4, 0]
                    }
                    // conditions.client_listings = true
                    conditions.has_client_listing = 1
                }

                if(this.filtered_listing_id && this.filtered_listing_id.length)
                    conditions.id = this.filtered_listing_id.join()

                if (this.min_date_added != "")
                    // Previous Code
                    // conditions.date_added = { from: this.min_date_added, to: this.max_date_added }
                    conditions.created_at = { from: `${this.min_date_added} 00:00:00`, to: `${this.max_date_added} 23:59:59`}
                if (/*this.has_date_qualified_filter &&*/ this.min_date_qualified != "")
                    // Previous Code
                    // conditions.date_qualified = { from: this.min_date_qualified, to: this.max_date_qualified }
                    conditions.qualified_at = { from: `${this.min_date_qualified} 00:00:00`, to: `${this.max_date_qualified} 23:59:59` }
                if (this.filter_case_number != "" && this.$route.query.status == 'Filed')
                    // conditions.case_number = this.filter_case_number
                    conditions["case.case_number"] = this.filter_case_number

                
                // if (this.filter_item_location != "")
                //     // conditions.listing_location = this.filter_item_location
                //     conditions.location = this.filter_item_location

                if (this.filters__.item_location?.selected != 0)
                    conditions.location = this.filters__.item_location?.selected

                if (this.filter_seller_location != "0")
                    conditions.seller_location = { with: this.filter_with_seller_country, value: this.filter_seller_location }
                if (this.selected_payment_methods.length > 0)
                    // conditions.payment_methods = this.selected_payment_methods
                    conditions.seller_payment_type = this.selected_payment_methods
                if (this.selected_reasons.length > 0)
                    // conditions.reasons = { with: this.infringement_option, value: this.selected_reasons }
                    conditions.infringement_indicators = { with: this.infringement_option, value: this.selected_reasons }
                if (this.filter_researcher != '')
                    conditions.researcher = this.filter_researcher
                if (this.filter_qa != '')
                    conditions.qa = this.filter_qa
                if (this.filter_item_class != "" && this.filter_item_class != "0")
                    conditions.item_class = { with: this.filter_with_item_class, value: this.filter_item_class }
                if (this.filter_item_type.length > 0)
                    // conditions.item_types = { with: this.filter_with_item_type, value: this.filter_item_type.map(f => f.label) }
                    conditions.item_type = { with: this.filter_with_item_type, value: this.filter_item_type.map(f => f.label) }
                if (this.shown_data.includes("RD")) {
                    // conditions.my_researcher_data = "1"
                    let id = JSON.parse(localStorage.getItem('ver')).id
                    conditions.user_id = id
                }
                if (this.shown_data.includes("QD")) {
                    // conditions.my_qa_data = "1"
                    let id = JSON.parse(localStorage.getItem('ver')).id
                    conditions.qa_user_id = id
                }

                if (this.filter_seller_url)
                    conditions.store_url = this.filter_seller_url
                
                if (this.filter_product_url)
                    conditions.url = this.filter_product_url

                if (this.filter_claimtype.selected != 0)
                    // conditions.claimtype = { with: this.filter_with_claimtype, value: this.filter_claimtype.selected }
                    conditions.claim_type = { with: this.filter_with_claimtype, value: this.filter_claimtype.selected }
                
                if (this.filter_shipping_location.selected != 0)
                    conditions.shipping_location = { with: this.filter_with_shipping_location, value: this.filter_shipping_location.selected }

                if (this.filter_roles.selected.length) {
                    let roles =[]
                    this.filter_roles.selected.forEach(s => {
                        roles.push(s.value)
                    })
                    // conditions.roles = this.filter_roles
                    conditions.roles = roles
                }
                
                // if (this.filter_employee.selected != 0)
                //     conditions.employee = this.filter_employee.selected

                if (this.filter_purchase_type != 0)
                    conditions.purchase_type = this.filter_purchase_type

                if (this.filter_status_change_reason.selected.length)
                    conditions.status_change_reason = { with: this.filter_with_status_change_reason, value: this.filter_status_change_reason.selected }
                
                if (this.enable_options.includes('brand_name_on_image_option'))
                    // conditions.brand_on_item_image = !!(+this.filter_brand_name_on_image)
                    conditions.brand_on_item_image = this.filter_brand_name_on_image
                
                if (this.enable_options.includes('logo_on_image_option'))
                    // conditions.logo_on_item_image = !!(+this.filter_logo_on_image)
                    conditions.logo_on_item_image = this.filter_logo_on_image

                if (this.enable_options.includes('will_ship_to_us_option'))
                    // conditions.will_ship_to_us = !!(+this.filter_will_ship_to_us)
                    conditions.will_ship_to_us = this.filter_will_ship_to_us

                if (this.enable_options.includes('commission_option'))
                    // conditions.commission = !!(+this.filter_commission)
                    conditions.commission = this.filter_commission
                
                if(this.filterDateCaptured !== '') conditions.date_captured = this.filterDateCaptured

                if(this.access_level == 5) {
                    headers.headers.Sort = `${this.sort_by == '0' ? 'lid' : this.sort_by},${this.order_by == '0' ? 'desc' : this.order_by}`
                    headers.headers.Where = JSON.stringify(conditions)
                }
                // console.log(headers)
                if (keywords != '' && this.searched_option == 1) {
                    if (keywords.length > 3)
                        if(this.access_level == 5)
                            headers.headers['Seller-name'] = keywords
                        else
                            conditions.seller_name = keywords
                    else {
                        this.$parent.showSnackbar('Please avoid searching sellers with less than 3 characters', false, 'warning', 'fas fa-exclamation-triangle')
                        return
                    }
                }
                
                // show loading overlay
                this.$parent.showBackdrop()

                /**
                 * Previous Code
                 * Deliverable #: 503
                 */
                // let endpoint = this.access_level == 5 ? '/listings/main/team' : '/listings/main/search'
                // await axios.get(process.env.VUE_APP_URL+`${endpoint}?page_size=${this.page_size}&page=${this.current_page}`,headers)
                //     .then(response=>{
                //         let results        = response.data
                //         this.lists = results.data.map(list=>{
                //                 // console.log(list)
                //             if(list.seller.length > 0 && list.url != null){
                //                 return {
                //                     product_img   : list.evidences.preview != null ? list.evidences.preview : list.evidences.Preview,
                //                     seller_name   : list.seller[0] !== undefined ? list.seller[0].name != "" || list.seller[0].name !== null ? list.seller[0].name: '------': [],
                //                     seller_url    : list.seller[0] !== undefined ? list.seller[0].url != "" || list.seller[0].url !== null ? list.seller[0].url : '------': [],
                //                     if_reassigned : false,
                //                     commission    : list.for_commission,
                //                     rid           : list.id,
                //                     progress      : 90,
                //                     status        : list.status == "Recheck" ? "For Rework" : list.status,
                //                     platform      : list.platform,
                //                     platform_img  : this.$parent.getImage('platforms', list.platform || "Default"),
                //                     product_url   : list.url,
                //                     details       : list.listing_info.listing_title,
                //                     qflag_id      : list.qflag_id,
                //                     user_id       : list.user_id,
                //                     reassigned    : list.no_of_reassignment,
                //                     date          : moment(list.created_at).format('YYYY-MM-DD'),
                //                     last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                //                     claim_type    : list.claim_type,
                //                     payment_type  : list.payment_type,
                //                     owner         : list.verifier_owner != null
                //                         ? (list.verifier_owner.first_name != null ? list.verifier_owner.first_name.trim(): "")+" "+(list.verifier_owner.last_name != null ? list.verifier_owner.last_name.trim(): "")
                //                         : "",
                //                     qualifier     : list.qualifier,
                //                     case_number   : list.case_number,
                //                     error         : false,
                //                     info          : []//after fetching active_listing_info data will be save here
                //                 }
                //             }
                //         })
                //         this.lists = this.lists.filter(list=>{return list != null})
                //         this.lists_meta  = results.meta
                //         this.lists_links = results.links
                //         this.loaded      = true
                //         this.lid_status  = ""
                //         this.searching = false
                //         setTimeout(() => {
                //             document.querySelector('.a-input-group input').focus()
                //         })
                //         if(this.prev_route.query?.action == 'edit' && this.search_keywords != '') {
                //             this.$nextTick(() => {//Show modal when list is rendered
                //                 document.getElementById('modal-opener').click()
                //             })
                //         }
                //     })
                //     .catch(err=> {
                //         console.log(err)
                //         this.$parent.showSnackbar('Something went wrong. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                //     })

                /**
                 * New Code
                 * Deliverable #: 503
                 */

                if(this.access_level == 5) {
                    headers.headers.Where = JSON.stringify(conditions)
                    let endpoint = '/listings/main/team'
                    await axios.get(process.env.VUE_APP_URL+`${endpoint}?page_size=${this.page_size}&page=${this.current_page}`,headers)
                        .then(response=>{
                            let results        = response.data
                            this.lists = results.data.map(list=>{
                                    // console.log(list)
                                if(list.seller.length > 0 && list.url != null){
                                    return {
                                        product_img   : list.evidences.preview != null ? list.evidences.preview : list.evidences.Preview,
                                        seller_name   : list.seller[0] !== undefined ? list.seller[0].name != "" || list.seller[0].name !== null ? list.seller[0].name: '------': [],
                                        seller_url    : list.seller[0] !== undefined ? list.seller[0].url != "" || list.seller[0].url !== null ? list.seller[0].url : '------': [],
                                        if_reassigned : false,
                                        commission    : list.for_commission,
                                        rid           : list.id,
                                        progress      : 90,
                                        status        : list.status == "Recheck" ? "For Rework" : list.status,
                                        platform      : list.platform,
                                        platform_img  : this.$parent.getImage('platforms', list.platform || "Default"),
                                        product_url   : list.url,
                                        details       : list.listing_info.listing_title,
                                        qflag_id      : list.qflag_id,
                                        user_id       : list.user_id,
                                        reassigned    : list.no_of_reassignment,
                                        date          : moment(list.created_at).format('YYYY-MM-DD'),
                                        last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                                        claim_type    : list.claim_type,
                                        payment_type  : list.payment_type,
                                        owner         : list.verifier_owner != null
                                            ? (list.verifier_owner.first_name != null ? list.verifier_owner.first_name.trim(): "")+" "+(list.verifier_owner.last_name != null ? list.verifier_owner.last_name.trim(): "")
                                            : "",
                                        qualifier     : list.qualifier,
                                        case_number   : list.case_number,
                                        error         : false,
                                        info          : []//after fetching active_listing_info data will be save here
                                    }
                                }
                            })
                            this.lists = this.lists.filter(list=>{return list != null})
                            this.lists_meta  = results.meta
                            this.lists_links = results.links
                            this.loaded      = true
                            this.lid_status  = ""
                            this.searching = false
                            setTimeout(() => {
                                document.querySelector('.a-input-group input').focus()
                            })
                            if(this.prev_route.query?.action == 'edit' && this.search_keywords != '') {
                                this.$nextTick(() => {//Show modal when list is rendered
                                    document.getElementById('modal-opener').click()
                                })
                            }
                        })
                        .catch(err=> {
                            console.log(err)
                            this.$parent.showSnackbar('Something went wrong. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                        })
                } else {
                    let sort
                    if(this.sort_by == 0) {
                        this.sort_by = 'id'
                    }

                    if(this.order_by == 0 || this.order_by == 'desc') {
                        sort = `-${this.sort_by}`
                    } else {
                        sort = `${this.sort_by}`
                    }

                    let queries = {
                        include: ["campaign", "case", "claimType", "evidences", "info", "seller.accounts", "platform", "qualifier", "user", "qflag"],
                        includeFilter: {
                            evidences: {
                                type: "preview"
                            },
                            info: {
                                field: ["date_captured", "item_type", "listing_title"]
                            },
                        },
                        filter: conditions,
                        sort: sort,
                    }
                    if(this.$route.query.status.toLowerCase() == 'filed') {
                        queries.include.push('caseSeller')
                        queries.include = queries.include.filter(inc => inc != 'seller.accounts')
                    }
                    // console.log(queries)
                    let data = null
                    await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}&page_size=${this.page_size}&page=${this.current_page}`, headers).then(
                        response => {
                            if(response.status == 200) {
                                let results        = response.data
                                this.lists = results.data.map(list=>{
                                    if(list.seller_id !== null && list.url != null){
                                        // listing info mapping
                                        let info = {}
                                        list.info.forEach(i => {
                                            info[i.field] = i.value
                                        })
                                        list.listing_info = info

                                        // qualifier mapping
                                        if(list.qualifier !== null) {
                                            let qualifier = `${list.qualifier.first_name} ${list.qualifier.last_name}`
                                            list.qualifier = qualifier
                                        }

                                        const mainAccount = list.seller?.accounts?.find(account => +account?.id === +(list?.seller_account_id ?? null));
                                        const paymentType = mainAccount?.type ?? null;

                                        return {
                                            product_img   : list.evidences !== null && list.evidences.length > 0 ? list.evidences[0].file_name2 : '',
                                            seller_name   : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].name) : (list.seller !== undefined && list.seller !== null ? list.seller.name != "" || list.seller.name !== null ? list.seller.name: '------': null),
                                            seller_url    : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].url) : (list.seller !== undefined && list.seller !== null ? list.seller.url != "" || list.seller.url !== null ? list.seller.url : '------': null),
                                            if_reassigned : false,
                                            commission    : list.for_commission,
                                            rid           : list.id,
                                            progress      : 90,
                                            status        : this.$parent.getQFlag(list.qflag_id),
                                            platform      : list.platform !== null && list.platform && list.platform.name,
                                            platform_img  : this.$parent.getImage('platforms', list.platform !== null && list.platform && list.platform.name || "Default"),
                                            product_url   : list.url,
                                            details       : list.listing_info.listing_title,
                                            listing_info  : list.listing_info,
                                            qflag_id      : list.qflag_id,
                                            user_id       : list.user_id,
                                            reassigned    : list.no_of_reassignment,
                                            date          : moment(list.created_at).format('YYYY-MM-DD'),
                                            last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                                            claim_type    : list.claim_type !== null ? list.claim_type.claim_type : list.claim_type,
                                            payment_type  : paymentType, //list.payment_type,
                                            owner_id      : list.user ? list.user.id : 0,
                                            owner         : list.user != null
                                                ? (list.user.first_name != null ? list.user.first_name.trim(): "")+" "+(list.user.last_name != null ? list.user.last_name.trim(): "")
                                                : "",
                                            qualifier     : list.qualifier,
                                            qualifier_id  : list.qa_user_id,
                                            case_number   : list.case && list.case !== null && list.case.case_number,
                                            error         : false,
                                            info          : []//after fetching active_listing_info data will be save here
                                        }
                                    }
                                })
                                this.lists = this.lists.filter(list=>{return list != null})
                                // this.lists_meta  = results.meta
                                let results_copy = {}
                                let for_meta = ['last_page','current_page','per_page','total']
                                let for_meta_equivalent = {
                                    last_page: 'no_of_pages',
                                    current_page: 'page',
                                    per_page: 'page_size',
                                    total: 'total_no_of_items',
                                }
                                
                                Object.entries(results).forEach(r => {
                                    const [key, value] = r
                                    if(for_meta.includes(key))  {
                                        results_copy[for_meta_equivalent[key]] = value
                                    }
                                    // console.log(key,value)
                                    // console.log(key, for_meta_equivalent[index])
                                })
                                results_copy['items_in_this_page'] = results.data.length

                                this.lists_meta = results_copy
                                this.lists_links = results.links
                                this.loaded      = true
                                this.lid_status  = ""
                                this.searching = false
                                setTimeout(() => {
                                    document.querySelector('.a-input-group input').focus()
                                })
                                if(this.prev_route.query?.action == 'edit' && this.search_keywords != '') {
                                    this.$nextTick(() => {//Show modal when list is rendered
                                        document.getElementById('modal-opener').click()
                                    })
                                }
                            }
                        }
                    ).catch(error => {
                        console.log(error)
                        this.searching = false
                        this.$parent.hideBackdrop()
                        this.$parent.showSnackbar('Something went wrong. Please contact System Admin.', false, 'invalid', 'fas fa-times-circle')
                    })

                    if (this.keywords !== "") {
                        const sample = await this.searchByLID(this.keywords, true)
                        if (sample && sample[0] && sample[0].rid) {
                            this.lastEditedListing = sample[0]

                            await sleep(200)
                            await this.getListingInfo(this.keywords,false,true)
                            await sleep(500)
                            $('#listing-modal').modal('show')
                            setTimeout(() => $('body').addClass('modal-open'), 1000)
                        }
                    }
                }
                this.$parent.hideBackdrop()
                this.$store.dispatch('setKeywords', '')
            },
            async searchByLID(lid, returnData = false) {
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                // headers.headers.WHERE = JSON.stringify({id: lid})
                let conditions = {}
                conditions.id = lid
                conditions.campaign_id = this.$route.params.campaign_id

                const substatus = this.$route.query.substatus_id?.toString()?.split('_')?.[0] ?? this.$route.query.substatus_id

                conditions.substatus_id = (!this.subStatusesStore.canSeeSubStatuses || substatus == 0 || substatus == -1) ? undefined : substatus
                conditions.with_substatus_set = !this.subStatusesStore.canSeeSubStatuses || substatus == -1 ? undefined : substatus != 0

                let sort
                if(this.sort_by == 0) {
                    this.sort_by = 'id'
                }

                if(this.order_by == 0 || this.order_by == 'desc') {
                    sort = `-${this.sort_by}`
                } else {
                    sort = `${this.sort_by}`
                }

                let queries = {
                    include: ["campaign", "case", "claimType", "evidences", "info", "seller.accounts", "platform", "qualifier", "user", "qflag"],
                    includeFilter: {
                        evidences: {
                            type: "preview"
                        },
                        info: {
                            field: ["date_captured", "item_type", "listing_title"]
                        },
                    },
                    filter: conditions,
                    sort: sort,
                }
                if(this.$route.query.status.toLowerCase() == 'filed') {
                    queries.include.push('caseSeller')
                    queries.include = queries.include.filter(inc => inc != 'seller.accounts')
                }
                if(!returnData) {
                    await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}&page_size=1&page=1`,headers)
                        .then(response=>{
                            let results        = response.data
                            console.log(results.data)
                            this.lid_status = results.data.length > 0 ? this.$parent.getQFlag(results.data[0].qflag_id) : this.$route.query.status
                            this.searching = false
                            this.$parent.hideBackdrop()
                        })
                } else {
                    let results = []
                    await axios.get(`${process.env.VUE_APP_URL}/api/v2/listings?${qs.stringify(queries, { encodeValuesOnly: true })}&page_size=1&page=1`,headers)
                    .then(response => {
                            results = response.data
                            this.lid_status = results.data.length > 0 ? this.$parent.getQFlag(results.data[0].qflag_id) : this.$route.query.status
                            this.searching = false
                        })
                    return results.data.map(list => {
                        // listing info mapping
                        let info = {}
                        list.info.forEach(i => {
                            info[i.field] = i.value
                        })
                        list.listing_info = info

                        // qualifier mapping
                        if(list.qualifier !== null) {
                            let qualifier = `${list.qualifier.first_name} ${list.qualifier.last_name}`
                            list.qualifier = qualifier
                        }

                        return {
                            product_img   : list.evidences !== null && list.evidences.length > 0 ? list.evidences[0].file_name2 : '',
                            seller_name   : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].name) : (list.seller !== undefined && list.seller !== null ? list.seller.name != "" || list.seller.name !== null ? list.seller.name: '------': null),
                            seller_url    : this.$route.query.status.toLowerCase() == 'filed' ? (list.case_seller && list.case_seller[0] && list.case_seller[0].url) : (list.seller !== undefined && list.seller !== null ? list.seller.url != "" || list.seller.url !== null ? list.seller.url : '------': null),
                            if_reassigned : false,
                            commission    : list.for_commission,
                            rid           : list.id,
                            progress      : 90,
                            status        : this.$parent.getQFlag(list.qflag_id),
                            platform      : list.platform !== null && list.platform && list.platform.name,
                            platform_img  : this.$parent.getImage('platforms', list.platform !== null && list.platform && list.platform.name || "Default"),
                            product_url   : list.url,
                            details       : list.listing_info.listing_title,
                            listing_info  : list.listing_info,
                            qflag_id      : list.qflag_id,
                            user_id       : list.user_id,
                            reassigned    : list.no_of_reassignment,
                            date          : moment(list.created_at).format('YYYY-MM-DD'),
                            last_rework   : moment(list.last_rework).format('YYYY-MM-DD'),
                            claim_type    : list.claim_type !== null ? list.claim_type.claim_type : list.claim_type,
                            payment_type  : list.payment_type,
                            owner         : list.user != null
                                ? (list.user.first_name != null ? list.user.first_name.trim(): "")+" "+(list.user.last_name != null ? list.user.last_name.trim(): "")
                                : "",
                            qualifier     : list.qualifier,
                            case_number   : list.case && list.case !== null && list.case.case_number,
                            error         : false,
                            info          : []//after fetching active_listing_info data will be save here
                        }
                    })
                }
            },
            preview(){
                let evidences = this.active_listing_info[0].evidences
                for(let key in evidences){
                    if(key.toLowerCase().includes("preview")){
                        var preview = evidences[key]
                    }
                }
                return preview != null && preview.trim() != ''
                    ? `${process.env.VUE_APP_URL}/files/${preview}`
                    : this.themeListingPreview
            },
            async getListingInfo(listing_id, refresh = false, use_lastEditedListing = false) {
                await this.resetData()
                //Reset selected reasons when modal is reopen
                this.selected_predefined_reasons    = []
                this.selected_predefined_sub_reasons= []
                this.other_reason                   = ""
                this.selected_other_reason          = []
                this.selected_comm                  = ""
                this.already_worked_lid             = ""
								
                this.active_listing_id  = listing_id
                this.change_status      = false
                this.show_required_list = false
                this.listingNote.status = false
                this.listingNote.value = ""
                this.listingNote.data = []
                this.listingNote.is_loading = false
                this.resetReassignData()
                //Check if listing info of listing_id is empty
                
                let has_listing_info = this.lists.find(list => list.rid == listing_id)?.info.length
                if(!has_listing_info || refresh) {
                    let active_listing = await this.getActiveListingInfo(listing_id)


                    if(active_listing.length) {
                        if(!use_lastEditedListing) {
                            // reset the data so that it will not be used in computed active_listing_info
                            this.lastEditedListing = []
                            //find the ar-list of listing_id
                            this.lists = this.lists.map(list => {
                                if(list.rid == listing_id) 
                                    list.info = active_listing
                                
                                return list
                            })
                        } else {
                            this.lastEditedListing.info = active_listing
                        }
                    }
                }
                this.show_predefined_reasons = false
                this.clicked_statuses        = []

                if (this.isQAAccount) {
                    const list = this.active_listing_info[0]
                    // this.individualReassign.user.selected = { access_level: 2, label: list.qa_owner, value: list.qa_user_id }
                    this.individualReassign.user.selected = { label: 'Select User', value: 0 }
                }

                setTimeout(() => {
                    $('.researcher-name').hide()
                }, 100)
            },
            refreshData(listing_id){
                this.getListingInfo(listing_id, true)
            },
            showReassignModal() {
                $('#reassign-modal').modal('show')
                if(!this.individualReassign_hasError)
                    this.resetReassignData()
                if(this.isQAAccount){
                    this.individualReassign.user_type.selected = 0;
                    if(this.$route.query.status.toLowerCase() == 'qualified')
                        this.batchReassign.user_type.selected = 1
                    else
                        this.batchReassign.user_type.selected = 0
                    this.isOpen_batchReassign = true
                }
            },
            resetReassignData() {
                this.reassign_campaign = 0
                this.show_predefined_reasons = false
                this.selected_campaign_user = 0
                this.reassignStatuses.selected = 0
                this.individualReassign.status.selected = 0
                this.individualReassign.user.selected = 0

                this.individualReassign.user_type.selected = 0
                this.individualReassign.campaign.selected = 0
                this.isOpen_batchReassign = false

                if(this.$route.query.status.toLowerCase() == 'qualified')
                    this.batchReassign.user_type.selected = 1
                else
                    this.batchReassign.user_type.selected = 0

                $('#reassign-user .input-icon')?.click()
                $('#reassign-campaign .input-icon')?.click()
                $('#reassign-status-select .input-icon')?.click()
                $('#individualReassign-user .input-icon')?.click()
                $('#individualReassign-status .input-icon')?.click()
                this.reassignStatuses.results = []
                
                this.individualReassign.show = false
                this.reassignmentFailed_meta_data = []
                this.hasExisting_listings = false
            },
            resetIndividualReassignment() {
                $('#listing-modal').modal('show')
                $('#reassign-modal').modal('hide')
                this.reassignStatuses.results = []
                this.reassignmentFailed_meta_data = []
                this.hasExisting_listings = false
                this.individualReassign_hasError = false

                this.individualReassign.user_type.selected = 0
                this.individualReassign.campaign.selected = 0
                this.isOpen_batchReassign = false
                if(this.$route.query.status.toLowerCase() == 'qualified')
                    this.batchReassign.user_type.selected = 1
                else
                    this.batchReassign.user_type.selected = 0

            },
            showIndividualReassign() {
                this.individualReassign.show = !this.individualReassign.show
                this.individualReassign.status.selected = 0
                this.individualReassign.user.selected = 0

                this.individualReassign.user_type.selected = 0
                this.individualReassign.campaign.selected = 0
                
                $('#individualReassign-status .input-icon')?.click()
                $('#individualReassign-user .input-icon')?.click()
            },
            editListing(){
                var lid         = this.active_listing_id
                var campaign_id = this.$route.params.campaign_id
                
                this.closeModal()
                this.$router.push({
                    'name':'listinginfo',
                    'params':{
                        campaign_id
                    },
                    query: {
                        'action'   : "edit",
                        'lid'      : lid,
                        'qflag_id' : this.qflag
                    }
                })
                // location.reload()
            },
            navigate(page) {
                this.$router.push({name: page})
            },
            updateListingPage() {
                var page_input = document.getElementById('pagination_input')
                if (page_input.value > this.last_page) page_input.value = this.last_page
                else if (page_input.value < 1) page_input.value = 1
                this.updatePage(page_input.value)
            },
            /*
            * Function to check if the value or the value entered is a number, dot, or comma, if not, then no no no. hahaha - Dev: Marvin 12/14/2019
            */
            isNumber(e) {
                var event = e || window.event;
                var keyCode = event.keyCode || event.which;            
                var key = String.fromCharCode(keyCode);
                var regex = /^[0-9.,\b]+$/;  
                if (key.length == 0) return;
  
                if(keyCode == 188 || keyCode == 190) return;
                else {
                    if (!regex.test(key)) {
                        event.returnValue = false;                
                        if (event.preventDefault) event.preventDefault();
                    }
                }    
            },
            resetPlatforms() {
                let checked = document.querySelectorAll('input[name=platforms]:checked')
                var platforms = []
                for (var i = 0; i < checked.length; i++) {
                    if (checked[i].value == 0) continue;
                    platforms.push(checked[i].id)
                }
            },
            ucFirst(string) {
                if (string == null || string == "")
                    return ""
                if (string.length == 1)
                    return string.charAt(0).toUpperCase()
                    
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            name: function(first,last){
                return this.ucFirst(first)+" "+this.ucFirst(last)
            },
            isEmpty: function(value){
                if(typeof value === 'undefined' || value === null){
                    return 'Not Set'
                }
                return value
            },
            async getChangeStatus(id, status, isReassignment = false) { //use in changing status in listing modal
                this.subStatusesStore.currentSelectedSubStatus = null

                //Reset reworkLevel and reworkCommission
                this.reworkCommission = null;
                this.reworkLevel      = null;
                this.hasAcknowledged  = false;
                this.reworkTimeline   = false;

                const resetStatus = this.selected_change_status == id

                await this.resetSelectedStatus();
                this.resetCommission();

                this.fetching_reasons       = false;
                this.lid_to_change          = {};

                this.selected_change_status = resetStatus ? 0 : id;
                this.predefined_status      = status;
                this.subStatusesStore.currentSelectedSubStatus = -1

                $('.a-modal-status-history-con .sub-statuses-container .btn.sub-status').removeClass('active')
                
                setTimeout(() => {
                    $('.a-modal-status-history-con .status-change-options .btn.active').siblings('.btn-group-toggle').find('.sub-statuses-container .btn').first().addClass('active')
                })

                let clicked_statuses     = this.clicked_statuses; //To know the current and previous clicked status
                let allow_get_predefined = true;

                this.resetReasonsSelectionStore();

                /**
                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                 * Deliverable Number: 454
                 */

                if (!isReassignment) {
                    if (status === 'Researched') {
                        allow_get_predefined = !this.hasMissingRequiredFields;
                    } else {
                        this.show_required_list = false;
                    }
                }

                if (!allow_get_predefined) {
                    this.show_predefined_reasons = false;
                }

                if (allow_get_predefined) {
                    if (!clicked_statuses.length) {
                        //Showing predefined reasons when changing status
                        this.show_predefined_reasons = !this.show_predefined_reasons;
                    } else {
                        //Showing and hiding predefined reasons when same status is Click
                        this.show_predefined_reasons         = (clicked_statuses[clicked_statuses.length - 1] === id) ? !this.show_predefined_reasons : true;
                        this.selected_predefined_reasons     = [];
                        this.selected_predefined_sub_reasons = [];
                        this.selected_other_reason           = [];
                        this.other_reason                    = "";
                    }
                }

                this.clicked_statuses.push(id);

                // Check if remove listing
                if (this.selected_change_status === -99) {
                    this.remove_listing.comment = ''
                    return
                }

                if (this.show_predefined_reasons) {
                    this.selected_predefined_reasons = [];
                    this.other_reason                = '';

                    this.getPredefinedReasons(+id);
                }
            },
            async getPredefinedReasons(id, additionalFilter = false) {
                this.reworkLevel = null
                this.reworkCommission = null

                // const isQualifiedOrHasPotential = [3, 98].includes(+id);
                const isQualifiedOrHasPotential = ListingStatus.Qualified === +id || ListingStatus.HasPotential === +id;

                if (isQualifiedOrHasPotential) {
                    setTimeout(() => document.querySelector('.retain-commission').classList.add('active') , 100);
                }

                await this.$store.dispatch('resetPredefinedReasonState');

                this.batch_change_status = id;

                const qflag = this.$parent.getQFlag(id);

                /* const forReworkId = 2
                if(this.all_predefined_reasons[qflag]?.length > 0 && id !== forReworkId) { */

                if (this.all_predefined_reasons[qflag]?.length > 0) {
                    this.predefined_reasons = this.all_predefined_reasons[qflag];
                    this.fetching_reasons   = true;

                    return
                }

                this.is_loading = true;

                const queries = {
                    filter: {
                        qflag_id: id
                    },
                    include: "subReasons,subReasonsCount",
                    page_size: 1000
                };

                if (additionalFilter) queries.filter.has_listing = this.$route.params.campaign_id;

                const { status, data } = await this.$http.get(`/api/v2/reasons?${qs.stringify(queries, { encode: false })}`, this.$session.get('ver_headers'));

                this.is_loading = false;

                if (status !== 200) {
                    this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-times-circle');

                    return;
                }

                const reasons = data.data;
                const role = this.user?.role ?? this.user.access_level;

                const isReasonRestricted = ({ restricted_roles }) => Array.isArray(restricted_roles) && restricted_roles.includes(role);

                this.predefined_reasons = reasons
                    .filter(reason => reason.status && !isReasonRestricted(reason))
                    .map(item => {
                        const { id, type, severity_level, sub_reasons, reason, restricted_roles, is_for_clients, status } = item;

                        const getSubReasons = () => sub_reasons
                            .filter(item => item.status && !isReasonRestricted(item))
                            .map(item => {
                                const { id, severity_level, text: reason, restricted_roles, status: status, is_for_clients } = item;
                                return { id, severity_level, reason, restricted_roles, status, is_for_clients };
                            });

                        const mainReason = sub_reasons.length ? { [reason]: getSubReasons() } : reason;

                        const WITH_SUBREASONS = 2;

                        // if (+type === WITH_SUBREASONS && mainReason[reason].length === 0) {
                        if (+type === WITH_SUBREASONS && sub_reasons?.length === 0) {
                            return null;
                        }

                        return { id, type, severity_level, restricted_roles, reason: mainReason, is_for_clients, status };
                    })
                    .filter(item => item !== null)

                this.all_predefined_reasons[qflag]       = this.predefined_reasons;
                this.filter_status_change_reason.options = reasons.map(reason => ({ label: reason.reason, value: reason.id }));
                this.fetching_reasons                    = true;

                return;
            },
            getCommissionValue(comm) {
                this.selected_comm           = comm
                this.selected_change_status  = 0
                this.show_predefined_reasons = false
            },


            /**
             * @param {Array<number>} lids
             * @param {number} status
             * @param {string} initiator
             * @param {string} modal
             * @returns {boolean} Has potential whitelist sellers
             */
            async checkPotentialWhitelists(lids, status, initiator = 'changeStatus', modal = 'listing-modal') {
                if(this.isCheckingPotentials) return
                this.isCheckingPotentials = true
                
                this.onModalClose = () => $(`#${modal}`).modal('show')
                const sellers = this.lists.filter(({rid}) => lids.includes(rid))
                    .map(({seller_name, seller_url}) => ({name: seller_name, url: seller_url}))
                const {success, data, message} = await this.$store.dispatch('checkPotentialWhitelists', sellers)
                if(!success) {
                    this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')
                    return false
                }
                if(data.length === 0) return false
                await this.$store.dispatch('setPotentialWhitelists', data)
                /* $(`#${modal}`).hide() */
                $(`#${modal}`).modal('hide')
                $('#potential-whitelists').modal('show')
                this.acknowledgeWhitelists = async () => {
                    const acknowledgement = {feature: 'status change', status: 1, notes: ''}
                    await this.$store.dispatch('acknowledgeWhitelist', acknowledgement)
                    $('#potential-whitelists').modal('hide')
                    $(`#${modal}`).modal('show')
                    this.hasAcknowledged = true

                    initiator === 'changeStatus'
                        ? this.changeStatus(lids[0], status) 
                        : initiator === 'batchChangeStatus'
                            ? this.batchChangeStatus(lids, status)
                            : initiator === 'newReassignListing'
                                ? this.doNewReassign(true, { rid: lids[0], qflag_id: status })
                                : initiator === 'batchNewReassignListing'
                                    ? this.doNewReassign()
                                    : null

                }

                return true;
            },
            async changeStatus(lid, current_qflag) {
                const qflag = this.selected_change_status;
                const comment = this.modal_comment.trim();

                this.already_worked_lid = '';
                this.is_loading         = true;

                if(qflag === 0 && this.selected_comm !== '') {
                    if(comment !== '') {
                        const payload    = {comment}
                        const commission = this.selected_comm ? 'yes' : (this.selected_comm == 0 ? 'no' : null)
                        const headers    = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                        headers.headers['Campaign-id'] = this.$route.params.campaign_id
                        this.$parent.showSnackbar('Updating commission...', true)
                        this.$http.patch(`/listings/${lid}/for_commission/${commission}`, payload, headers)
                            .then(response => {
                                if(+response.data.meta.code === 200) {
                                    this.modal_comment = ''
                                    this.selected_change_status = 0
                                    this.selected_comm = ''
                                    this.$parent.showSnackbar('Successfully updated commission', false, 'success', 'fas fa-check-circle')
                                    this.refreshData(this.active_listing_id)
                                }
                            })
                            .catch (err => {
                                console.error(err)
                                this.$parent.showSnackbar(' Something went wrong. Please contact system admin.', false, 'success', 'fas fa-check-circle')
                            })
                            .finally(() => this.is_loading = false)
                    } else {
                        this.is_loading = false
                        this.$parent.showSnackbar('Please add comment when adding or removing commissions', false, 'invalid', 'fas fa-times-circle')
                    }
                } else if(comment != "" && qflag == 0) {
                    let config             = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                    let post               = { 'field': 'comment', 'value': comment, 'listing_id': lid }
                    config.headers.listing = JSON.stringify(lid)
                    config.headers['Campaign-id'] = this.$route.params.campaign_id
                    this.$parent.showSnackbar("Adding comment...",true)
                    this.$http.post('/history', post, config)
                        .then(response => {
                            if(response.data.meta.code == 200) {
                                this.modal_comment = ""
                                this.$parent.showSnackbar("Successfully added comment.", false, 'success', 'fas fa-check-circle')
                            }
                        })
                        .catch(() => this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-info-circle') )
                        .finally(() =>this.is_loading = false )
                }else if(lid != "" && ([41, 4].includes(qflag) || (![41, 4].includes(qflag) && this.show_predefined_reasons)) && qflag != 0) {
                    let headers = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                    headers.headers['Campaign-id'] = this.$route.params.campaign_id
                    let patch   = {}
                    let allow_research = true

                    if (qflag === ListingStatus.Researched) {
                        allow_research = !this.hasMissingRequiredFields;
                    }

                    if(allow_research) {
                        let payload = {"comment":"","listing_ids":lid}
                        let predefined_data = {
                            "main_reasons"   :this.selected_predefined_reasons,
                            "sub_reasons"    :this.selected_predefined_sub_reasons,
                            "listing_id"     :lid,
                            "status"         :this.selected_change_status,
                            "other_reason_id":this.selected_other_reason,
                            "other_reason"   :this.other_reason,
                        }
                        if (qflag == 3 || qflag == 98 || qflag == 32) {
                            if (this.selected_comm !== '') {
                                payload.for_commission = this.selected_comm == 1 ? 'yes' : (this.selected_comm == 0 ? 'no' : null)
                            } else {
                                this.is_loading = false
                                this.$parent.showSnackbar("Please select whether with or without commission", false, 'invalid', 'fas fa-info-circle')
                                return
                            }
                        }
                        
                        // if(predefined_data['main_reasons'].length || predefined_data['sub_reasons'].length || predefined_data['other_reason_id'].length) {
                        if(predefined_data.main_reasons.length || predefined_data.sub_reasons.length || predefined_data.other_reason_id.length || [41, 4].includes(qflag)) {
                            // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                            if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                                this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                                this.is_loading = false
                            }
                            else if(this.confirmedLFCNote == false && this.checkIndividualLFC && this.selected_change_status == 99) {
                                $('#lfc-confirm-invalidation').modal('show')
                                this.is_loading = false
                            }
                            else {
                                if(+qflag === 2) {
                                    if(this.reworkLevel === null) {
                                        this.is_loading = false
                                        return this.$parent.showSnackbar('Please select Severity Level', false, 'invalid', 'fas fa-info-circle')
                                    }
                                    if(this.reworkCommission === null){
                                        this.is_loading = false
                                        return this.$parent.showSnackbar('Please select Rework Commission', false, 'invalid', 'fas fa-info-circle')
                                    }
                                }
                                // this.$parent.showSnackbar('Updating status...')
                                const deduplicatedListings = await this.checkForDuplicateListing(''+payload.listing_ids, qflag, () => this.changeStatus(lid, current_qflag))
                                if(deduplicatedListings === '' || deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                                    this.is_loading = false
                                    return
                                    // return this.$parent.hideSnackbar()
                                }
                                const exceptedStatus = [99, 33]
                                if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag)) {
                                    const hasPotentials       = await this.checkPotentialWhitelists([lid], current_qflag)
                                    this.isCheckingPotentials = false
                                    this.is_loading           = false
                                    if(hasPotentials) return
                                }

                                if (this.showRestrictedReasons()) {
                                    return;
                                }

                                this.$parent.showSnackbar('Updating status...')
                                //Always set comment to empty since there is predefined-reason to include
                                payload.comment           = ''
                                payload.reasons           = this.setPredefinedReasons()

                                if (this.subStatuses[this.$parent.getQFlag(qflag)]?.filter(s => s.id)?.length && this.subStatusesStore.currentSelectedSubStatus == null) {
                                    this.is_loading = false
                                    return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
                                }

                                payload.target_substatus_id = this.subStatusesStore.currentSelectedSubStatus ?? -1
                                
                                if(![3, 98].includes(+qflag)) {
                                    payload.rework_level      = this.reworkLevel
                                    payload.rework_commission = this.reworkCommission
                                }
                                this.$http.patch(`/listings/multiple/statuschange/${ qflag }`, payload, headers)
                                    .then(response => {
                                        this.setRelatedCampaignDuplicateBypass(false)

                                        let result = response.data
                                        //Alert user when seller is whitelisted
                                        if(result.meta.whitelisted) {
                                            this.detected_whitelist = {
                                                name     : result.data.seller_name,
                                                url      : result.data.seller_url,
                                                site_id  : result.data.site_id,
                                                platforms: result.data.platforms.join(', '),
                                                campaigns: result.data.campaigns.join(', ')
                                            }
                                            $('#listing-modal').modal('hide')
                                            $('#whitelist-info-modal').modal('show')
                                            return this.$parent.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
                                        }

                                        if(result.meta.code == 200) {
                                            this.setPredefinedReasons(predefined_data)
                                            // document.getElementById("back-to-product").click()
                                            //If current qflag is for rework (qflag_id = 2), reduce count of active campaign in sidebar
                                            // current_qflag == 2 && (this.reduceSidebarCampaignCount(this.campaign.name))

                                            /**
                                             * Deliverable: Hide all For Rework/Reworked elements across all GUI
                                             * Deliverable Number: 454
                                             */
                                            // if(current_qflag == 2) {
                                            //     this.reduceSidebarCampaignCount(this.campaign.name)
                                            // }

                                            // #region -- requested by Melony Loquinario to be returned (pop up listing summary after status change) - Marvin 10/23/2023
                                            if (this.access_level == 2) this.search_keywords = `${lid}`
                                            // #endregion
                                            
                                            // #region -- increase current researched from the weekly quota tracker
                                            if (qflag == 1)
                                                this.increaseUserCurrentWeeklyQuota()
                                            // #endregion


                                            this.closeModal()
                                            this.getListing()
                                            /**
                                             * Deliverable: Remove listing id from filter after edit listing
                                             * Deliverable Number: 465
                                             */
                                            // setTimeout(async () => {
                                            //         await this.$router.push({
                                            //             name: 'listing',
                                            //             query: {
                                            //                 status   : this.$parent.getQFlag(qflag),
                                            //                 platform : this.$route.query.platform,
                                            //                 /*keywords : lid*/
                                            //             }
                                            //         })
                                            //         // this.search_keywords = lid
                                            //         // to call and reinitialize the statuses in the filter drawer - Marvin 01/30/20
                                            //         let filter_drawer = this.$parent.$children
                                            //             .filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                                            //         filter_drawer.dem_statuses = []
                                            //         filter_drawer.initializeStatuses()
                                            //         this.$parent.showSnackbar('Successfully change status.', false, 'success', 'fas fa-check-circle')
                                            // }, 500);
                                            // let filter_drawer = this.$parent.$children
                                            //     .filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                                            // filter_drawer.dem_statuses = []
                                            // filter_drawer.initializeStatuses()
                                            this.refreshVer_statuses()
                                            this.$parent.showSnackbar('Successfully change status.', false, 'success', 'fas fa-check-circle')
                                            if(this.access_level == 1) {
                                                this.$store.dispatch('setResearchedTracker', true)
                                                this.$store.dispatch('setWaitingTracker', false)
                                                setTimeout(() => {
                                                    this.$store.dispatch('setWaitingTracker', true)
                                                }, 5000)
                                            }
                                            this.setResearchTrackerLoad()
                                            this.currentAssignmentCopy.listing_count += 1
                                            if (this.currentAssignmentCopy?.listing_count == this.currentAssignmentCopy?.target) {
                                                this.showCongratsMsg()
                                            }
                                            setTimeout(() => {
                                                this.getUserAssignments()
                                            }, 4000)
                                        }
                                    })
                                    .catch(err => {
                                        const duplicate_logs = err?.response?.data?.meta?.duplicate_logs
                                        if (duplicate_logs) { 
                                            this.setRelatedCampaignDuplicateContinueFunction(() => this.changeStatus(lid, current_qflag))
                                            this.setRelatedCampaignDuplicates(duplicate_logs)
                                            this.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                                            return
                                        }
                                        if (err?.response?.data?.data) {
                                            console.error(err);
                                            this.already_worked_lid = err.response.data.data[0].duplicate.id
                                            document.getElementById('already-worked-span').scrollIntoView()
                                            let message = err.response.data.meta.message
                                            this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-check-circle')
                                        }
                                        else if (err?.response?.data?.meta?.message) {
                                            this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-check-circle')
                                        }
                                    })
                                    .finally(() => this.is_loading = false)
                            }
                        }else{
                            this.is_loading = false
                            this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                        }
                    // eslint-disable-next-line no-unreachable
                    }else{
                        this.is_loading = false
                        this.$parent.showSnackbar("Please fill all the required information before tagging this as researched.", false, "invalid", "fas fa-times-circle")
                    }
                // eslint-disable-next-line no-unreachable
                } else {
                    this.is_loading = false;

                    if (qflag === ListingStatus.Researched) {
                        if (this.hasMissingRequiredFields) {
                            this.$parent.showSnackbar('Please fill all the required information before tagging this as researched.', false, 'invalid', 'fas fa-times-circle')
                            return;
                        }
                    }

                    this.$parent.showSnackbar('Please select status and add comment', false, 'invalid', 'fas fa-times-circle');
                }

                // trigger notification to users
                globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: this.active_li.user_id, qa_user_id: this.active_li.qa_user_id, } });

                // Added trigger for checking if there are Clients from Listings 
                
                let trigger_payload = {
                    label: 'isTriggerOn_verGUIListingStatusChange',
                    value: true,
                }
                await this.$store.dispatch('setTrigger', trigger_payload)
            },
            setPredefinedReasons() {
                const reasons     = this.$store.state.checked_reasons;
                const reasonsInfo = this.$store.state.checked_subreasons;
                const otherReason = this.$store.state.checked_other_reason[0] ?? null;
                const comment     = this.$store.state.other_reason_comment;

                let selectedReasons = [];

                if (reasons.length) {
                    const tempReasons = reasons.map(reason => ({ id: reason.id, reasons_info: [], other_reason: '' }));
                    selectedReasons = [ ...selectedReasons, ...tempReasons ];
                }

                if (reasonsInfo.length) {
                    const reasonIds = [ ...new Set(reasonsInfo.map(({ reason_id }) => reason_id)) ];

                    for (const id of reasonIds) {
                        const filteredReasonsInfo = reasonsInfo.filter(ri => ri.reason_id === id).map(ri => ri.id);
                        const tempReasonsInfo     = { id, reasons_info: filteredReasonsInfo, other_reason: '' };

                        selectedReasons = [ ...selectedReasons, tempReasonsInfo ];
                    }
                }

                if (otherReason !== null) {
                    const tempOther = { id: otherReason, reasons_info: [], other_reason: comment };
                    selectedReasons = [ ...selectedReasons, tempOther ];
                }

                return selectedReasons;

                /* let reasons = [];

                if (this.selected_predefined_reasons.length !== 0) {
                    for(const { id } of this.selected_predefined_reasons) {
                        reasons = [ ...reasons, { id, reasons_info: [], other_reason: '' } ];
                    }
                }

                const selectedSubReasons = this.selected_predefined_sub_reasons;

                if (selectedSubReasons.length !== 0) {
                    const reasonIds = [...new Set(selectedSubReasons.map(m => m.reason_id))]
                    for(const id of reasonIds) {
                        const tempIds = selectedSubReasons
                            .filter(f => f.reason_id === id)
                            .map(m => m.id);

                        reasons = [...reasons, { id, reasons_info: tempIds, other_reason: ''} ];
                    }
                }

                if (this.selected_other_reason.length !== 0) {
                    reasons = [...reasons, {
                        id          : this.selected_other_reason[0],
                        reasons_info: [],
                        other_reason: this.other_reason
                    }];
                }

                return reasons; */
            },
            switchHistoryAndStatus(bool){
               /*  if([1,5].includes(this.access_level)){
                    let qflag_id = this.active_listing_info[0].qflag_id
                    if([4, 2, 41].includes(+qflag_id)){
                        this.change_status = bool
                    }
                    this.selected_change_status = 0
                    this.selected_comm          = ''
                }else{
                    this.change_status          = bool
                    this.selected_change_status = 0
                    this.selected_comm          = ''
                } */


                this.change_status = bool;
                this.selected_change_status = 0;
                this.selected_comm          = '';

                document.querySelector('.modal.show').scrollTo(0, 0)
            },
            modalCheckoutUploadDragEnter(index) {
                let current = $('.upload-modal--box').eq(index)
                $(current).addClass('dragHover')
                $(current).children().filter("div.icon").children().filter("label.d-block").text("Drag 'n drop file here")
            },
            modalCheckoutUploadDragLeave(index) {
                let current = $('.upload-modal--box').eq(index)
                $(current).removeClass('dragHover')
                $(current).children().filter("div.icon").children().filter("label.d-block").text('Checkout')
            },
            async uploadModalCheckoutFile(event, lid) {
                const type      = 'checkout'
                const token     = JSON.parse(localStorage.getItem('ver')).token
                let data        = new FormData()
                const config = {headers: {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${token}`,'Gui': 'Verification'}}
                config.headers['Campaign-id'] = this.$route.params.campaign_id
                data.append('file', event.target.files[0])
                data.append('name', `${lid}/${type}`)
                if(type === '' || +type === 0) {
                    return this.$parent.showSnackbar('Please select screenshot type', false, 'invalid', 'fas fa-times-circle')
                }
                const limit_size = 30 * 1024 * 1024
                if(event.target.files[0].size > limit_size || type === 'Merged') {
                    return this.$parent.showSnackbar('Evidence size exceeds to 30mb. Please optimize the file.', false, 'invalid', 'fas fa-times-circle')
                }
                const extension = event.target.files[0].name.split('.').pop().toLowerCase()//For PDFs
                if(event.target.files[0].type !== 'application/pdf' && !['jpeg', 'jpg', 'png'].includes(extension)) {
                    return this.$parent.showSnackbar('Please upload PDF or .png/.jpeg type evidence.', false, 'invalid', 'fas fa-times-circle')
                }
                this.$parent.showSnackbar('Uploading evidence...', true)
                await this.$http.post('/files', data, config)
                    .then(response => {
                        const results    = response.data
                        const image_type = results.data.filename.includes('/') 
                            ? results.data.filename.split('/')[1].split('.')[0] 
                            : results.data.filename.split('.')[0]
                        // this.uploaded_evidences = this.uploaded_evidences.filter(evidence => evidence.type !== type)
                        setTimeout(_ => {
                            const image = `${lid}/${results.data.filename}`
                            const href = `${process.env.VUE_APP_URL}/files/${image}`
                            const src = `/static/svg/PDF Default.svg`
                            this.uploaded_evidences.push({href, type, src, image})
                        }, 500);
                        this.$parent.showSnackbar('Successfully uploaded evidence', false, 'success', 'fas fa-check-circle')
                        // document.getElementById('upload-evidence').value = ''
                    })
                    .catch(err => {
                        console.error(err)
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin.',false,'invalid','fas fa-times-circle')
                    })
                // document.getElementById('upload-evidence').value = ''
            },
            removeEvidence(lid, filename) {
                const config                  = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                config.headers['Campaign-id'] = this.$route.params.campaign_id
                
                filename = filename.split('/')[filename.split('/').length - 1]
                this.$http.delete(`/files/${lid}/${filename.replace('| Missing').trim()}`, config)
                    .then(response => {
                        const results = response.data
                        if(+results.meta.code === 200) {
                            this.$parent.showSnackbar('Successfully remove evidence', false, 'success', 'fas fa-check-circle')
                            this.uploaded_evidences = this.uploaded_evidences.filter(evidence => evidence.type !== 'checkout')
                            // this.closeModal()
                        }
                    })
                    .catch(err => {
                        console.error(err)
                        this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-times-circle')
                    })
            },
            getUserCampaigns() {
                let campaign_id      = this.$route.params.campaign_id
                let config           = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                let id               = JSON.parse(localStorage.getItem('ver')).id
                config.headers.Where = JSON.stringify({access_level: [1,5]})
                const endpoint = this.isCLAccount ? '/users/team/members' : `/users/in/campaign/${campaign_id}?page_size=100`
                // axios.get(process.env.VUE_APP_URL+`/users/in/campaign/${campaign_id}?page_size=100`,config)
                this.$http.get(endpoint, config)
                    .then(response => {
                        if(+response.data.meta.code === 200){
                            this.campaign_users = response.data.data
                                // .filter(user => user.id != id)
                                .map(user => ({
                                    'value': user.id,
                                    'label': user.first_name +' '+ (user?.last_name ?? ''),
                                    'access_level': user.access_level
                                }))
                        }
                    })
                    .catch(err => this.$parent.showSnackbar(err.response.data.meta.message + 'Please contact system admin.', false, 'invalid', 'fas fa-times-circle') )
            },
            getResearchers(){
                let header = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                header.headers.where = JSON.stringify({'access_level': [1,2,5,7]})
                // axios.get(process.env.VUE_APP_URL+`/users/multi?page_size=1000`, header)
                this.$http.get('/users/multi?page_size=1000', header)
                    .then(response => {
                        let results = response.data
                        if(+results.meta.code === 200) {
                            this.campaign_users = results.data
                                .map(researcher => ({
                                    'access_level': researcher.access_level,
                                    'value': researcher.id,
                                    'label': researcher.first_name + ' ' + (researcher.last_name != null ? researcher.last_name : ''),
                                }))
                        }
                    })
                    .catch(err => this.$parent.showSnackbar(err.response.data.meta + 'Please contact system admin.', false, 'invalid', 'fas fa-times-circle') )
            },
            getSelectListing(rid){
                this.selected_predefined_reasons = []
                this.selected_predefined_sub_reasons = []
                this.selected_other_reason = []
                if(!this.selected_listings.includes(rid)){
                    this.selected_listings.push(rid)
                    document.getElementById(rid).parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    this.multipleCheckForInvalidLFC()
                }else{
                    this.selected_listings = this.selected_listings.filter(list=>{return list != rid})
                    document.getElementById(rid).parentNode.parentNode.parentNode.parentNode.classList.remove('checked')
                    this.multipleCheckForInvalidLFC()
                }
                this.selected_comm = ''
            },
            async getRoles() {
                const { data } = await this.$http.get('roles/multi?page_size=1000', this.$session.get('ver_headers'))

                this.filter_roles.options = data.data.map(d => ({ label: d.name, value: d.id}))
            },
            async newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual = false) {
                const listing_ids = isIndividual ? [lid] : this.selected_listings

                // Check if user is QA and get the value of the selected user object
                user_id = user_id?.value ?? user_id

                // const seller = this.lists.find(l => lid === l.rid).seller_name
                const qflag = isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected
                const header = this.$session.get('ver_headers')
                header.headers['Campaign-id'] = this.$route.params.campaign_id

                this.$parent.showSnackbar('Processing request...')

                let predefined_data = {
                    "main_reasons"   :this.selected_predefined_reasons,
                    "sub_reasons"    :this.selected_predefined_sub_reasons,
                    "listing_id"     :listing_ids,
                    "status"         :this.individualReassign.status.selected,
                    "campaign_id"    :campaign_id,
                    "other_reason_id":this.selected_other_reason,
                    "other_reason"   :this.other_reason
                }

                if (!this.isResearcher && [user_id, qflag, campaign_id].every(s => s == 0)) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                } 
                
                // const canSelectUser = !this.isFeatureRestricted('v-reassignment-user');

                // if (canSelectUser && [user_id].includes(0)) {
                //     this.is_loading = false
                //     this.reassignStatuses.loading = false
                //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                // }

                if (this.confirmedLFCNote == false && this.checkIndividualLFC && (this.selected_change_status == 99 || this.batch_change_status == 99)) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return $('#lfc-confirm-invalidation').modal('show')
                }

                if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                }

                if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                    this.is_loading = false
                    this.reassignStatuses.loading = false
                    return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                }

                // const exceptedStatus = [99, 33]
                // if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag)) {
                //     const hasPotentials       = await this.checkPotentialWhitelists([lid], current_qflag, 'newReassignListing')
                //     this.isCheckingPotentials = false
                //     this.is_loading           = false

                //     if(hasPotentials) return
                // }

                const payload = {
                    listing_ids: listing_ids,
                    target_campaign_id: campaign_id == 0 ? null : campaign_id,
                    target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                    target_user_id: user_id == 0 ? null : user_id,
                    target_qflag_id: qflag == 0 ? null : qflag,
                    comment: "", //Always set comment to empty since there is predefined-reason to include
                    reasons: this.setPredefinedReasons(),
                }

                Object.keys(payload).forEach(k => payload[k] == null && delete payload[k])

                if (this.showRestrictedReasons()) return;

                await this.$http.patch(`listings/bulk-reassign`, payload, header)
                .then(response=>{
                    const result = response.data

                    const isNotSuccessful = result.filter(r => !r.success)

                    if (isNotSuccessful.length) {
                        this.reassign__unsuccessful = {
                            hasActive: [],
                            notAssignedToCampaign: [],
                            duplicatesFound: [],
                            others: []
                        }
                        
                        for (let i = 0; i < isNotSuccessful.length; i++) {
                            const current = isNotSuccessful[i]
                            this.reassign__unsuccessful[current.errorMessage.includes("Seller already have an")
                                ? "hasActive"
                                : current.errorMessage.includes("Error. The researcher aren")
                                    ? "notAssignedToCampaign"
                                    : current.errorMessage == "Duplicates found"
                                        ? "duplicatesFound"
                                        : "others"
                            ].push(current)
                        }

                        return $("#reassignment-unsuccessful--modal").modal('show')
                    }

                    let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                    filter_drawer.dem_statuses = []
                    filter_drawer.initializeStatuses()

                    this.closeModal()

                    // trigger notification to users
                    globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: this.active_li.user_id, qa_user_id: this.active_li.qa_user_id, } });

                    this.getListing()
                    
                    this.is_select_all = false
                    this.checkAllListing()

                    this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                    
                    this.setRelatedCampaignDuplicateBypass(false)
                    $('#related-campaign-duplicate-sellers-modal').modal('hide')

                }).catch(async err=>{
                    if (err?.response?.meta.code == 403) {
                        this.$parent.showSnackbar(`${err.response.meta.message}`,false,'invalid','fas fa-times-circle')
                        return
                    }

                    this.$parent.showSnackbar(`${err?.response?.data?.message}`,false,'invalid','fas fa-times-circle')
                })
                .finally(async() => {
                    this.is_loading = false
                    this.reassignStatuses.loading = false

                    let trigger_payload = {
                        label: 'isTriggerOn_reassigningResearcherSide',
                        value: true,
                    }
                    await this.$store.dispatch('setTrigger', trigger_payload)
                    console.log('trigger in reassign listing ver')
                })
            },
            newReassignListingX(lid, current_qflag, user_id, campaign_id, isIndividual = false) {
                return new Promise(async (resolve, reject) => {
                        // Check if user is QA and get the value of the selected user object
                        user_id = user_id?.value ?? user_id

                        const seller = this.lists.find(l => lid === l.rid).seller_name
                        const qflag = isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected
                        let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                        header.headers['Campaign-id'] = this.$route.params.campaign_id

                        if (isIndividual) this.$parent.showSnackbar('Processing request...')

                        let patch   = {}
                        let allow_research = true

                        let payload = {"comment":""}

                        let predefined_data = {
                            "main_reasons"   :this.selected_predefined_reasons,
                            "sub_reasons"    :this.selected_predefined_sub_reasons,
                            "listing_id"     :lid,
                            "status"         :isIndividual ? this.individualReassign.status.selected : this.reassignStatuses.selected,
                            "campaign_id"    :campaign_id,
                            "other_reason_id":this.selected_other_reason,
                            "other_reason"   :this.other_reason
                        }

                        if (isIndividual) {
                            if(!allow_research) {
                                this.is_loading = false
                                reject(new Error("Please fill all the required information before tagging this as researched."))
                                return this.$parent.showSnackbar("Please fill all the required information before tagging this as researched.", false, "invalid", "fas fa-times-circle")
                            }

                            if (!this.isResearcher && [user_id, qflag, this.individualReassign.campaign.selected].every(s => s == 0)) {
                                this.is_loading = false
                                return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                            } 
                            
                            // if (this.isResearcher && [user_id].includes(0))  {
                            const canSelectUser = !this.isFeatureRestricted('v-reassignment-user');

                            if (canSelectUser && [user_id].includes(0)) {
                                this.is_loading = false
                                return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                            }

                            if (this.confirmedLFCNote == false && this.checkIndividualLFC && (this.selected_change_status == 99 || this.batch_change_status == 99)) {
                                this.is_loading = false
                                return $('#lfc-confirm-invalidation').modal('show')
                            }

                            // if (!user_id) {
                            //     this.is_loading = false
                            //     reject(new Error('Please select user first'))
                            //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                            // }

                            // if (!qflag && this.canReassignWithStatus) {
                            //     this.is_loading = false
                            //     reject(new Error('Please select status first'))
                            //     return this.$parent.showSnackbar('Please select status first', false, "invalid", "fas fa-times-circle")
                            // }

                            // if (!this.individualReassign.campaign.selected && this.canReassignWithCampaign) {
                            //     this.is_loading = false
                            //     reject(new Error('Please select campaign first'))
                            //     return this.$parent.showSnackbar('Please select campaign first', false, "invalid", "fas fa-times-circle")
                            // }

                            // if (qflag == 3 || qflag == 98) {
                            //     if (this.selected_comm !== '') {
                            //         payload.for_commission = this.selected_comm == 1 ? 'yes' : (this.selected_comm == 0 ? 'no' : null)
                            //     } else {
                            //         this.is_loading = false
                            //         reject(new Error("Please select whether with or without commission"))
                                    
                            //         return this.$parent.showSnackbar("Please select whether with or without commission", false, 'invalid', 'fas fa-info-circle')
                            //     }
                            // }
                            
                            // if(predefined_data['main_reasons'].length || predefined_data['sub_reasons'].length || predefined_data['other_reason_id'].length) {
                            // if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && !([4].includes(qflag) && [1, 99].includes(this.currentStatus))) {
                            if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                                this.is_loading = false
                                reject(new Error('Please select reason(s)'))
                                return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                            }

                            // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                            if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                                this.is_loading = false
                                reject(new Error('Please add comment.'))
                                return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                            }

                            // if(+qflag === 2) {
                            //     if(this.reworkLevel === null) {
                            //         this.is_loading = false
                            //         reject(new Error('Please select Severity Level'))
                                    
                            //         return this.$parent.showSnackbar('Please select Severity Level', false, 'invalid', 'fas fa-info-circle')

                            //
                            //     }

                            //     if(this.reworkCommission === null){
                            //         this.is_loading = false
                            //         reject(new Error('Please select Rework Commission'))

                            //         return this.$parent.showSnackbar('Please select Rework Commission', false, 'invalid', 'fas fa-info-circle')
                                    
                            
                            //     }
                            // }

                            // this.$parent.showSnackbar('Updating status...')

                            const deduplicatedListings = await this.checkForDuplicateListing(''+predefined_data.listing_id, qflag, () => this.newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual))
                            if (deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                                this.is_loading = false
                                return
                            }

                            if(deduplicatedListings === '') {
                                this.is_loading = false
                                reject(new Error('has duplicate'))
                                return
                                // return this.$parent.hideSnackbar()
                            }

                            const exceptedStatus = [99, 33]
                            if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag)) {
                                const hasPotentials       = await this.checkPotentialWhitelists([lid], current_qflag, 'newReassignListing')
                                this.isCheckingPotentials = false
                                this.is_loading           = false

                                if(hasPotentials) {
                                    reject(new Error('has potential whitelist'))
                                    return
                                }
                            }
                        }

                        //Always set comment to empty since there is predefined-reason to include
                        payload.comment           = ''
                        payload.reasons           = this.setPredefinedReasons()

                        // eslint-disable-next-line no-unreachable
                        if(![3, 98].includes(+qflag)) {
                            payload.rework_level      = this.reworkLevel
                            payload.rework_commission = this.reworkCommission
                        }

                        if (this.showRestrictedReasons()) {
                            return;
                        }

                        await this.$http.patch(`listings/${lid}/statuschange/reassignment/${+qflag}/${+user_id}/${+campaign_id}`, payload, header)
                        .then(response=>{
                            let result = response.data

                            if (isIndividual) {
                                //If current qflag is for rework (qflag_id = 2), reduce count of active campaign in sidebar
                                // current_qflag == 2 && (this.reduceSidebarCampaignCount(this.campaign.name))

                                /**
                                 * Deliverable: Hide all For Rework/Reworked elements across all GUI
                                 * Deliverable Number: 454
                                 */
                                // if(current_qflag == 2) {
                                //     this.reduceSidebarCampaignCount(this.campaign.name)
                                // }
        
                                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                                filter_drawer.dem_statuses = []
                                filter_drawer.initializeStatuses()
        
                                this.closeModal()

                                // trigger notification to users
                                globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: this.active_li.user_id, qa_user_id: this.active_li.qa_user_id, } });

                                this.getListing()
                                
                                this.is_select_all = false
                                this.checkAllListing()
                            }

                            if (isIndividual) this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                            
                            this.setRelatedCampaignDuplicateBypass(false)
                            $('#related-campaign-duplicate-sellers-modal').modal('hide')

                            resolve(result)
                        }).catch(async err=>{
                            const duplicate_logs = err?.response?.data?.duplicate_logs
                            if (duplicate_logs) {
                                $('.modal').modal('hide')

                                await sleep(500)

                                this.setRelatedCampaignDuplicateContinueFunction(() => this.newReassignListing(lid, current_qflag, user_id, campaign_id, isIndividual))
                                this.setRelatedCampaignDuplicates(duplicate_logs)
                                this.showSnackbar(`${err.response.data.message}`,false,'invalid','fas fa-times-circle')
                                reject({ message: new Error(err.response.data.meta.message), lid, seller })
                                return    
                            }
                            
                            if(this.existing_and_duplicate_reasons.includes(err.response.data.meta.message)){
                                this.hasExisting_listings = true
                            }
                            this.reassignmentFailed_meta_data.push(err.response.data.meta)
                            reject({ message: new Error(err.response.data.meta.message), lid, seller })
                            
                            if (isIndividual) {
                                console.error(err);
                                // let message = err.response.data.meta.message
                                // this.$parent.showSnackbar(message, false, 'invalid', 'fas fa-times-circle')

                                this.$parent.showSnackbar('Request processed successfully, encountered problems.', false, 'warning', 'fas fa-exclamation-triangle')
                                
                                this.reassignStatuses.results[0] = {
                                    reason: { message: new Error(err.response.data.meta.message), lid, seller },
                                    status: 'rejected'
                                }
                                let result_unfulfilled = this.reassignStatuses.results.filter(r => r.status === 'rejected')
                                if(this.hasExisting_listings) {
                                    this.transferExistingTo_reassign(this.get_existing_listings, result_unfulfilled)
                                }
                                this.individualReassign_hasError = true
                                $('#listing-modal').modal('hide')
                                this.showReassignModal()
                            }
                        })
                        .finally(async() => {
                            this.is_loading = false

                            let trigger_payload = {
                                label: 'isTriggerOn_reassigningResearcherSide',
                                value: true,
                            }
                            await this.$store.dispatch('setTrigger', trigger_payload)
                            console.log('trigger in reassign listing ver')
                        })
                })
            },
            async batchNewReassignListing() {
                const qflag = this.reassignStatuses.selected
                const campaign_id = this.reassign_campaign
                const user_id = this.selected_campaign_user
                
                this.reassignStatuses.loading = true

                await this.newReassignListing(null, null, user_id, campaign_id, false)
            },
            async batchNewReassignListingX() {
                let promises = []
                const qflag = this.reassignStatuses.selected
                const campaign_id = this.reassign_campaign
                const user_id = this.selected_campaign_user?.value ?? this.selected_campaign_user
                
                this.reassignStatuses.loading = true
                this.$parent.showSnackbar('Processing request...')
                for (let index = 0; index < this.selected_listings.length; index++) {
                    let lid = this.selected_listings[index]
                    let listing = this.lists.find(l => l.rid === lid)
                    let patch   = {}
                    let allow_research = true

                    if(!allow_research) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar("Please fill all the required information before tagging this as researched.", false, "invalid", "fas fa-times-circle")
                    }
                    let payload = {"comment":""}
                    let predefined_data = {
                        "main_reasons"   :this.selected_predefined_reasons,
                        "sub_reasons"    :this.selected_predefined_sub_reasons,
                        "listing_id"     :lid,
                        "status"         :this.reassignStatuses.selected,
                        "other_reason_id":this.selected_other_reason,
                        "other_reason"   :this.other_reason
                    }

                    // if ([this.selected_campaign_user, this.reassignStatuses.selected, this.reassign_campaign].includes(0)) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select user, status, and campaign first', false, "invalid", "fas fa-times-circle")
                    // }

                    if (!this.isResearcher && [user_id, qflag, campaign_id].every(s => s == 0)) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select user, status, or campaign first', false, "invalid", "fas fa-times-circle")
                    } 
                    
                    if (this.isResearcher && [user_id].includes(0))  {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                    }

                    // if (!user_id) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select user first', false, "invalid", "fas fa-times-circle")
                    // }

                    // if (!qflag && this.canReassignWithStatus) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select status first', false, "invalid", "fas fa-times-circle")
                    // }

                    // if (!campaign_id && this.canReassignWithCampaign) {
                    //     this.reassignStatuses.loading = false
                    //     return this.$parent.showSnackbar('Please select campaign first', false, "invalid", "fas fa-times-circle")
                    // }
                    
                    // if(predefined_data['main_reasons'].length || predefined_data['sub_reasons'].length || predefined_data['other_reason_id'].length) {
                    // if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && !([4].includes(qflag) && [1, 99].includes(this.currentStatus))) {
                    if(qflag && !predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length && this.canReassignWithStatus) {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                    }
                    // if(predefined_data['other_reason_id'].length && predefined_data['other_reason'].trim() == '') {
                    if(predefined_data.other_reason_id.length && predefined_data.other_reason.trim() == '') {
                        this.reassignStatuses.loading = false
                        return this.$parent.showSnackbar('Please add comment.', false, 'invalid', 'fas fa-info-circle')
                    }
                    const deduplicatedListings = await this.checkForDuplicateListing(''+predefined_data.listing_id, qflag, () => this.batchNewReassignListing())
                    if(deduplicatedListings === '' || deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                        this.reassignStatuses.loading = false
                        return
                    }
                    const exceptedStatus = [99, 33]
                    if(!this.hasAcknowledged && !exceptedStatus.includes(+qflag)) {
                        const hasPotentials       = await this.checkPotentialWhitelists([lid], listing.qflag_id, 'batchNewReassignListing', 'reassign-modal')
                        this.isCheckingPotentials = false
                        this.is_loading           = false
                        this.reassignStatuses.loading = false

                        if(hasPotentials) return
                    }

                    promises.push(this.newReassignListing(this.selected_listings[index], listing.qflag_id, user_id, campaign_id))
                }
                const results = await Promise.allSettled(promises)

                if (results.every(r => r.status == 'fulfilled')) {
                    this.$parent.showSnackbar('Request processed successfully!', false, 'success', 'fas fa-check')
                    this.closeModal()
                } else {
                    this.$parent.showSnackbar('Request processed successfully, encountered problems.', false, 'warning', 'fas fa-exclamation-triangle')
                    let result_unfulfilled = results.filter(r => r.status === 'rejected')
                    if(this.hasExisting_listings) {
                        this.transferExistingTo_reassign(this.get_existing_listings, result_unfulfilled)
                    }
                }
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.dem_statuses = []
                filter_drawer.initializeStatuses()

                // trigger notification to users
                this.notifyListingUpdate()

                this.getListing()
                this.is_select_all = false
                this.checkAllListing()
                
                let result_unfulfilled_length = results.filter(r => r.status === 'rejected').length
                if(this.hasExisting_listings && this.get_existing_listings.length == result_unfulfilled_length){
                    this.reassignStatuses.results = this.reassignStatuses.results.filter(r => r.status === 'rejected')
                }else {
                    this.reassignStatuses.results = results.filter(r => r.status === 'rejected')
                }
                this.reassignStatuses.loading = false
            },
            doNewReassign(isIndividual = false, list = null) {
                const user = isIndividual ? this.individualReassign.user.selected : this.selected_campaign_user
                if (this.isSelectedUserQA(user)){
                    console.log('here in reassignQA')
                    this.reassignQA(isIndividual)
                }

                if (this.hasLFC == 1 && this.batchModalProps.status == 99) {
                    $('#lfc-confirm-invalidation').modal('show')
                }

                else if (isIndividual && list != null)
                    this.newReassignListing(list.rid, list.qflag_id, user, this.individualReassign.campaign.selected, true)
                else
                    this.batchNewReassignListing()
            },
            isSelectedUserQA(data) {
                return [2].includes(data?.access_level ?? 0)
            },
            async reassignQA(isIndividual = false) {
                let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                const selected = isIndividual ? this.individualReassign.user.selected?.value ?? this.individualReassign.user.selected : this.selected_campaign_user?.value ?? this.selected_campaign_user
                if(selected != 0){
                    this.$parent.showSnackbar("Updating QA Owner..")
                    let payload = {"ids":isIndividual ? [this.active_listing_info[0]?.rid] : this.selected_listings}
                    await axios.patch(process.env.VUE_APP_URL+`/listing/qa_user_id/${selected}`,payload,header).
                    then(response=>{
                        if(response.status == 200){
                            // trigger notification to users
                            if (isIndividual)
                                globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: this.active_li.user_id, qa_user_id: this.active_li.qa_user_id, } });
                            else
                                notifyListingUpdate();

                            this.closeModal()
                            this.is_select_all = false
                            this.checkAllListing()
                            this.getListing()
                            this.$parent.showSnackbar("Successfully updated QA Owner.",false, "success", "fas fa-check-circle")
                        }
                    }).catch(err=>{
                        this.closeModal()
                        this.is_select_all = false
                        this.checkAllListing()
                        this.$parent.showSnackbar("Something went wrong. Please contact system admin.",false, "invalid", "fas fa-times-circle")
                    })
                }else{
                    this.$parent.showSnackbar("Please select QA to reassign.", false, "invalid", "fas fa-times-circle")
                }
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                filter_drawer.dem_statuses = []
                filter_drawer.initializeStatuses()
            },
            // async reassignListing(mode){
            //         let header = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
            //         header.headers['Campaign-id'] = this.$route.params.campaign_id
            //         if(mode == 'Researcher'){
            //             let payload = {"listings": {}}
            //             if ([1].includes(this.access_level)) {
            //                 if (this.selected_campaign_user != 0) {
            //                     for(let listing of this.selected_listings){
            //                         payload.listings[listing] = {user_id:this.selected_campaign_user, campaign_id: this.reassign_campaign}
            //                     }
            //                     await axios.patch(process.env.VUE_APP_URL+"/multi",payload,header)
            //                         .then(response=>{
            //                             let failed_listing = response.data.filter(l => l.meta.code == 400);
            //                             this.closeModal()
            //                             this.is_select_all = false
            //                             this.checkAllListing()
            //                             if(failed_listing.length == 0){
            //                                 this.$parent.showSnackbar("Successfully reassigned",false,"success","fas fa-check-circle")
            //                                 setTimeout(() => {
            //                                     this.getListing()
            //                                 }, 1500);
            //                             }
            //                             else {
            //                                 setTimeout(async () => {
            //                                     this.getListing();
            //                                     while (this.searching) {
            //                                         await new Promise(r => setTimeout(r, 500));//delay
            //                                     }
            //                                     failed_listing.map(f => this.lists.filter(l => l.rid == f.id).map(l => l.error = true));
            //                                     this.$parent.showSnackbar("Some listing(s) were not reassigned. Seller may already have an active listing to the requested campaign", false, "invalid", 'fas fa-times-circle')
            //                                     this.changeShowError();
            //                                 }, 1500);
            //                             }
            //                         }).catch(err=>{alert(err.response.data.meta.message+"Please contact system admin.")})
            //                 } else {
            //                     this.$parent.showSnackbar("Please select user", false, "invalid", 'fas fa-times-circle')
            //                 }
            //             } else {
            //                 let errorMessage = ([this.selected_campaign_user, this.reassign_campaign].includes(0))
            //                         ? "Please select user and campaign first"
            //                         : null
            //                 if (errorMessage)
            //                     return this.$parent.showSnackbar(errorMessage, false, "invalid", 'fas fa-times-circle')
            //                 for(let listing of this.selected_listings){
            //                     payload.listings[listing] = { user_id: this.selected_campaign_user, campaign_id: this.isCLAccount ? this.$route.params.campaign_id : this.reassign_campaign }
            //                 }
            //                 await axios.patch(process.env.VUE_APP_URL+"/multi",payload,header)
            //                     .then(response=>{
            //                         let failed_listing = response.data.filter(l => l.meta.code == 400);
            //                         if(failed_listing.length == 0){
            //                             this.is_select_all = false
            //                             this.checkAllListing()
            //                             this.closeModal()
            //                             this.$parent.showSnackbar("Successfully reassigned",false,"success","fas fa-check-circle")
            //                             setTimeout(() => { 
            //                                 this.getListing()
            //                             }, 1500);
            //                         }
            //                         else {
            //                             this.is_select_all = false
            //                             this.checkAllListing()
            //                             this.closeModal()
            //                             setTimeout(async () => {
            //                                 this.getListing();
            //                                 while (this.searching) {
            //                                     await new Promise(r => setTimeout(r, 500));//delay
            //                                 }
            //                                 failed_listing.map(f => this.lists.filter(l => l.rid == f.id).map(l => l.error = true));
            //                                 this.$parent.showSnackbar("Some listing(s) were not reassigned. Listing's seller may already have an active listing to the requested campaign", false, "invalid", 'fas fa-times-circle')
            //                                 this.changeShowError();
            //                             }, 1500);
            //                         }
            //                     }).catch(err=>{alert(err.response.data.meta.message+"Please contact system admin.")})
            //             }
            //         }else if(mode == 'QA'){
            //             if(this.selected_campaign_user != 0){
            //                 this.$parent.showSnackbar("Updating QA Owner..")
            //                 let payload = {"ids":this.selected_listings,}
            //                 await axios.patch(process.env.VUE_APP_URL+`/listing/qa_user_id/${this.selected_campaign_user}`,payload,header).
            //                 then(response=>{
            //                     if(response.status == 200){
            //                         this.closeModal()
            //                         this.is_select_all = false
            //                         this.checkAllListing()
            //                         this.getListing()
            //                         this.$parent.showSnackbar("Successfully updated QA Owner.",false, "success", "fas fa-check-circle")
            //                     }
            //                 }).catch(err=>{
            //                     this.closeModal()
            //                     this.is_select_all = false
            //                     this.checkAllListing()
            //                     this.$parent.showSnackbar("Something went wrong. Please contact system admin.",false, "invalid", "fas fa-times-circle")
            //                 })
            //             }else{
            //                 this.$parent.showSnackbar("Please select QA to reassign.", false, "invalid", "fas fa-times-circle")
            //             }
            //         }
            //     let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
            //     filter_drawer.dem_statuses = []
            //     filter_drawer.initializeStatuses()
            // },
            async batchChangeStatus(listings, status, comment, dismisser) {
                if(+status === 2) {
                    if(this.reworkCommission === null)
                        return this.$parent.showSnackbar('Please select Rework Commission', false, 'invalid', 'fas fa-info-circle')
                    if(this.reworkLevel === null)
                        return this.$parent.showSnackbar('Please select Severity Level', false, 'invalid', 'fas fa-info-circle')
                }
                
                const header = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                header.headers['Campaign-id'] = this.$route.params.campaign_id

                const body = {comment: '', listing_ids: listings.toString()}

                const predefined_data = {
                    main_reasons   :this.selected_predefined_reasons,
                    sub_reasons    :this.selected_predefined_sub_reasons,
                    status         :this.batch_change_status,
                    other_reason_id:this.selected_other_reason,
                    other_reason   :this.other_reason
                }

                const autoQualified = 30
                if(+status !== autoQualified) {
                    if(!predefined_data.main_reasons.length && !predefined_data.sub_reasons.length && !predefined_data.other_reason_id.length)
                        return this.$parent.showSnackbar('Please select reason(s)', false, 'invalid', 'fas fa-times-circle')
                }

                if (this.selected_listings.length && this.hasLFC == 1 && this.confirmedLFCNote == false && this.batch_change_status == 99) {
                    this.is_loading = false
                    return $('#lfc-confirm-invalidation').modal('show')
                }

                const exceptedStatus = [33, 99]
                if(!this.hasAcknowledged && !exceptedStatus.includes(+status)) {
                    const hasPotentials = await this.checkPotentialWhitelists(listings, status, 'batchChangeStatus', 'change-status-modal')
                    this.isCheckingPotentials = false
                    if(hasPotentials) return
                }

                this.is_loading = true
                if([3, 98, 32].includes(+status)) {
                    body.for_commission = this.selected_comm ? 'yes' : (this.selected_comm == 0 ? 'no' : null)
                }

                //Check for duplicate listings
                const deduplicatedListings = await this.checkForDuplicateListing(body.listing_ids, status, () => this.batchChangeStatus(listings, status, comment, dismisser))
                if (deduplicatedListings == 'oh it is raining in manila, di ka ba nilalamig') {
                    this.is_loading = false
                    return
                }

                if(deduplicatedListings === '') {
                    this.is_loading = false
                    this.duplicateListingModalOnContinue = () => this.multipleStatusChange('', status)
                    this.duplicateListingModalProceedAnyway = () => this.multipleStatusChange(body.listing_ids, status)
                    return this.$parent.showSnackbar('All selected listings has duplicate sellers', false, 'invalid', 'fas fa-times-circle')
                }

                if(this.duplicate_listings.length) {
                    this.is_loading = false
                    this.duplicateListingModalOnContinue = () => this.multipleStatusChange(deduplicatedListings, status, predefined_data)
                    this.duplicateListingModalProceedAnyway = () => this.multipleStatusChange(body.listing_ids, status)
                    return
                }

                if(!deduplicatedListings) {
                    return;
                }

                if (this.showRestrictedReasons()) {
                    return;
                }

                this.multipleStatusChange(deduplicatedListings, status, predefined_data)
            },
            showRestrictedReasons() {
                const role = this.user?.role ?? this.user.access_level;
                const IS_INTERNAL_USER = role === Role.Lawfirm || role === Role.Client;

                if (IS_INTERNAL_USER) {
                    return false;
                }

                if (this.isRestrictionLoading) {
                    this.isRestrictionLoading = false;
                }

                if (!this.hasSelectedReasonWithRestriction) {
                    return false;
                }

                this.showReasonWithRestriction = true;
                this.is_loading                = false;
                this.reassignStatuses.loading  = false;

                if (this.other_reason === '') {
                    this.hasSeenReasonWithRestriction = true;
                    this.$parent.showSnackbar('Please provide a custom reason.', false, 'invalid', 'fas fa-info-circle');

                    return true;
                }

                const otherReasonId = this.predefined_reasons.find(item => item.reason === 'Others').id;
                this.selected_other_reason = [ ...this.selected_other_reason, otherReasonId ];

                if (!this.hasSeenReasonWithRestriction) {
                    this.hasSeenReasonWithRestriction = true;
                    this.$parent.showSnackbar('Selected reason(s) are restricted to some role(s).', false, 'warning', 'fas fa-info-circle');

                    return true;
                }

                this.isRestrictionLoading = true;
                this.resetReasonsSelectionStore();
                return false;
            },
            resetReasonsSelectionStore() {
                return useReasonSelectionStore().resetReasonsSelectionStore();
            },
            notifyListingUpdate() {
                // trigger notification to users
                this.selectedListingsValues.forEach(li => {
                    globalSend({ type: 'notification', data: { updater_id: this.user.id, researcher_id: li.owner_id, qa_user_id: li.qualifier_id, } });
                })
            },
            /**
             * Apply status change on multiple listing ids.
             * Detect whitelist sellers.
             * Set predefined reasons.
             * @param {string} listing_ids
             * @param {number} status
             * @param {object} reasons
             */
            async multipleStatusChange(listing_ids, status, reasons) {
                if(listing_ids === '')
                    return this.$parent.showSnackbar('No listing(s) to be updated', false, 'invalid', 'fas fa-times-circle')

                if (this.subStatuses[this.$parent.getQFlag(status)]?.filter(s => s.id)?.length && this.subStatusesStore.currentSelectedSubStatus == null) {
                    this.is_loading = false
                    return this.$parent.showSnackbar('Please select a sub status', false, 'warning', 'fas fa-exclamation-triangle')
                }

                const header = this.$session.get('ver_headers')
                const body   = {
                    listing_ids,
                    reasons: this.setPredefinedReasons(),
                    comment: '',
                    target_substatus_id: this.subStatusesStore.currentSelectedSubStatus ?? -1,
                    // rework_level     : this.reworkLevel,
                    // rework_commission: this.reworkCommission,
                    // for_commission   : this.selected_comm
                }
                if([98, 3, 32].includes(+status) && this.selected_comm !== null) {
                    body.for_commission = this.forCommission 
                } else {
                    body.rework_level = this.reworkLevel
                    body.rework_commission = this.reworkCommission
                }
                // body.reasons = this.setPredefinedReasons()
                header.headers['Campaign-id'] = this.$route.params.campaign_id
                this.$http.patch(`/listings/multiple/statuschange/${status}`, body, header)
                    .then(response => {
                        this.setRelatedCampaignDuplicateBypass(false)

                        const result = response.data
                        //Alert user when seller is whitelisted
                        if(result.meta.whitelisted) {
                            this.detected_whitelist = {
                                name     : result.data.seller_name,
                                url      : result.data.seller_url,
                                site_id  : result.data.site_id,
                                platforms: result.data.platforms.join(', '),
                                campaigns: result.data.campaigns .join(', ')
                            }
                            $('.modal').modal('hide')
                            $('#whitelist-info-modal').modal('show')
                            return this.$parent.showSnackbar('Seller is whitelisted', false, 'invalid', 'fas fa-times-circle')
                        }
                        if(+result.meta.code === 200) {
                            this.forCommission = null
                            const listings = listing_ids.split(',')
                            if(+status !== 30) {
                                for(const listing_id of listings) {
                                    this.setPredefinedReasons({ ...reasons, listing_id })
                                }
                            }
                            this.$parent.showSnackbar('Successfully update status of selected listings', false, 'success', 'fas fa-check-circle')

                            // trigger notification to users
                            this.notifyListingUpdate()

                            let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                            filter_drawer.dem_statuses = []
                            filter_drawer.initializeStatuses()
                            this.getListing()
                            if(this.access_level == 1) {
                                this.$store.dispatch('setResearchedTracker', true)
                                this.$store.dispatch('setWaitingTracker', false)
                                setTimeout(() => {
                                    this.$store.dispatch('setWaitingTracker', true)
                                }, 5000)
                            }
                            this.is_select_all = false
                            this.confirmedLFCNote = false
                            this.checkAllListing()
                            // document.getElementById(dismisser).click()
                            this.closeModal()
                            if(+status === 30)
                                $('#set-autoQ-modal').modal('hide')

                            this.resetReasonsSelectionStore();
                        } else {
                            this.closeModal()
                            this.$parent.showSnackbar(result.meta.message, false, 'warning', 'fas fa-exclamation-triangle')
                        }
                    })
                    .catch(err => {
                        const duplicate_logs = err?.response?.data?.meta?.duplicate_logs
                        if (duplicate_logs) { 
                            this.setRelatedCampaignDuplicateContinueFunction(() => this.multipleStatusChange(listing_ids, status, reasons))
                            this.setRelatedCampaignDuplicates(duplicate_logs)
                            this.$parent.showSnackbar(err.response.data.meta.message, false, "invalid", "fas fa-exclamation-circle")
                            return
                        }
                        if (err?.response?.data?.data) {
                            console.error(err.response.data.data)
                            this.already_worked_lid = err.response.data.data.map(({duplicate}) => duplicate.id).join(',')
                            this.duplicate_seller_found = err.response.data.data.map(({duplicate}) => duplicate)
                            this.closeModal()
                            $('#duplicate-seller-modal').modal('show')
                            this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-exclamation-circle')
                        }
                        else if (err?.response?.data?.meta?.message) {
                            this.$parent.showSnackbar(err.response.data.meta.message, false, 'invalid', 'fas fa-exclamation-circle')
                        }
                    })
                    .finally(() => this.is_loading = false)
            },
            /**
             * Check for duplicate listings.
             * Show modal if has duplicate listings.
             * Set value for vm.duplicate_seller_found.
             *
             * @param {string} listing_ids
             * @param {number} qflag
             * @return {string | boolean} Listing Ids with no duplicate. False when server error occurs
             */
            async checkForDuplicateListing(listing_ids, qflag, sourceFunction = () => {}) {
                if (typeof listing_ids == 'string')
                {
                    listing_ids = listing_ids.split(',');
                }
                else if (typeof listing_ids == 'number')
                {
                    listing_ids = [listing_ids];
                }
                this.$parent.showSnackbar('Checking for duplicates...', true);
                const header = this.$session.get('ver_headers')
                const {status, data} = await this.$http.post(`/duplicate_listings/${qflag}`, {
                    listing_ids: listing_ids
                }, header)
                    .catch(err => err)
                
                if(data.data && data.data[0]?.duplicate_logs != null){
                    const d = data.data[0]
                    const duplicate_logs = { location: d.duplicate_logs, data: d.duplicates }
                    $('.modal').modal('hide')
                    await sleep(500)
                    this.setRelatedCampaignDuplicateContinueFunction(() => sourceFunction())
                    this.setRelatedCampaignDuplicates(duplicate_logs)
                    this.$parent.showSnackbar(`${data.meta.message}`,false,'invalid','fas fa-times-circle')

                    return 'oh it is raining in manila, di ka ba nilalamig' // just some string to return that is not empty
                }

                if(status !== 200) {
                    this.closeModal()
                    this.$parent.showSnackbar('Something went wrong. Please contact System Admin', false, 'invalid', 'fas fa-times-circle')
                    return false
                }
                //Remove listing id from listing_ids that has duplicate
                const response = data.data
                const withDuplicateListingIds = response.map(({ reference }) => reference.id)
                this.duplicate_listings = response.map(({reference, duplicates}) => (
                    {
                        id: reference.id,
                        duplicates: duplicates.map(duplicate => (
                            {
                                id   : duplicate.id,
                                name : duplicate.name,
                                qflag: duplicate.qflag_id
                            }
                        ))
                    }
                ))
                if(this.duplicate_listings.length) {
                    this.closeModal()
                    $('#duplicate-listing-modal').modal('show')
                }

                // this.setRelatedCampaignDuplicateBypass(false)
                this.$parent.showSnackbar('Done checking for duplicates',false,'success','fas fa-check-circle');
                return listing_ids
                    .filter(listing => !withDuplicateListingIds.includes(+listing))
                    .join()
            },
            /**
             * Redirect to search page and search for listing id
             * @param {number} id
             */
            searchListing(id) {
                window.open(`/verification/search?status=All&platform=0&lid=${id}`, '_blank', 'noopener')
            },
            closeModal() {
                $('.modal').modal('hide')
            },
            checkAllListing(){
                // this.select_all_text = this.is_select_all ? "Unselect" : "Select"
                this.selected_listings = []
                var inputs = document.querySelectorAll("input[name='listings']");
                for(let i = 0; i < inputs.length; i++) {
                    this.selected_listings.push(parseInt(inputs[i].value)) 
                    inputs[i].checked = this.is_select_all;
                    if (this.is_select_all)
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.add('checked')
                    else
                        inputs[i].parentNode.parentNode.parentNode.parentNode.classList.remove('checked')
                }
                if(this.is_select_all === false){
                    this.selected_listings = []
                }
                this.is_select_all = !this.is_select_all
            },
            downloadExcel(campaign_name, campaign_id,listings){
                localStorage.setItem("source", "campaign management");
                localStorage.setItem("ver_listings",listings)
                let route = this.$router.resolve({name:"export",params:{"campaign_id":campaign_id},query:{"name":campaign_name}})
                window.open(route.href,"_blank")
            },
            uploadedEvidence(evidences) {
                let arr     = []
                const types = ['preview', 'lf review', 'product', 'store', 'conversation', 'checkout', 'additional evidence', 'asin location info', 'seller name google search', 'cover', 'merge']
                const getEvidence = type => arr.filter(evidences => evidences.type.toLowerCase().includes(type))
                for(const key in evidences) {
                    arr.push({type: key, image: evidences[key]})
                }
                let new_evidences = []
                for (const type of types) {
                    const temp = getEvidence(type)
                    if(temp.length > 1) {
                        temp.forEach(each => new_evidences.push(each))
                        continue
                    }
                    new_evidences.push(temp[0])
                }
                this.uploaded_evidences = new_evidences
                    .filter(evidence => evidence !== undefined)
                    .map(image => {
                        let file = image.image
                        const extension = image.image.split('.').pop()
                        image.src  = '/static/svg/PDF Default.svg'
                        image.href = `${this.host}/files/${file}`
                        if(['png','jpg', 'jpeg'].includes(extension)) {
                            const time = new Date().getTime()
                            const url  = file => `${this.host}/files/${file}?t=${time}`
                            image.src  = url(file)
                            image.href = url(file)
                            if(image.type === 'lf review') {
                                if(file?.includes('Missing')) //If image for lf review is missing, try replacing filename lf review to LF Review
                                    file = file.replace('lf review', 'LF Review').replace(' | Missing', '')
                                image.src  = url(file)
                                image.href = url(file)
                            }
                        }
                        return image
                    })
                // this.uploaded_evidences          = new_evidences
                this.is_uploaded_evidences_ready = true
                // return new_evidences
            },
            filterHistory(histories) {
                const filteredHistories = histories.map(history => {
                    if(!history.user.length) {
                        history.user.push({ first_name: 'Undefined', last_name: 'User' })
                    }

                    return history;
                });

                return filteredHistories;
            },
            returnDefault(value) {
                return value ? value : "-----"
            },
            getCampaigns(){
                let headers = JSON.parse(localStorage.getItem('ver')).headers
                axios.get(process.env.VUE_APP_URL+"/campaigns/main?page_size=1000", JSON.parse(headers))
                    .then(response=>{
                        let results = response.data
                        this.campaigns_select = results.data.map(campaign=>{
                            return {value:campaign.id,label:campaign.name}
                        })
                        this.campaign.name = this.campaigns_select.filter(c => c.value == this.$route.params.campaign_id)[0].label
                        this.campaign.id = this.campaigns_select.filter(c => c.value == this.$route.params.campaign_id)[0].value
                        this.campaign.img = this.$parent.getImage('campaigns', this.campaign.name)
                        this.$parent.hideSnackbar()
                    })
            },
            getResearchersCampaigns(user_id) {
                user_id = user_id?.value ?? user_id

                this.researchers_campaigns_select = []
                
                if (!user_id) return
                this.$parent.showSnackbar("Getting selected user's campaign(s). Please wait..", true)
                let headers = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                axios.get(process.env.VUE_APP_URL+`/campaigns/main/user/${user_id}?page_size=1000`, headers)
                    .then(response=>{
                        let results = response.data
                        this.researchers_campaigns_select = results.data.map(campaign=> ({value:campaign.id,label:campaign.name}))
                        this.$parent.showSnackbar("User's campaign(s) successfully fetched.", false, 'success', 'fas fa-check-circle');
                    })
            },
            arrangeList(order) {
                this.order_by = order
            },
            updatePage(page) {
                this.current_page = page
                this.is_select_all = false
                this.checkAllListing()
                this.getListing()
                window.scrollTo(0, 0)
            },
            updateShowNumberItems() {
                switch (this.show_number_of_items) {
                    case "1":
                        this.page_size = 25
                        break
                    case "2":
                        this.page_size = 50
                        break
                    case "3":
                        this.page_size = 100
                        break
                    case "4":
                        this.page_size = 150
                        break
                    case "5":
                        this.page_size = 500
                        break
                    case "6":
                        this.page_size = 1000
                        break
                    default:
                        this.page_size = 10
                        break
                }
            },
            setQflag(id){
                this.qflag = id
            },
            clearKeywords() {
                this.search_keywords = ""
                this.setFilters()
            },
            getStatusIcon(id, field) {
                if (field == "qflag_id")
                    return this.$parent.getQFlagIcon(parseInt(id))
                
                return ""
            },
            setCommission(lid,commission){
                let config = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                config.headers['Campaign-id'] = this.$route.params.campaign_id
                let payload = {"listings":{[lid]:{"for_commission":commission}}}
                axios.patch(process.env.VUE_APP_URL+"/multi",payload,config)
                .then(response=>{
                        document.getElementById("commission").checked = commission == '1' ? true : false
                }).catch(err=>{
                    this.$parent.showSnackbar("Please insert comment",false,"invalid","fas fa-times-circle")
                })
            },
            async showPreview(type, file) {
                if(file != null) {
                    let ext = file.split(".")[1]
                    if("pdf".includes(ext))
                        return '/static/svg/PDF Default.svg'
                    else {
                        if(type === 'lf review') {
                            //     //Check if image is found
                            return await this.$http.get(this.host+'/files/'+file)
                                .then(_ => {
                                    return `${this.host}/files/${file}`
                                    // return this.host+'/files/'+file
                                })
                                .catch(_ => {
                                    // let lf_review = file.split('/')[0] + '/LF%20Review.png'
                                    file = `${file.split('/')[0]}/LF%20Review.png`
                                    // file = lf_review
                                    return `${this.host}/files/${file}`
                                    // return this.host + '/files/' + lf_review
                                })
                        }
                        if(type === 'preview') {
                            // return this.host+'/files/'+file
                            return `${this.host}/files/${file}`
                        }
                    }
                }
            },
            mergeEvidences(listing_id){
                let config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                
                // This is the new fullmerge algo - Marvin 01/30/2023
                config.headers["Gui"] = "Verification";
                const payload = {
                    name: "merge evidences",
                    type: "full merge",
                    description: `Full merge evidence for ${listing_id}`,
                    listing_id: listing_id
                };
                this.$parent.showSnackbar("Adding full merge job to queue...", true)
                this.$http.post("gen_requests", payload, config)
                    .then(response => {
                        this.$parent.showSnackbar("Successfully added job to queue.", false, "success", "fas fa-check-circle")
                    })
                    .catch(err => {
                        console.error(err)
                        this.$parent.showSnackbar(err.response.data.message,false,"invalid","fas fa-times-circle")
                    })
                
                // This is the old Fullmerge algo - Marvin 01/30/2023
                // config.headers["Campaign-id"] = this.$route.params.campaign_id
                // this.$parent.showSnackbar("Merging evidence...", true)
                // axios.post(`${process.env.VUE_APP_URL}/merge/evidences/${listing_id}`,{}, config)
                //     .then(response => {
                //         // if(response.data.meta.code === 200) {
                //             this.modal_evidences = {...this.modal_evidences, ...{"full merge": response.data.data.filename}}
                //             this.$parent.showSnackbar("Successfully merged evidence.", false, "success", "fas fa-check-circle")
                //         // }
                //     })
                //     .catch(err => {
                //         console.error(err)
                //         this.$parent.showSnackbar(err.response.data.message,false,"invalid","fas fa-times-circle")
                //     })
            },
            setFilters() {
                this.filters = {
                    status: this.$route.query.status,
                    platform: this.$route.query.platform,
                    keywords: this.$route.query.keywords,
                    campaign_id: this.$route.params.campaign_id
                }
            },
            showMissing(type) {
                if(type.includes("Missing")){
                    let ext = type.split(".")[1].split("|")[0].trim()
                    return ["png","jpg","jpeg"].includes(ext)
                        ? "this.src='/static/svg/IMG_BROKEN.svg'"
                        : "this.src='/static/svg/PDF_BROKEN.svg'"
                    // if(["png","jpg","jpeg"].includes(ext))
                    //     return "this.src='/static/svg/IMG_BROKEN.svg'"
                    // else
                    //     return "this.src='/static/svg/PDF_BROKEN.svg'"
                }
                return ''
            },
            applyMoreFilters() {
                this.appendListingID();
                if (this.expanded) this.expanded = !this.expanded
                this.updateShowNumberItems()
                this.applied_filters_count = this.filter_set_count()
                this.updatePage(1)
                this.getListing()
            },
            async resetMoreFilters() {
                // if (this.expanded)
                //     this.expanded = !this.expanded

                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]

                this.filtered_listing_id = [];
                this.filtered_listing_id_input = null;

                this.show_number_of_items = '0'
                this.updateShowNumberItems()
                this.order_by = 'desc'
                this.infringement_option = '1'
                this.selected_reasons = []
                this.selected_payment_methods = []
                this.max_date_added = ''
                this.max_date_qualified = ''
                this.filter_seller_location = 0
                this.filter_with_seller_country = 1
                this.filter_item_location = ''
                this.filter_qa = ''
                this.filter_researcher = ''
                this.filter_item_class = 0
                this.filter_with_item_class = 1
                this.filter_item_type = []
                this.filter_with_item_type = 1
                this.filterDateCaptured = ''
                this.filter_case_number = ''
                this.filter_brand_name_on_image = 1
                this.filter_logo_on_image = 1
                this.filter_commission = 1
                this.filter_roles.selected = []
                this.filter_employee.selected = 0
                this.filter_purchase_type = 0
                this.filter_will_ship_to_us = 1
                this.filter_with_shipping_location = 1
                this.filter_shipping_location.selected = 0
                this.filter_with_claimtype = 1
                this.filter_claimtype.selected = 0
                this.filters__.item_location.selected = 0
                this.filter_seller_url = ""
                this.filter_product_url = ""
                this.enable_options = []
                filter_drawer.uncheckPlatforms()

                document.querySelectorAll('#ver-filter-form .search-select .input-icon').forEach(e => e.click())

                document.querySelectorAll('input[name=platforms]')[0].checked = true
                filter_drawer.joined_platforms = '0'
                filter_drawer.applyPlatformFilters()
                this.applied_filters_count = 0
                // this.applied_filters_count = this.filter_set_count()
                this.updatePage(1)
                // this.getListing()
            },
            applyFilters() {
                this.applied_filters_count = this.filter_set_count()
                this.updateShowNumberItems()
                this.updatePage(1)
            },
            resetFilters() {
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                let more_filters = this.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-more-filters')[0]
                let checked = document.querySelectorAll('input[name=platforms]:checked')
                more_filters.switched = false
                this.show_number_of_items = '0'
                this.updateShowNumberItems()
                this.order_by = 'desc'
                this.infringement_option = '1'
                this.selected_reasons = []
                this.selected_payment_methods = []
                this.max_date_added = ''
                this.max_date_qualified = ''
                this.filter_seller_location = '0'
                this.filter_with_seller_country = '1'
                this.filter_item_location = ''
                this.filter_qa = ''
                this.filter_researcher = ''
                this.filter_item_class = 0
                this.filter_with_item_type = '1'
                this.filterDateCaptured = ''
                this.filter_case_number = ''
                this.filter_brand_name_on_image = '1'
                this.filter_logo_on_image = '1'
                this.filter_will_ship_to_us = '1'
                this.filter_with_shipping_location = '1'
                this.filter_shipping_location.selected = '0'
                this.filter_with_claimtype = '1'
                this.filters__.item_location.selected = 0
                this.filter_claimtype.selected = '0'
                this.filter_seller_url = ""
                this.filter_product_url = ""
                this.enable_options = []

                filter_drawer.uncheckPlatforms()
                // checked.forEach(platform => {
                //     platform.checked = false
                // })
                document.querySelectorAll('input[name=platforms]')[0].checked = true
                filter_drawer.joined_platforms = '0'
                filter_drawer.applyPlatformFilters()
                this.applied_filters_count = this.filter_set_count()
                this.updatePage(1)
            },
            removeFilter(filter_type, id) {
                let filter_drawer = this.$parent.$children.filter(c => c.$vnode.componentOptions.tag == 'ar-filter')[0].$children[0]
                if (filter_type == 'reasons') {
                    this.selected_reasons = this.selected_reasons.filter(flag => flag != id)
                    this.updatePage(1)
                } else if (filter_type == 'payment_methods') {
                    this.selected_payment_methods = this.selected_payment_methods.filter(method => method != id)
                    this.updatePage(1)
                } else if (filter_type == 'platforms') {
                    let split = (this.$route.query.platform || '').split(',')
                    let filtered = split.filter(platform => platform != id)
                    let checked = document.querySelectorAll('input[name=platforms]:checked')
                    for (var i = 0; i < checked.length; i++) {
                        if (checked[i].value == 0) continue
                        
                        if (checked[i].value == id)
                            checked[i].checked = false
                    }
                    checked = document.querySelectorAll('input[name=platforms]:checked')
                    if (checked.length == 0) {
                        document.querySelectorAll('input[name=platforms]')[0].checked = true
                    }
                    filter_drawer.joined_platforms = (filter_drawer.dem_platforms.filter(platform => filtered.includes(platform.value.toString())) || []).map(platform => platform.value).join()
                    filter_drawer.applyPlatformFilters()
                }
                this.applied_filters_count = this.filter_set_count()
            },
            getPaymentMethods() {
                let config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(`${process.env.VUE_APP_URL}/payment_types/in/campaign/${this.$route.params.campaign_id}?page_size=50`, config)
                    .then(response => {
                        this.payment_methods = response.data.data.map (method => ({value: method.id, label: method.payment_name }))
                    }
                )
            },
            getReasons(){
                var config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(process.env.VUE_APP_URL+"/criteria?page_size=100",config)
                    .then(response=>{
                        var results = response.data
                        this.reasons = results.data.map(result=>{
                            return {label:result.title,value:result.id}
                        })
                    }).catch(err=>{alert(`${err.response.data.meta.message}. Please contact system admin.`)})
            },
            formatDate(date) {
                const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
                const formatDate = new Date(date)
                const year = formatDate.getFullYear()
                const day = formatDate.getDate()
                const month = months[formatDate.getMonth()]
                return `${month} ${day}, ${year}`
            },
            getSellerLocations() {
                let config = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(`${process.env.VUE_APP_URL}/countries?page_size=1000`, config)
                    .then(response => {
                        this.seller_locations = [ {label: "No Seller Country", value: -1}, ...response.data.data.map (country => ({value: country.id, label: country.name })) ]
                    }
                )
            },
            resetCommission() {
                this.selected_comm = ''
                document.querySelectorAll('.comm-status, input[name="commission"]').forEach(item => {
                    item.classList.remove('active')
                })
            },
            async resetSelectedStatus() {
                this.selected_change_status = 0
                let promise = new Promise((resolve, reject) => {
                    let container = document.querySelector('.a-toggle-btn')
                    if (container) {
                        container.querySelectorAll('.btn').forEach(item => {
                            item.classList.remove('focus')
                            item.classList.remove('active')
                        })
                        container.querySelectorAll('.comm-status, input[name="commission"]').forEach(item => {
							item.classList.remove('focus')
							item.classList.remove('active')
						})
                    }
                    resolve('done')
                })
            },
            getItemClasses(){
                let config      = JSON.parse(JSON.parse(localStorage.getItem("ver")).headers)
                axios.get(process.env.VUE_APP_URL+`/classifications?page_size=1000`,config)
                    .then(response=>{
                        let results = response.data
                        this.item_class = results.data.map(result=> {
                            let label = ""
                            let class_num = ""
                            let class_name = ""
                            class_num = result.class.split('-')
                            class_name = class_num[1]?.trim() || ''
                            class_num = class_num[0].trim()
                            if (class_num?.toLowerCase().includes('class') && !isNaN(class_num.split(' ')[1])) {
                                // label = `[${class_num}] ${result.types || ''}`
                                label = `[${class_num}] ${class_name}`
                            } else
                                label = result.class
                            return {
                                label:`[Class ${result.number == null || 
                                        result.number == 0 || result.number == "  " ? 
                                        'N/A' : result.number}] ${label} `,
                                value:result.class
                            }
                        })
                    }).catch(err=>{alert(`${err.response.data.meta.message}. Please contact system admin.`)})
            },
            filter_set_count() {
                let filters_count = 0 
                
                filters_count = this.selected_payment_methods.length + this.selected_reasons.length
                
                // if (this.filtered_listing_id.length > 0) filters_count++
                if (this.filtered_listing_id && this.filtered_listing_id.length) filters_count++

                if (this.min_date_added != '') filters_count++
                if (this.min_date_qualified != '') filters_count++
                if (this.filter_item_location != '' || this.filters__.item_location?.selected != 0) filters_count++
                if (this.filter_seller_location != 0) filters_count++
                if (this.filter_case_number != '' && this.$route.query.status == 'Filed') filters_count++
                if (this.filter_researcher != '') filters_count++
                if (this.filter_qa != '') filters_count++
                if (this.filter_item_class != 0) filters_count++
                if (this.filter_item_type.length) filters_count++
                if (this.filterDateCaptured !== '') filters_count++
                if (this.enable_options.includes('brand_name_on_image_option')) filters_count++
                if (this.enable_options.includes('logo_on_image_option')) filters_count++
                if (this.enable_options.includes('will_ship_to_us_option')) filters_count++
                if (this.enable_options.includes('commission_option')) filters_count++
                if (this.filter_shipping_location.selected != 0) filters_count++
                if (this.filter_claimtype.selected != 0) filters_count++
                if (this.filter_status_change_reason.selected.length) filters_count++
                if (this.filter_roles.selected.length) filters_count++
                if (this.filter_employee.selected != 0) filters_count++
                if (this.filter_purchase_type != 0) filters_count++
                if (this.filter_seller_url != '') filters_count++
                if (this.filter_product_url != '') filters_count++

                // console.log(filters_count)
                return filters_count
            },
            isObject(data) {
                return typeof data === 'object' && data !== null
            },
            getClassNumber(item_class) {
                let label = ""
                let class_num = ""
                if (!item_class)
					return ""
                class_num = item_class.split('-')
                class_num = class_num[0].trim()
                if (class_num?.toLowerCase().includes('class') && !isNaN(class_num.split(' ')[1])) {
                    label = `[${class_num}]`
                } else
                    label = item_class
                return label
            },
            resetData() {
                this.selected_predefined_reasons     = []
                this.selected_predefined_sub_reasons = []
                this.selected_change_status          = 0
                this.selected_other_reason           = []
                this.other_reason                    = ''
                return this.$store.dispatch('resetPredefinedReasonState')
            },
            scrollIntoView(id) {
                setTimeout(() => document.getElementById(id).scrollIntoView(), 100)
            },
            showAddListingNote() {
                this.listingNote.status = !this.listingNote.status
                this.show_predefined_reasons = false
                if (this.listingNote.status)
                    this.getListingNotes()
            },
            addListingNote() {
                this.listingNote.is_loading = true
                let config  = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                let payload = { 'note': this.listingNote.value, 'listing_id': this.active_listing_id }
                this.$http.post('/general_history/store/note', payload, config)
                    .then(response => {
                        this.listingNote.value = ""
                        this.change_status = false
                        this.show_predefined_reasons = false
                        this.getListingNotes()
                        this.$parent.showSnackbar("Successfully added note.", false, 'success', 'fas fa-check-circle')
                    })
                    .catch(() => this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-info-circle') )
                    .finally(_ => this.listingNote.is_loading = false)
            },
            getListingNotes() {
                this.listingNote.is_loading = true
                let config = JSON.parse(JSON.parse(localStorage.getItem('ver')).headers)
                this.$http.get(`/general_history/get/notes/${this.active_listing_id}`, config)
                    .then(response => {
                        if (response.data.meta.code == 200) {
                            this.listingNote.data = response.data.data
                        }
                    })
                    .catch(() => this.$parent.showSnackbar('Something went wrong. Please contact system admin.', false, 'invalid', 'fas fa-info-circle') )
                    .finally(_ => this.listingNote.is_loading = false)
            },
            decideToGetReason(id) {
                if (this.isReassignWithReason) this.getPredefinedReasons(id)
            },
            copytoClipboard(str = "", prop_name = 'listing id') {
                if (str != "") {
                    let modal = []
                    let container = (modal = document.querySelectorAll('.modal.in, .modal.show')).length ? modal : document
                    const el = document.createElement('textarea')
                    el.value = str
                    el.setAttribute('readonly', '')
                    el.style.position = 'absolute'
                    el.style.left = '-9999px'
                    container[modal.length ? 0 : "body"].appendChild(el)
                    el.select()
                    document.execCommand('copy')
                    container[modal.length ? 0 : "body"].removeChild(el)

                    // call the snackbar when copied
                    this.$parent.showSnackbar(`You copied ${prop_name}: ${str}`, false, 'success', 'fas fa-check')
                }
            },
            transferExistingTo_reassign(existing_listings_result, results) {
                if(existing_listings_result.length == results?.length){
                    this.reassignStatuses.results = results.map((item, index) => {
                        return { ...item, existing_listings: existing_listings_result[index] }
                    })
                }
            },
            transferExistingTo_existing_listings_id(result){
                this.existing_listings_id = result.map(item => {
                    return {
                        lid: item.duplicate.map(item => {
                            return item.lid
                        }),
                        status: item.duplicate.map(item => {
                            return item.qflag_id_word
                        }),
                    }
                })
            },
            redirectTo_searchPage(searched_lid, status) {
                window.open(`${window.location.origin}/verification/search?status=${status}&platform=0&lid=${searched_lid}`,'_blank')
            },
            // Multiple Listing ID
            appendListingID(e) {
                if (this.filtered_listing_id_input && !this.filtered_listing_id.includes(this.filtered_listing_id_input.trim())) {
                    this.filtered_listing_id_input.replace(/\s+/g, ',').split(',').filter(listing_id => {
                        if (listing_id && !this.filtered_listing_id.includes(listing_id))
                            this.filtered_listing_id.push(listing_id.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))
                    })
                    this.filtered_listing_id_input = null;
                    $('#input-rids').val('');
                }
            },
            removeSelectedListing() {
                for (let listing of this.selected_listings) {
                    let idx = this.listings.findIndex(l => l.id == listing);
                    this.listings.splice(idx, 1);
                }
                this.selected_listings = [];
            },
            removeLastListingID() {
                if (this.filtered_listing_id_input == '') this.filtered_listing_id.pop()
            },
            removeListingID(listing_id) {
                this.filtered_listing_id.splice(this.filtered_listing_id.indexOf(listing_id), 1);
            },
            cleanInput() {
                this.filtered_listing_id_input = this.filtered_listing_id_input.replace(/[^0-9,]+s/g, '');
            },
            user_id() {
                return JSON.parse(localStorage.getItem('ver'))?.id
            },
            async getAdditionalFilters_data() {
                if(!this.expanded)
                    return
                if(this.getDataFor_AdditionalFilters)
                    return

                this.getDataFor_AdditionalFilters = true
                await this.getClaimTypes()
                await this.getItemTypes()
                /**
                 * Roles select was removed so it is commented here
                 * but getRoles() is still part of Additional Filters
                 * 
                 * If it will be back, don't forget to uncomment this
                 */
                // this.getRoles()
                // this.getEmployees()
                await this.getItemLocations()
                await this.getShippingLocations()
                await this.getPaymentMethods()
                await this.getSellerLocations()
                await this.getItemClasses()
                await this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status), true)

                this.getDataFor_AdditionalFilters = false
            },
            hasLFCMessage() {
                this.triggerLFCInvalidNotice();
            },
            /**
             * Checks if param value is equivalent to status property of router query.
             * 
             * @param { string } status
             */
            isQueryStatusEquals(status) {
                status = status.toLocaleLowerCase();
                const queryStatus = this.$route.query?.status?.toString()?.toLowerCase();

                return status === queryStatus;
            },
            getUserAssignments() {
                this.$store.dispatch('userAssignments')
            },
            async setResearchTrackerLoad() {
                await this.$store.dispatch('setResearcherTrackerLoad', this.showResearchTrackerLoad);

                this.$store.dispatch('setResearcherTrackerLoad', !this.showResearchTrackerLoad);

                // No need to setTimeout since $store.dispatch can be awaited.
                /* setTimeout(() => {
                    this.$store.dispatch('setResearcherTrackerLoad', !this.showResearchTrackerLoad)
                }, 5000) */
            },
            showCongratsMsg() {
                $('#show-congrats-message').modal('show')
                this.closeExhaustModal()
                setTimeout(() => {
                    this.closeCongratsMsg();
                },5000);
            },
            closeCongratsMsg() {
                $('#show-congrats-message').modal('hide')
            },
            closeExhaustModal() {
                $('#show-exhausted-tracker').modal('hide')
            },
            async getRestrictedReasons() {
                const reasonsRequest = await this.$http.get('/api/reasons?page_size=1000', this.$session.get('ver_headers'));
                const reasons = reasonsRequest.data.data;

                const reasonsInfoRequest = await this.$http.get('/api/reasons-info?page_size=1000', this.$session.get('ver_headers'));
                const reasonsInfo = reasonsInfoRequest.data.data;

                const role = this.user?.role ?? this.user.access_level;

                const isRestrictedToMyAccount = ({ restricted_roles }) => Array.isArray(restricted_roles) && restricted_roles.length !== 0 && restricted_roles.includes(role);

                const reasonsWithRestrictedRoles = reasons.filter(isRestrictedToMyAccount)
                    .reduce((accumulated, current) => [ current.id, ...accumulated ], []);

                const reasonsInfoWithRestrictedRoles = reasonsInfo.filter(isRestrictedToMyAccount)
                    .reduce((accumulated, current) => [ current.id, ...accumulated ], []);

                this.reasonsWithRestrictedRoles = {
                    main: reasonsWithRestrictedRoles,
                    sub: reasonsInfoWithRestrictedRoles
                };

                return;
            },
            clearReasons() {
                this.selected_other_reason = []
                this.selected_predefined_reasons = []
                this.selected_predefined_sub_reasons = []
            }
        },
        watch: {
            'subStatusesStore.showListingsWithSubstatuses'(value) {
                this.getListing();
            },
            '$store.state.checked_reasons'(val) {
                this.selected_predefined_reasons = val
            },
            '$store.state.checked_subreasons'(val) {
                this.selected_predefined_sub_reasons = val
            },
            '$store.state.checked_other_reason'(val) {
                this.selected_other_reason = val
            },
            '$store.state.other_reason_comment'(val) {
                this.other_reason = val
            },
            '$store.state.checked_commission'(val) {
                this.selected_comm = val
            },
            isRemoveListingRestricted(value) {
                if (value) {
                    $('#remove-listing-modal').modal('hide')

                    this.show_predefined_reasons = false
                }
            },
            show_error(val) {
                if (!val) {
                    this.lists.map(l => l.error = false);
                }
            },
            min_date_added(val) {
                // // Adding time to Date
                // console.log(val)
                // let date2 = new Date(val)
                // console.log(date2)
                // let year = date2.getFullYear()
                // date2 = date2.toString().split(year.toString())[1]
                // date2 = date2.split(' ')[1]
                // val = `${val} ${date2}`
                // console.log(val)
                if (val == '') {
                    this.max_date_added = ''
                } else if (this.max_date_added == '') {
                    this.max_date_added = this.today
                } else if (val > this.max_date_added) {
                    this.max_date_added = val
                }
            },
            max_date_added(val) {
                if (val == '' && this.min_date_added == '') {
                    this.max_date_added = this.today
                } else if (val <= this.min_date_added) {
                    this.min_date_added = val
                }
            },
            min_date_qualified(val) {
                if (val == '') {
                    this.max_date_qualified = ''
                } else if (this.max_date_qualified == '') {
                    this.max_date_qualified = this.today
                } else if (val > this.max_date_qualified) {
                    this.max_date_qualified = val
                }
            },
            max_date_qualified(val) {
                if (val == '' && this.min_date_qualified == '') {
                    this.max_date_qualified = this.today
                } else if (val <= this.min_date_qualified) {
                    this.min_date_qualified = val
                }
            },
            '$route.query'(search) {
                this.selected_listings = []
                this.is_select_all = false
                this.checkAllListing()
                this.current_page = 1
                this.setFilters()
            },
            '$route.params'() {
                this.setFilters()
                if (!this.has_date_qualified_filter)
                    this.date_qualified = ''
                if (this.$route.params.campaign_id != null && this.campaigns_select.length > 0) {
                    this.campaign.name = this.campaigns_select.filter(c => c.value == this.$route.params.campaign_id)[0].label
                    this.campaign.img = this.$parent.getImage('campaigns', this.campaign.name)
                }
            },
            filters: {
                handler() {
                    this.updatePage(1)
                }
            },
            shown_data() {
                this.updatePage(1)
            },
            active_evidences(val) {
                this.uploadedEvidence(val)
            },
            'individualReassign.show'() {
                this.show_predefined_reasons = false
            },
            change_status() {
                this.listingNote.status = false
                this.listingNote.value = ""
                this.individualReassign.show = false
            },
            allSelectedReasons: {
                handler({reasons, reasonsInfo, otherReason}) {
                    const predefinedReasons     = this.predefined_reasons
                    const reasonsHasCrucial     = predefinedReasons.some(({id, severity_level}) => reasons.includes(id) && +severity_level === 1)
                    const otherReasonHasCrucial = predefinedReasons.some(({id, severity_level}) => id === otherReason[0] && +severity_level === 1)
                    const reasonsInfoHasCrucial = reasonsInfo.some(({severity_level}) => +severity_level === 1)
                    const hasCrucial = [reasonsHasCrucial, otherReasonHasCrucial, reasonsInfoHasCrucial].includes(true)
                    //Automatically set Rework level to Major Rework and Rework Commission to No Commission
                    this.reworkLevel      = hasCrucial ? 1 : this.reworkLevel
                    this.reworkCommission = hasCrucial ? 0 : this.reworkCommission
                    this.hasCrucial = hasCrucial
                },
                deep: true
            },
            'reassignStatuses.selected'(val) {
                this.decideToGetReason(val)
                this.subStatusesStore.currentSelectedSubStatus = -1

                if (this.reassignmentBulkSubStatusOptions.length) {
                    setTimeout(() => {
                        $('.bulk-reassign-status-and-substatus-con').children('.btn-group-toggle').find('.sub-statuses-container .btn').first().addClass('active')
                    })
                }
            },
            'individualReassign.status.selected'(val) {
                if (val) {
                    this.decideToGetReason(val)
                    if (this.isReassignWithReason) this.getChangeStatus(val, this.reassignStatusOptions.find(s => s.value == val)?.label, true)
                } else {
                    this.show_predefined_reasons = false
                }

                this.subStatusesStore.currentSelectedSubStatus = -1

                if (this.reassignmentSubStatusOptions.length) {
                    setTimeout(() => {
                        $('.individual-reassign-status-and-substatus-con').children('.btn-group-toggle').find('.sub-statuses-container .btn').first().addClass('active')
                    })
                }
            },
            'individualReassign.user_type.selected'(val) {
                this.individualReassign.user.selected = { label: 'Select User', value: 0 }
                if(val == 1)
                    this.individualReassign.user.selected = { label: 'Select Researcher / CL User', value: 0 }
                if(val == 2)
                    this.individualReassign.user.selected = { label: 'Select QA User', value: 0 }
            },
            'batchReassign.user_type.selected' (val) {
                this.selected_campaign_user = { label: 'Select User', value: 0 }
                if(val == 1)
                    this.selected_campaign_user = { label: 'Select Researcher / CL User', value: 0 }
                if(val == 2)
                    this.selected_campaign_user = { label: 'Select QA User', value: 0 }
            },
            'isOpen_batchReassign' (val) {
                if(val) {
                    this.selected_campaign_user = { label: 'Select User', value: 0 }
                } else {
                    this.batchReassign.user_type.selected = 0
                }
            },
            '$route.query.status' (val) {
                let val_toLower = val?.toLowerCase()
                this.getPredefinedReasons(this.$parent.getQFlagId(this.$route.query.status), true);

                if (val_toLower == 'qualified') {
                    this.batchReassign.user_type.selected = 1
                    this.individualReassign.user_type.selected = 1
                    return
                }
                this.batchReassign.user_type.selected = 0
                this.individualReassign.user_type.selected = 0
            },
            // 'attached_lists' (val) {
            //     if (this.$route.query.status.toLowerCase() == 'client listing') {
            //         if(val.length > 0) {
            //             this.has_clientListings = true
            //             // if(this.access_level == 1) {
            //             // }
            //         } else {
            //             this.has_clientListings = false
            //             // if(this.access_level == 1) {
            //             // }
            //         }
            //     }
                
            // }
            'hasTriggeredSomething'(val) {
                if(val) {
                    // Added trigger for checking if there are Clients from Listings 
                    
                    let gui = 'ver'
                    let payload = {
                        type: this.access_level == 5 ? 'byCampaignLead' : '',
                        gui: gui,
                        user_id: this.user_id,
                    }
                    this.$store.dispatch('setListing_has_clientListings', payload)
                }
            },
            generalHistory(value) {
                if (value.length === 0) {
                    return;
                }

                this.isHistoryReady = false;

                const hideHistories = (elements, restrictedReasons, key) => {
                    for (const element of elements) {
                        const id = element.dataset[key];

                        if (id === '') {
                            continue;
                        }

                        const isRestricted = restrictedReasons.includes(+id);

                        if (!isRestricted) {
                            continue;
                        }

                        element.style.display = 'none';
                    }
                }

                setTimeout(() => {
                    const { main, sub } = this.reasonsWithRestrictedRoles ?? {};

                    const reasons = document.querySelectorAll('[data-reason-id]');
                    hideHistories(reasons, main, 'reasonId');

                    const reasonsInfo = document.querySelectorAll('[data-reason-info-id]');
                    hideHistories(reasonsInfo, sub, 'reasonInfoId');

                    this.isHistoryReady = true;
                }, 1000);
            },
            hasMissingRequiredFields(value) {
                this.getChangeStatus(ListingStatus.Researched, 'Researched');
                return;
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .listing-modal-notes-icon {
        -webkit-transition: .5s ease;
        transition: .5s ease;
    }
    .listing-modal-notes-icon.toggled {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: -8px;
    }
</style>
<style scoped>
    .fieldset-alt.disabled .fieldset-alt-content {
        opacity: 0.5 !important;
    }


     .qa-selected strong {
        font-weight: 500;
        position: relative;
    }
     .qa-selected strong.with-separator:after {
        content: "";
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        background: #ccc;
        position: absolute;
        right: -71px;
        height: 32px;
        width: 1px;
        top: 50%;
    }
     .qa-selected .action-item:hover {
        opacity: 0.5;
    }
     .qa-selected .action-item:active {
        opacity: 0.8;
    }
    .main-con {
        padding: 208px 343px 150px 77px;
        /* padding: 158px 343px 150px 77px; */
    }
    .a-header {
        position: relative;
        top: 1px;
        letter-spacing: 0.024em;
        color: #383A3D;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 0;
        height: 40px;
        line-height: 7px;
        vertical-align: middle;
    }
    .a-header img {
        height: 40px;
        width: 40px;
        margin-right: 17px;
    }
    .a-header span {
        display: block;
        font-size: 16px;
        font-weight: 400;
        color: #383A3D;
    }
    .a-header em {
        font-style: initial;
        opacity: 0.8;
    }
    .a-header strong {
        font-weight: 500 !important;
    }
    .titlebar-con {
        padding-right: 334px;
    }
    .a-titlebar {
        padding-right: 0;
    }
    .a-sidebar-filter {
        box-shadow: none;
        height: 100vh;
        z-index: 1;
        top: 57px;
        right: 0;
    }
    .a-header-con {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .selected-header {
        background: #EAF0FC 0% 0% no-repeat padding-box;
        padding: 15px 25px;
    }
    .export-btn {
        background: #2D9E5A !important;
    }
    .export-btn:hover{
        background: #2d9e5a8c !important;
    }
    .reassign-select-label {
        text-align: left;
    }
    .a-modal section {
        margin-bottom: 44px;
    }
    .a-modal hr {
        border: 1px solid #4e4e4e30;
        margin: 32px 0
    }
    .empty-listing-con {
        position:relative;
        height: 28vh;
    }
    .empty-listing-con img {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        width: 38%;
        left: 50%;
        top: 126%;
    }
    .campaign-preview {
        position : absolute !important;
        left     : 15px !important;
        bottom   : 6px;
        width    : 50px;
        height   : 50px !important;
        top      : initial !important;
        transform: none !important;
    }
    .titlebar-con {
        padding-left: 46px;
    }
    .for-rework-toggles {
        top: -41px;
    }
    .for-rework-toggles .btn {
        height: 48px;
        line-height: 36px;
        font-weight: 500 !important;
        font-size: 14px;
        margin-left: 8px;
        letter-spacing: 0.24px;
        border-radius: 8px !important;
        padding-left: 32px;
        padding-right: 32px;
        background-color: #fff;
        color: #383A3D;
    }
    .for-rework-toggles .btn:first-child {
        margin-left: 0;
    }
    .for-rework-toggles .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle,
    .for-rework-toggles .btn-outline-primary:not(:disabled):not(.disabled):hover {
        background-color: #3E88DA;
    }
    .for-rework-toggles .btn-outline-primary:not(:disabled):not(.disabled):hover {
        color: #fff;
    }
    .rework-modification-counter {
        display: block;
        height: 64px;
        width: 210px;
        padding: 15px;
        border: 1px solid #DC3C40;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        color: #DC3C40;
    }
    .rework-modification-counter span {
        display: block;
        font-size: 12px;
        color: #515365;
        font-weight: 400;
        margin-top: 3px;
    }
    .has-bullet-separator {
        position: relative;
    }
    .has-bullet-separator::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        z-index: 2;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 5px;
        width: 5px;
        border-radius: 100%;
    }
    #reassign-modal .modal-dialog {
        -webkit-transition: .3s ease !important;
        transition: .3s ease !important;
    }
    #reassign-modal .withReason .campaign-img {
        height: 40px;
        width: 40px;
    }
    #reassign-modal .withReason .left-con {
        width: 330px;
        /* height: 391px; */
    }
    #reassign-modal .withReason .reassign-right-con {
        width: calc(100% - 368px);
        /* background: #F8F8F8; */
        padding: 15px;
    }
    #reassign-modal .withReason .right-con {
        height: 345px;
        overflow-y: auto;
        padding-right: 10px;
    }
    #reassign-modal .withReason .reassign-left-heading h5,
    #reassign-modal .withReason .reassign-right-heading h5 {
        font-weight: 500;
        font-size: 16px;
    }
    #reassign-modal .withReason .reassign-left-heading h5 span,
    #reassign-modal .withReason .reassign-right-heading h5 span {
        font-weight: 400;
        font-size: 12px;
    }
    .reassign-to-wip-notice {
        height: 87px;
        border: 1px dashed #ff971d;
        border-radius: 10px;
        position: relative;
        padding: 12px 20px 20px 124px;
        background: #fdf7f0;
    }
    .reassign-to-wip-notice .notice-img {
        width: 85px;
        height: 63px;
        position: absolute;
        left: 21px;
        top: 50%;
        /* overflow: hidden; */
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        opacity: 1;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        border: 1px dashed transparent;
    }
    .reassign-to-wip-notice .notice-img img {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 32%;
        left: 50%;
    }
    .reassign-to-wip-notice h6 {
        background: #ff971d;
        border-radius: 14px;
        line-height: 25px;
        height: 24px;
        width: 75px;
        text-align: center;
        color: #ffff;
        font-size: 11px;
        margin-bottom: 10px;
    }
    .reassign-to-wip-notice p {
        text-align: left;
        font-size: 13px;
        color: #000000CC;
    }
    #reassign-modal .table-scroll {
        width:100%;
        display: block;
        empty-cells: show;
    }
    #reassign-modal .table-scroll thead,
    #reassign-modal .table-scroll tfoot{
        position:relative;
        display: block;
        width:100%;
    }
    #reassign-modal .table-scroll tbody{
        display: block;
        position:relative;
        width:100%;
        height: 280px;
        overflow-y:auto;
    }
    #reassign-modal .table-scroll tr{
        width: 100%;
        display: inline-block;
    }
    #reassign-modal .table-scroll td,.table-scroll th{
        display: inline-block;
        word-break: break-all;
        padding: 1rem;
        text-align:left;
    }
    #reassign-modal .table-scroll tr td .status {
        display: inline-block;
        border-radius: 4px;
        padding: 3px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 9px;
        font-weight: 500;
        background: #29ABA2;
        min-width: 66px;
    }
    #reassign-modal .table-scroll tr td .status.not_found {
        background: #DC3C40;
    }
    #reassign-modal .table-scroll tr td .status.wip {
        background: #f3a17a;
    }
    #reassign-modal .table-scroll tr td .status.others {
        background: #3c97dc;
    }
    #reassign-modal .table-scroll th {
        display: inline-block;
        color: #627aad;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1.4px;
    }
    .upload-modal--box {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 120px;
        border-radius: 8px;
        border: 2px dashed #262C3952;
        background: #3B59981A;
        margin-left: 0.7%;
        margin-right: 0.7%;
        -webkit-transition: .5s ease;
        transition: .5s ease;
        cursor: pointer;
    }
    .upload-modal--box .remove-upload--file-button {
        position: absolute;
        display: block;
        height: 10px; 
        width:10px;
        top: -6px;
        right: -1px;
        -webkit-transition: .3s ease;
        transition: .3s ease;
        cursor: pointer;
    }
    .upload-modal--box .remove-upload--file-button:hover {
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
    }
    .upload-modal--box.toBeUploaded {
        opacity: .7;
    }
    .upload-modal--box:not(.cantUpload):hover,
    .upload-modal--box:not(.cantUpload).dragHover {
        -webkit-transform: scale(.95);
        transform: scale(.95);
        border-color: #2d599e;
    }
    .upload-modal--box input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
    }
    .upload-modal--box .icon {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        top: 55%;
        left: 50%;
        font-size: 12px;
        text-align: center;
        color: #3B59988F;
        font-weight: 500;
        z-index: 1;
        cursor: pointer;
    }
    .upload-modal--box .icon img {
        width: 50px;
        height: 60px;
    }
    /*
    * VER VARIATIONS
    */
    .gui-ver .has-bullet-separator::before {
        background: #3e88da;
    }
    /*
    * END OF VER VARIATIONS
    */

    /* added style for Reassignment Status in Existing Listing(s) */
    .existing-listings-container {
        max-height: 100px;
        overflow: auto;
        margin-bottom: 5px;
        border: 1px solid rgb(197, 197, 197);
    }
    .existing-listings-item-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .existing-listings-item-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .existing-btn {
        background-color: #2D599E;
        letter-spacing: 1.25px;
        font-size: 12px;
        font-weight: 400;
        padding: 6px 10px 6px;
        border-radius: 20px;
        position: relative;
        opacity: 1;
        border: 0;
        color: #FFFFFF;
    }
    .existing-btn:not(.disabled):hover {
        background-color: #3E88DA;
    }

    .additional-filters-con {
        height: 0;
        overflow: hidden;
        -webkit-transition: height 0.2s ease;
        transition: height 0.2s ease;
    }

    .additional-filters-con.expanded {
        height: calc(100vh - 182px);
    }

    .additional-filters-con-con {
        height: calc(100vh - 250px);
        padding: 0 20px 20px 41px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .lfc-invalid-note {
        background: #E5AD930F 0% 0% no-repeat padding-box;
        border: 1px solid #E5AD93;
        color: #E5AD93;
        border-radius: 0px 8px 8px 0px;
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 10px;
    }

    .lfc-invalid-note2 {
        background: #E5AD930F 0% 0% no-repeat padding-box;
        border: 1px solid #E5AD93;
        color: #E5AD93;
        border-radius: 8px 8px 8px 8px;
        width: 100%;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-bottom: 10px;
    }

    .lfc-invalid-note-header {
        font: normal normal bold 14px/18px Roboto;
        letter-spacing: -0.24px;
        color: #E5AD93;
    }

    .lfc-invalid-note-header2 {
        margin-top: 10px;
        font: normal normal bold 12px/18px Roboto;
        letter-spacing: 0.50px;
        color: #E5AD93;
    }

    .lfc-invalid-note-list {
        display: flex;
        flex-direction: column;
        font: italic normal normal 14px/18px Roboto;
        letter-spacing: -0.24px;
        color: #E5AD93;
        margin-left: 15px
    }

    .sub-statuses-container {
        max-height: 300px;
        overflow-y: auto;
        margin-bottom: 10px;
        border: 1px solid rgba(121, 189, 154, 0.35);
        border-top: none;
        margin-left: -1px;
        width: 99.9%;
        padding: 10px;
    }

    .sub-statuses-container .btn.sub-status {
        height: 36px !important;
        position: relative;
        width: 100%;
        padding-top: 22px !important;
        padding-bottom: 20px !important;
        padding-left: 80px !important;
        border-radius: 8px !important;
    }

    .sub-statuses-container .btn.sub-status.reassignment::before {
        left: 18px !important;
    }

    .sub-statuses-container .btn.sub-status.only {
        border: 1px solid #bdd6f1 !important;
    }

    .sub-statuses-container .btn.sub-status::before {
        content:"";
        position: absolute;
        top: 50%;
        left: 47px;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #383A3D;
    }
</style>
